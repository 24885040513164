import { Aspect, AspectInput } from '../models';

import HTTPClient from '../http-client';

import { pick } from 'lodash';

export class Aspects extends HTTPClient {

  listAspects (query: { assetId?: string }): Promise<Array<Aspect>> {
    return this.request({
      method: 'get',
      path: `/aspects`,
      query
    })
  }

  createAspect (aspectInput: AspectInput): Promise<Aspect> {
    return this.request({
      method: 'post',
      path: `/aspects`,
      body: pick(aspectInput, 'name', 'description', 'assetId', 'attributes')
    })
  }

  getAspect(aspectId: string): Promise<Aspect> {
    return this.request({
      method: 'get',
      path: `/aspects/${aspectId}`,
    })
  }

  updateAspect(aspectId: string, aspectInput: AspectInput): Promise<Aspect> {
    return this.request({
      method: 'put',
      path: `/aspects/${aspectId}`,
      body: pick(aspectInput, 'name', 'description', 'attributes')
    })
  }

  deleteAspect(aspectId: string): Promise<void> {
    return this.request({
      method: 'delete',
      path: `/aspects/${aspectId}`
    })
  }
}