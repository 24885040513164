import React from 'react';

import {
  IconButton,
  TextField,
  InputAdornment
} from '@material-ui/core';

import {
  Search as SearchIcon,
  Clear as ResetIcon
} from '@material-ui/icons'

type Props = {
  value: string;
  autoFocus?: boolean;
  onChange: (text: string) => void;
}

class State {

  constructor (props: Props) {
    this.text = props.value + "";
  }

  text: string;
}

export class SearchField extends React.Component<Props> {

  readonly state = new State(this.props);

  update = (text: string) => {
    this.setState({ text });
    this.props.onChange(text);
  }

  render() {
    const { autoFocus } = this.props;
    const { text } = this.state;

    return (
      <TextField
        inputProps={{style: {fontSize: 14}}}
        margin="none"
        placeholder="Quick search..."
        autoFocus={autoFocus}
        color="secondary"
        value={text}
        onChange={evt => this.update(evt.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" style={{width: 30}}>
              { text &&
                <IconButton size="small" onClick={() => this.update('')}>
                  <ResetIcon fontSize="small" />
                </IconButton>
              }
            </InputAdornment>
          ),
        }}
      />
    );
  }
}