import React from 'react';

import {
  withRouter,
  RouteComponentProps,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import {
  IconButton,
  Box,
  Typography,
  Container,
  Drawer,
  Toolbar,
  Divider,
  List
} from '@material-ui/core';

import {
  Add as AddIcon,
} from '@material-ui/icons';

import {
  Skeleton
} from '@material-ui/lab';

import {
  AppLinkSkeleton,
  AppTopBar,
  DebugDialog,
  FeedbackToast,
  ErrorPage,
  TemplatePage,
  TemplateList,
  TemplateListSkeleton,
  SearchItem,
  ResourceEditDialog,
  TemplateForm
} from '../components';

import API, {
  Template,
  TemplateInput,
  DataType
} from '../api';

import {
  filterTemplates,
  newTemplateInput,
  uploadTemplateVersionArtifact
} from '../utils';

interface Params {
  templateId?: string;
  templateVersionId?: string;
}

interface Props extends RouteComponentProps<Params> {
  embedded?: boolean;
  baseURL: string;
  dataType?: DataType;
}

class State {
  loading: boolean = true;
  error?: Error;
  templates: Template[] = [];
  feedback: string | undefined = undefined;
  searchText: string = '';
}

export const TemplatesPage = withRouter(class extends React.Component<Props, State> {

  readonly state = new State();
  readonly widthDrawer = 400;

  componentDidMount() {
    const { dataType } = this.props;
    this.setState(new State());
    return API.templates.listTemplates({ dataType })
    .then(templates => {
      this.setState({ loading: false, templates });
    })
    .catch(error => {
      this.setState({ loading: false, error });
    })
  }

  render() {
    const { history, location, embedded, baseURL, dataType } = this.props;
    const { loading, error, templates, feedback, searchText } = this.state;

    const selection = templates.find(t => location.pathname.match(`${baseURL}/${t.id}`));

    const filteredTemplates = filterTemplates(templates, searchText, selection);

    const loadingState = (
      <React.Fragment>
        { !embedded &&
          <AppTopBar>
            <AppLinkSkeleton />
            <Box m={1} />
            <AppLinkSkeleton />
          </AppTopBar>
        }
        <Drawer open={true} variant="permanent" PaperProps={{style: {width: this.widthDrawer}}}>
          { !embedded && <Toolbar /> }
          <Toolbar variant="dense">
            <Skeleton width={100} height={20} />
          </Toolbar>
          <Box my={2} />
          <Divider />
          <TemplateListSkeleton countItems={5} />
        </Drawer>
      </React.Fragment>
    );

    const idealState = (
      <React.Fragment>
        { !embedded &&
          <AppTopBar>
            <Typography variant="h6">
              Templates Management
            </Typography>
            <Box m={1} />
          </AppTopBar>
        }
        <Drawer open={true} variant="permanent" PaperProps={{style: {width: this.widthDrawer, display: 'flex', overflowY: 'hidden'}}}>
          { !embedded && <Toolbar /> }
          <Toolbar variant="dense">
            {/*<TemplateIcon />
            <Box mx={1} />*/}
            <Typography variant="subtitle1" noWrap>
              Templates
            </Typography>
            <Box m="auto" />
            <DebugDialog object={templates} />
            <ResourceEditDialog<TemplateInput, Template>
              title="New Template"
              resource={newTemplateInput()}
              trigger={<IconButton color="primary"><AddIcon /></IconButton>}
              action={resource => {
                return API.templates.createTemplate(resource)
                .then(template => uploadTemplateVersionArtifact(template, resource.upload))
              }}
              onSuccess={template => {
                history.replace(`${baseURL}/${template.id}`);
                this.setState({
                  feedback: `Template '${template.name}' created with version '${template.version}'.`,
                  templates: [...templates, template]
                })
              }}
              renderForm={(resource, ref, handleSubmit) => (
                <TemplateForm
                  newTemplate
                  uploadRequired
                  dataType={dataType}
                  templateInput={resource}
                  innerRef={ref}
                  onSubmit={handleSubmit}
                />
              )}
            />
          </Toolbar>
          <List dense disablePadding>
            <SearchItem onUpdate={(searchText) => this.setState({ searchText })} />
          </List>
          <Divider />
          <Box display="flex" flexDirection="column" flex={1} style={{overflowY: 'scroll'}}>
            { templates.length === 0
              ? <Box p={1}><Typography variant="subtitle1" color="textSecondary" align="center" paragraph>No templates yet.</Typography></Box>
              : filteredTemplates.length === 0
                ? <Box p={1}><Typography variant="subtitle1" color="textSecondary" align="center" paragraph>No templates found.</Typography></Box>
                : <TemplateList
                    items={filteredTemplates}
                    selection={selection}
                    onSelection={template => history.push(`${baseURL}/${template.id}`) }
                  />
            }
          </Box>
          <Divider />
          { templates.length > 0 &&
            <Box display="flex" justifyContent="center">
              <Typography variant="caption" color="textSecondary" align="center">Showing { filteredTemplates.length } out of { templates.length } templates.</Typography>
            </Box>
          }
        </Drawer>
        <Container style={{paddingLeft: this.widthDrawer + 24, flex: 1}}>
          <Switch>
            <Route exact path={baseURL || '/'}>
              {
                filteredTemplates.length > 0
                ? <Redirect to={`${baseURL}/${filteredTemplates[0].id}`} />
                : (
                  <Box py={2}>
                    <Typography paragraph variant="subtitle1">
                      No templates available. Add a new template.
                    </Typography>
                  </Box>
                )
              }
            </Route>
            <Route exact path={`${baseURL}/:templateId`} render={(props) => (
              <TemplatePage
                key={props.match.url}
                dataType={dataType}
                onCreate={template => {
                  this.setState({
                    feedback: `Template '${template.name}' version '${template.version}' successfully added.`,
                    templates: templates.map(t => t.id === template.id ? template : t)
                  });
                }}
                onUpdate={template => {
                  this.setState({
                    feedback: `Template '${template.name}' version '${template.version}' successfully updated.`,
                    templates: templates.map(t => t.id === template.id ? template : t)
                  });
                }}
                onDelete={(template, permanently) => {
                  this.setState({
                    feedback: `Template '${template.name}' version '${template.version}' successfully deleted.`,
                    templates: permanently ? templates.filter(t => t.id !== template.id) : templates.map(t => t.id === template.id ? template : t)
                  });
                  if (permanently)
                    history.push(`${baseURL}`);
                }}
              />
            )} />

          </Switch>
          <FeedbackToast key={feedback} message={feedback} onHide={() => this.setState({ feedback: undefined })} />
        </Container>
      </React.Fragment>
    );

    return error
      ? <ErrorPage message={error.message} onReload={() => this.componentDidMount()} />
      : loading
        ? loadingState
        : idealState;
  }
});