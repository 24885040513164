import React from 'react';

import {
  Box,
  Button,
  Toolbar,
  Typography
} from '@material-ui/core';

import {
  Add as AddIcon
} from '@material-ui/icons';

import API, {
  DataCollection,
  DataType
} from '../api';

import {
  ContentDialog,
  DebugDialog,
  DataCollectionEditor,
  DataCollectionIcon,
  DataCollectionListSkeleton,
  DataCollectionList,
  ErrorBox,
  SearchField
} from '../components';

import {
  searchCase
} from '../utils';

interface Props {
  aspectId?: string;
  selection?: DataCollection;
  onSelection?: (dataCollection?: DataCollection) => void;
  style?: React.CSSProperties;
}

class State {
  loading: boolean = true;
  error?: Error;
  dataCollections?: DataCollection[];
  selection?: DataCollection;
  searchText: string = '';

  constructor(props: Props) {
    this.selection = props.selection
  }
}

export class DataCollectionPicker extends React.Component<Props, State> {

  readonly state = new State(this.props);

  componentDidMount () {
    this.setState({ loading: true, error: undefined, dataCollections: undefined });
    API.dataCollections.listDataCollections({ dataSourceReferenceId: this.props.aspectId, dataType: DataType.Images })
    .then(dataCollections => this.setState({ dataCollections, loading: false }))
    .catch(error => this.setState({ error, loading: false }))
  }

  toggleSelection = (meta: DataCollection) => {
    const selection = meta.id !== this.state.selection?.id ? meta : undefined;
    this.setState({ selection });
    this.props.onSelection?.(selection);
  }

  handleUpdate = (dataCollections: DataCollection[], dataCollection: DataCollection) => {
    this.setState({
      dataCollections: dataCollections.map(d => d.id === dataCollection.id ? dataCollection : d )
    });
  }

  handleDelete = (dataCollections: DataCollection[], dataCollection: DataCollection) => {
    const { selection } = this.state;
    const updates = dataCollections.filter(d => d.id !== dataCollection.id);
    if (selection && selection.id === dataCollection.id) {
      this.setState({
        dataCollections: updates,
        selection: undefined
      });
      this.props.onSelection?.(undefined);
    }
    else {
      this.setState({
        dataCollections: updates
      });
    }
  }

  render() {
    const { style, aspectId } = this.props;
    const { dataCollections, selection, loading, error, searchText } = this.state;

    const filteredDataCollections = dataCollections ? dataCollections
      .filter(d => !searchText || searchCase(d.name).indexOf(searchCase(searchText)) !== -1)
      .sort((a, b) => a.name.localeCompare(b.name)) : [];

    return (
      <Box display="flex" flexDirection="column" style={style}>
        <Toolbar variant="dense">
          <DataCollectionIcon />
          <Box mx={1} />
          <Typography variant="subtitle1">Pick a Data Collection</Typography>
          <Box mx={2} />
          { dataCollections && dataCollections.length > 0 &&
            <SearchField
              autoFocus
              value={searchText}
              onChange={searchText => this.setState({ searchText })}
            />
          }
          <DebugDialog object={dataCollections} />
          <Box mx="auto" />
          { dataCollections && aspectId &&
            <ContentDialog
              fullscreen
              trigger={<Button color="primary" size="small" startIcon={<AddIcon />}>New</Button>}
              renderContent={close => (
                <DataCollectionEditor
                  dataSourceReferenceId={aspectId}
                  onCreate={dataCollection => {
                    this.setState({
                      dataCollections: [...dataCollections, dataCollection]
                    })
                  }}
                  onUpdate={dataCollection => {
                    this.setState({
                      dataCollections: dataCollections.map(d => d.id === dataCollection.id ? dataCollection : d)
                    });
                  }}
                  onClose={close}
                />
              )}
            />
          }
        </Toolbar>
        <Box style={{flex: '1 1 1px', overflowY: 'scroll'}}>
          { loading && <DataCollectionListSkeleton countItems={5} /> }
          { error && <ErrorBox message={error?.message} onReload={() => this.componentDidMount()} /> }
          { dataCollections && filteredDataCollections.length > 0 &&
            <DataCollectionList
              items={filteredDataCollections}
              selection={selection}
              onSelection={(item) => this.toggleSelection(item)}
              onUpdate={item => this.handleUpdate(dataCollections, item) }
              onDelete={item => this.handleDelete(dataCollections, item) }
            />
          }
          { dataCollections && dataCollections.length === 0 &&
            <Box p={2}>
              <Typography color="textSecondary" variant="body1">No Data Collections available.</Typography>
            </Box>
          }
        </Box>
      </Box>
    );
  }
}