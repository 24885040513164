import React from 'react';

import {
  Formik,
  FormikProps,
  FormikErrors,
  Form
} from 'formik';

import {
  TextField
} from '@material-ui/core';

import {
  LabelsetInput
} from '../api';

interface Props {
  labelsetInput: LabelsetInput;
  innerRef: any;
  onSubmit: (labelsetInput: LabelsetInput) => void;
}

export class LabelsetForm extends React.PureComponent<Props> {
  render() {
    const { labelsetInput, innerRef, onSubmit } = this.props;
    return (
      <Formik<LabelsetInput>
        innerRef={innerRef}
        initialValues={labelsetInput}
        validate={values => {
          const errors: FormikErrors<LabelsetInput> = {};
          if (!values.name)
            errors.name = 'Required';
          return errors;
        }}
        onSubmit={onSubmit}
      >
      { (props: FormikProps<LabelsetInput>)  => {
        const { values, errors, touched, handleChange } = props;

        return (
          <Form autoComplete="off" noValidate>
            <TextField
              required
              margin="normal"
              name="name"
              label="Name"
              value={values.name}
              onChange={handleChange}
              autoFocus
              fullWidth
              placeholder=""
              error={!!errors.name}
              helperText={errors.name || 'Choose a unique name'}
              InputLabelProps={{shrink: true}}
            />
            <TextField
              margin="normal"
              name="description"
              label="Description"
              value={values.description}
              onChange={handleChange}
              fullWidth
              placeholder=""
              error={touched.description && !!errors.description}
              helperText={errors.description}
              InputLabelProps={{shrink: true}}
            />
          </Form>
        );
      } }
      </Formik>
    );
  }
}