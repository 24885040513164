import React from 'react';

import {
  IconButton,
} from '@material-ui/core';

import {
  MoreHoriz as ViewIcon
} from '@material-ui/icons';

import {
  Dataset
} from '../api';

import {
  ContentDialog,
  DatasetViewer,
} from '../components';

interface Props {
  dataset: Dataset;
  onClose?: () => void;
  onUpdate: (dataset: Dataset) => void;
  onDelete: (dataset: Dataset) => void;
}

class State {
  anchor: HTMLButtonElement | null = null;
}

export class DatasetMenu extends React.Component<Props, State> {

  readonly state = new State();

  show = (evt: any) => {
    this.setState({ anchor: evt.currentTarget });
  }

  hide = () => {
    this.setState({ anchor: null });
  }

  render() {
    const { dataset, onUpdate, onDelete } = this.props;

    return (
      <ContentDialog
        trigger={<IconButton size="small"><ViewIcon /></IconButton>}
        renderContent={(close) => (
          <DatasetViewer
            datasetVersionId={dataset.datasetVersionId}
            onClose={close}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        )}
      />
    );
  }
}