import React from 'react';

import {
  Formik,
  FormikProps,
  FormikErrors,
  Form
} from 'formik';

import {
  FormLabel
} from '@material-ui/core';

import {
  TemplateInstanceInput,
  Template
} from '../api';

import {
  TemplatePicker
} from '../components';

import {
  setTemplateInstanceTemplate
} from '../utils';

interface Props {
  templateInstanceInput: TemplateInstanceInput;
  templates: Template[];
  innerRef: any;
  onSubmit: (templateInstanceInput: TemplateInstanceInput) => void;
}

export class TemplateInstanceTemplateForm extends React.PureComponent<Props> {

  handleSelection = (template: Template | undefined, props: FormikProps<TemplateInstanceInput>) => {
    const { values, setFieldValue } = props;
    if (values.templateId !== template?.id) {
      setTemplateInstanceTemplate(values, template);
      setFieldValue('template', template?.id, true);
    }
  }

  render() {
    const { templateInstanceInput, innerRef, onSubmit, templates } = this.props;


    return (
      <Formik
        innerRef={innerRef}
        initialValues={templateInstanceInput}
        validate={ (values: TemplateInstanceInput) => {
          const errors: FormikErrors<TemplateInstanceInput> = {};
          if (!values.templateId)
            errors.templateId = 'Required';
          return errors;
        }}
        onSubmit={onSubmit}
      >
      { (props: FormikProps<TemplateInstanceInput>)  => {
        const { values, touched, errors } = props;

        if (!values.templateId && templates.length > 0)
          this.handleSelection(templates[0], props);

        const selection = templates.find(t => t.id === values.templateId);

        return (
          <Form autoComplete="off" noValidate style={{display: 'flex', flexDirection: 'column', flex: 1}}>
            { touched.templateId && errors.templateId &&
              <FormLabel color="primary" focused filled error>Select a Template</FormLabel>
            }
            <TemplatePicker
              style={{flex: 1}}
              selection={selection}
              onSelection={template => this.handleSelection(template, props)}
            />
          </Form>
        );
      } }
      </Formik>
    );
  }
}