import React from 'react';

import {
  withRouter,
  Route,
  RouteComponentProps,
  Switch
} from 'react-router-dom';

import {
  TemplateInstancesPage,
  TemplateInstanceCreator
} from '../components';

interface Props extends RouteComponentProps {
  path: string;
  dataSourceReferenceId?: string;
}

export const TemplateInstanceRouter = withRouter(class extends React.Component<Props> {

  render() {
    const { path, dataSourceReferenceId } = this.props;

    return (
      <Switch>
        { dataSourceReferenceId &&
          <Route exact path={`${path}`} render={(props) => (
            <TemplateInstancesPage
              embedded
              aspectId={dataSourceReferenceId}
            />
          )} />
        }
        { dataSourceReferenceId &&
          <Route exact path={`${path}/pick`} render={(props) => (
            <TemplateInstanceCreator
              aspectId={dataSourceReferenceId}
            />
          )} />
        }
      </Switch>
    );
  }
});