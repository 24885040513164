import React from 'react';

import {
  LinearProgress
} from '@material-ui/core';

type Props = {
  value?: number;
  style?: React.CSSProperties;
}

export class ProgressBar extends React.Component<Props> {

  render() {
    const { value, style } = this.props;

    return (
      <LinearProgress
        value={value || 0}
        variant="indeterminate"
        color="secondary"
        style={{
          ...style
        }}
      />
    )
  }
}