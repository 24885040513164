import React from 'react';

import {
  Prompt
} from 'react-router-dom';

import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';

import {
  ErrorAlert
} from '../components';

interface Props<I, O> {
  title: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  fullHeight?: boolean;
  trigger: React.ReactElement<any>;
  resource: I;
  action: (resource: I) => Promise<O>;
  onSuccess: (resource: O) => void;
  onCancel?: () => void;
  renderForm: (resource: I, ref: React.RefObject<any>, handleSubmit: (values: I) => void) => React.ReactElement;
}

class State {
  open: boolean = false;
  error?: Error;
  isSubmitting: boolean = false;
}

export class ResourceEditDialog<I extends unknown, O = I> extends React.Component<Props<I, O>, State> {

  readonly state = new State();

  readonly formRef = React.createRef<any>();

  handleSubmit = (values: I) => {
    this.setState({ isSubmitting: true, error: undefined })
    this.props.action(values)
    .then(resource => {
      this.setState(new State());
      this.props.onSuccess(resource);
    })
    .catch(error => {
      this.setState({ error, isSubmitting: false });
    })
  }

  handleCancel = () => {
    this.setState(new State());
    if (this.props.onCancel)
      this.props.onCancel();
  }

  componentDidUpdate = () => {
    window.onbeforeunload = this.state.open ? () => true : null;
  }

  render() {
    const { fullHeight, maxWidth = 'xs', trigger, title, resource, renderForm } = this.props;
    const { open, error, isSubmitting } = this.state;

    const paperProps = fullHeight ? {style: {height: '100vh' }} : undefined;

    const triggerElement = React.cloneElement(trigger, {
      onClick: () => {
        if (trigger.props.onClick)
          trigger.props.onClick();
        this.setState({ open: true });
      }
    });

    return (
      <React.Fragment>
        {triggerElement}
        <Dialog
          open={open}
          maxWidth={maxWidth}
          disableBackdropClick
          fullWidth
          onClose={this.handleCancel}
          PaperProps={paperProps}
        >
          <DialogTitle>{title}</DialogTitle>
          { error && <ErrorAlert error={error} /> }
          <Prompt when={open} message="Changes you made may not be saved." />
          <DialogContent style={{flex: 1}}>
            { renderForm(resource, this.formRef, this.handleSubmit) }
          </DialogContent>
          <DialogActions>
            <Button color="secondary"
              onClick={this.handleCancel}>
              Cancel
            </Button>
            <Button color="primary" variant="contained"
              disabled={isSubmitting}
              startIcon={ isSubmitting && <CircularProgress color="inherit" size={20} /> }
              onClick={() => this.formRef.current.submitForm()}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}