import preval from 'preval.macro';

const API_BASE = preval`module.exports = process.env.API_BASE;`;
const API_KEY = preval`module.exports = process.env.API_KEY;`;

export default {
  baseURL: API_BASE,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Api-Key': API_KEY
  },
  // timeout: 5000
}