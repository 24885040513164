import React from 'react';

import {
  LibraryAddCheck as AutoencoderIcon,
  TabUnselected as ObjectDetectionIcon,
  Description as UnknownIcon
} from '@material-ui/icons';

import API, { Template, TemplateInput, DataType, LabelType } from '../api';

import { orderBy } from 'lodash';

import { searchCase } from '../utils';

export const getTemplateIcon = (type: string, props?: any): React.ReactElement => {
  if (type === 'objectdetection')
    return React.createElement(ObjectDetectionIcon, props);
  else if (type === 'autoencoder')
    return React.createElement(AutoencoderIcon, props);
  else
    return React.createElement(UnknownIcon, props);
}

export function filterValidTemplates(templates: Template[]): Template[] {
  return templates.filter(t => !!t.settings);
}

const filterTemplatesByText = (templates: Template[], text: string = ''): Template[] => {
  const searchText = searchCase(text);

  return templates
    .filter(t => {
      return !searchText.length
        || searchCase(`${t.name} ${t.description} ${t.version}`).indexOf(searchText) !== -1;
    });
}

export const filterTemplates = (templates: Template[], searchText: string, selection?: Template): Template[] => {
  const filtered = filterTemplatesByText(templates, searchText);
  const sorted = orderBy(filtered, t => t.name.toLowerCase());
  return sorted;
}

export const uploadTemplateVersionArtifact = (template: Template, file: File | undefined): Promise<Template> => {
  if (file) {
    return API.templates.createOrUpdateTemplateVersionArtifact(template.id, template.templateVersionId)
    .then(artifact => {
      return API.templates.uploadTemplateVersionArtifact(artifact.s3PresignedUploadUrl, file)
    })
    .then(artifact => template);
  }
  return Promise.resolve(template);
}

export const newTemplateInput = (): TemplateInput => {
  return {
    name: '',
    description: '',
    templateType: '',
    version: '',
    dataType: DataType.Images,
    trainingRequired: true,
    settings: {
      labelSettings: {
        singleLabels: false,
        fixedLabels: false,
        labelConfiguration: {
          labelType: LabelType.Classification,
          labelDefinitions: [
            { id: 0, name: 'A' },
            { id: 1, name: 'B' },
            { id: 2, name: 'C' },
          ]
        }
      },
      training: {},
      prediction: {},
      commons: {},
      trainingSchema: {},
      predictionSchema: {},
      commonsSchema: {}
    }
  }
}