import React from 'react';

import {
  IconButton
} from '@material-ui/core';

import {
  MoreHoriz as ViewIcon
} from '@material-ui/icons';

import {
  DataCollection
} from '../api';

import {
  ContentDialog,
  DataCollectionViewer,
} from '../components';

interface Props {
  dataCollection: DataCollection;
  onUpdate: (dataCollection: DataCollection) => void;
  onDelete: (dataCollection: DataCollection) => void;
}

class State {
  anchor: HTMLButtonElement | null = null;
}

export class DataCollectionMenu extends React.Component<Props, State> {

  readonly state = new State();

  show = (evt: any) => {
    this.setState({ anchor: evt.currentTarget });
  }

  hide = () => {
    this.setState({ anchor: null });
  }

  render() {
    const { dataCollection, onUpdate, onDelete } = this.props;

    return (
      <ContentDialog
        trigger={<IconButton><ViewIcon /></IconButton>}
        renderContent={(closeViewer) => (
          <DataCollectionViewer
            dataCollectionId={dataCollection.id}
            onClose={closeViewer}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        )}
      />
    );
  }
}