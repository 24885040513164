import React from 'react';

import {
  FormikErrors
} from 'formik';

import {
  Box,
  FormControl,
  FormLabel,
  FormHelperText,
  Slider,
  Typography,
  TextField,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core';

import {
  ParameterValue,
  ParameterSchema
} from '../api';

import {
  titleCase
} from '../utils';

interface Props {
  attr: string;
  onChange: (evt: React.ChangeEvent<any>) => void;
  name: string;
  schema: ParameterSchema;
  values: Record<string, ParameterValue>;
  errors?: FormikErrors<Record<string, ParameterValue>>;
}

class State {
  value: number;

  constructor(props: Props) {
    this.value = props.values[props.attr] as number;
  }
}

export class ParameterControl extends React.Component<Props, State> {

  readonly state = new State(this.props);

  handleSliderChange = (evt: React.ChangeEvent<any>, value: number | number[]) => {
    evt.target.name = this.props.name;
    evt.target.value = value;
    this.props.onChange(evt);
  }

  handleBooleanChange = (evt: React.ChangeEvent<any>) => {
    evt.target.value = evt.target.value === 'true';
    this.props.onChange(evt);
  }

  render() {
    const { values, attr, name, schema, errors, onChange } = this.props;
    const { value } = this.state;

    return (
      <FormControl
        margin="dense"
        fullWidth
        size="small"
        error={errors && !!errors[attr]}>
        <FormLabel>
          <Typography variant="body2" color="textPrimary">
           { titleCase(attr)} { schema.type === 'number' && `(${value !== undefined ? value : '-'})` }
          </Typography>
        </FormLabel>
        { schema.type === 'number' && (
          <Box paddingLeft={0.5} paddingRight={2}>
            <Slider
              style={{paddingBottom: 5}}
              name={name}
              value={value}
              onChange={(evt, value) => this.setState({ value: value as number }) }
              onChangeCommitted={this.handleSliderChange}
              min={schema.minimum}
              max={schema.maximum}
              step={schema.multipleOf}
              valueLabelDisplay="off"
            />
          </Box>
        )}
        { schema.type === 'enum'&& (
          <TextField
            select
            required
            name={name}
            defaultValue={value}
            onChange={onChange}
          >
            {values[attr] === undefined &&
              <MenuItem value={undefined}>
                -
              </MenuItem>
            }
            {schema.values.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        )}
        { schema.type === 'string' && !('enum' in schema) && (
          <TextField
            required
            name={name}
            defaultValue={value || ''}
            onChange={onChange} />
        )}
        { schema.type === 'boolean' && (
          <RadioGroup
            row
            name={name}
            defaultValue={String(value) || 'false'}
            onChange={this.handleBooleanChange}
            style={{marginBottom: -8}}
          >
            {
              values[attr] === undefined &&
              <FormControlLabel
                label={<Typography variant="body2">-</Typography>}
                value="undefined"
                control={<Radio size="small" />}
              />
            }
            <FormControlLabel
              label={<Typography variant="body2">Yes</Typography>}
              value="true"
              control={<Radio size="small" color="primary" />}
            />
            <FormControlLabel
              label={<Typography variant="body2">No</Typography>}
              color="primary"
              value="false"
              control={<Radio size="small" color="primary" />}
            />
          </RadioGroup>
        )}
        <FormHelperText>{(errors && errors[attr]) || schema.description}</FormHelperText>
      </FormControl>
    );
  }
}