import React from 'react';

import {
  NavLink,
  LinkProps
} from 'react-router-dom';

import {
  Typography
} from '@material-ui/core';

import {
  Skeleton
} from '@material-ui/lab';

interface Props {
  name: string;
  href?: string;
  secondary?: boolean;
}

export class AppLinkSkeleton extends React.Component {
  render() {
    return (
      <Skeleton width={200} height={20} style={{backgroundColor: 'rgba(255, 255, 255, 0.22)'}} />
    );
  }
}

export class AppLink extends React.Component<Props> {

  render() {
    const { name, href, secondary } = this.props;

    const styles = {
      fontWeight: secondary ? 300 : 500,
      color: 'inherit'
    }

    return href ? (
      <Typography variant="h6" style={{ cursor: 'pointer', textDecoration: 'none', ...styles}}
        component={
          React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>((props, ref) => (
            <NavLink to={href} {...props} ref={ref} />
          )
        )}
      >
        { name }
      </Typography>
    ) : (
      <Typography variant="h6" style={styles}>{ name }</Typography>
    );
  }
}