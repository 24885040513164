import { DataCollection, DataCollectionInput, DataType, DataCollectionObject } from '../models';

import HTTPClient from '../http-client';

import { pick } from 'lodash';

export class DataCollections extends HTTPClient {

  listDataCollections (query: { dataSourceReferenceId?: string, dataType: DataType }): Promise<Array<DataCollection>> {
    return this.request({
      method: 'get',
      path: `/data-collections`,
      query
    })
  }

  getDataCollection (dataCollectionId: string): Promise<DataCollection> {
    return this.request({
      method: 'get',
      path: `/data-collections/${dataCollectionId}`,
    })
  }

  createDataCollection (dataCollectionInput: DataCollectionInput): Promise<DataCollection> {
    return this.request({
      method: 'post',
      path: `/data-collections`,
      body: pick(dataCollectionInput, 'dataType', 'name', 'description', 'dataSourceReferenceId')
    })
  }

  updateDataCollection (dataCollectionId: string, dataCollectionInput: DataCollectionInput): Promise<DataCollection> {
    return this.request({
      method: 'put',
      path: `/data-collections/${dataCollectionId}`,
      body: pick(dataCollectionInput, 'name', 'description', 'dataSourceReferenceId')
    })
  }

  deleteDataCollection (dataCollectionId: string): Promise<void> {
    return this.request({
      method: 'delete',
      path: `/data-collections/${dataCollectionId}`
    })
  }

  getDataCollectionObjects (dataCollectionId: string, query: { skip?: number, take?: number, ordering?: 'asc' | 'desc' }): Promise<Array<DataCollectionObject>> {
    return this.request({
      method: 'get',
      path: `/data-collections/${dataCollectionId}/objects`,
      query
    })
    .then(data => data.objects)
  }

  // getDataCollectionObjectsIdentifiers (dataCollectionId: string): Promise<Array<string>> {
  //   return this.request({
  //     method: 'get',
  //     path: `/data-collections/${dataCollectionId}/objects/identifiers`
  //   })
  // }

  // searchDataCollectionObjects (dataCollectionId: string, objectIds: Array<String>): Promise<Array<DataCollectionObject>> {
  //   return this.request({
  //     method: 'post',
  //     path: `/data-collections/${dataCollectionId}/objects/searches`,
  //     body: { objectIds }
  //   })
  // }

  createDataCollectionObject (dataCollectionId: string, originalFilename: string): Promise<DataCollectionObject> {
    return this.request({
      method: 'post',
      path: `/data-collections/${dataCollectionId}/objects`,
      body: { originalFilename }
    })
  }

  uploadDataCollectionObject(dataCollectionObjectUploadUrl: string, file: File): Promise<void> {
    return this.request({
      method: 'put',
      path: dataCollectionObjectUploadUrl,
      body: file
    })
  }

  deleteDataCollectionObject(dataCollectionId: string, dataCollectionObjectId: string): Promise<void> {
    return this.request({
      method: 'delete',
      path: `/data-collections/${dataCollectionId}/objects/${dataCollectionObjectId}`
    })
  }
}