import React from 'react';

import {
  List
} from '@material-ui/core';

import {
  DatasetItemSkeleton
} from '../components';

interface Props {
  countItems: number;
}

export class DatasetListSkeleton extends React.Component<Props> {
  render() {
    const { countItems } = this.props;

    return (
      <List dense disablePadding>
        { Array(countItems).fill(0).map((val, index) => (
          <DatasetItemSkeleton key={index} />
        )) }
      </List>
    );
  }
}