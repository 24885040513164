import React from 'react';

import {
  Box,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography
} from '@material-ui/core';

import {
  AppTopBar,
  ImageCanvas
} from '../components';

import {
  ArtifactUploadStatus,
  DataCollectionObject,
  LabelsetEntry,
  LabelType
} from '../api';

interface Props { }

class State {
  labelType: LabelType = LabelType.Segmentation;
  update?: LabelsetEntry;
}

export class CanvasTestPage extends React.Component<Props, State> {

  readonly state = new State();

  render() {
    const { labelType, update } = this.state;

    const labelDefinitions = [ { id: 0, name: 'A' } ];
    const cropBox = { top: 0.2, right: 0.8, bottom: 0.8, left: 0.2 };
    const image: DataCollectionObject = {
      "id": "d82df5c5-459f-49a4-9421-7a2cb0fcd398",
      "fileUploadStatus": ArtifactUploadStatus.Uploaded,
      "s3PresignedUploadUrl": "",
      "s3PresignedUrl": "https://aif-develop-data-l7147wcf.s3.amazonaws.com/dataset_objects/91378f32-9baa-11ec-9c89-00155d884382/d82df5c5-459f-49a4-9421-7a2cb0fcd398?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAVY4XN444GFQ4JU6T%2F20220710%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20220710T042127Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEOz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDGV1LWNlbnRyYWwtMSJIMEYCIQCGsCC4bG2docZaDHJsQFfU5iRVjpmsQCGhtZxhFysV1AIhAJV0IjlD6KY7bFiyZ2uycglMM%2BSn6uZamWcspozx2EPVKqECCCUQAxoMMzk3MDY1MTg1MDgwIgwfFmiqj7V1QnKCDbgq%2FgGLoz5fM5DFAv7%2FdW6rqHRdPzoPJkXhj0XKgDZ9GkbAJXWo%2BxqZiroiuJTGbNxHXte82b9Z9QpumDZGWsPE1A%2FUbif7%2Bvu%2BAeD5Q9N3jKas%2BMy6I%2BS9J0zVKif08NxIla2cTZvqCS16SjK8mfgeHHU%2BmxD70X2MC3gGlRGTE05lEQu9yB%2FIbJZoGFhVLnKRCWRoFCYugTkuhtlAOFHJk6ssQacGkTtyatV5GbXMbmzTsv1UF%2BgT7Vc1JhOVq62T6s1dyH2EBd5MzwBQ7FbqbZB2ehCF0YIsDk4pjF76vvBAkIRNz4wIgnn5SXPfOw2G2O2gly0jqKoptDV51Ula4DDYoamWBjqZAaCtnVqQsS65qvPEgCh4vuyEkYHaar9oM4%2FVgMlf8O%2Bu0XmWSYth%2FA%2BRwCZHfiramm24pTsxQD9QpDxzJa1MZ7JrSvchVX%2BiTRI7ZdamA6z554JLRTDKG8yyCYPsFMeb6Od%2BXbpPV5kThSYnMpbI%2FosGmhLAYpPg7hhq43vil9IuaTlrwqxfauLuLKpWmROsgb2GJVJeYSQJ2Q%3D%3D&X-Amz-Signature=ad17d0cf24bb6b7592e1ab9252f821c748c13c72681f29018d58090e78041c1c",
      "attributes": {
        "originalFilename": "hazelnut_6.jpeg",
        "aspectRatio": 1.5,
        "previewUrl": "https://images.pexels.com/photos/2325446/pexels-photo-2325446.jpeg",
        "thumbnailUrl": "https://aif-develop-data-l7147wcf.s3.amazonaws.com/dataset_objects/91378f32-9baa-11ec-9c89-00155d884382/d82df5c5-459f-49a4-9421-7a2cb0fcd398_thumbnail?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAVY4XN444GFQ4JU6T%2F20220710%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20220710T042127Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEOz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDGV1LWNlbnRyYWwtMSJIMEYCIQCGsCC4bG2docZaDHJsQFfU5iRVjpmsQCGhtZxhFysV1AIhAJV0IjlD6KY7bFiyZ2uycglMM%2BSn6uZamWcspozx2EPVKqECCCUQAxoMMzk3MDY1MTg1MDgwIgwfFmiqj7V1QnKCDbgq%2FgGLoz5fM5DFAv7%2FdW6rqHRdPzoPJkXhj0XKgDZ9GkbAJXWo%2BxqZiroiuJTGbNxHXte82b9Z9QpumDZGWsPE1A%2FUbif7%2Bvu%2BAeD5Q9N3jKas%2BMy6I%2BS9J0zVKif08NxIla2cTZvqCS16SjK8mfgeHHU%2BmxD70X2MC3gGlRGTE05lEQu9yB%2FIbJZoGFhVLnKRCWRoFCYugTkuhtlAOFHJk6ssQacGkTtyatV5GbXMbmzTsv1UF%2BgT7Vc1JhOVq62T6s1dyH2EBd5MzwBQ7FbqbZB2ehCF0YIsDk4pjF76vvBAkIRNz4wIgnn5SXPfOw2G2O2gly0jqKoptDV51Ula4DDYoamWBjqZAaCtnVqQsS65qvPEgCh4vuyEkYHaar9oM4%2FVgMlf8O%2Bu0XmWSYth%2FA%2BRwCZHfiramm24pTsxQD9QpDxzJa1MZ7JrSvchVX%2BiTRI7ZdamA6z554JLRTDKG8yyCYPsFMeb6Od%2BXbpPV5kThSYnMpbI%2FosGmhLAYpPg7hhq43vil9IuaTlrwqxfauLuLKpWmROsgb2GJVJeYSQJ2Q%3D%3D&X-Amz-Signature=1977466a00459abc480be611e30a59b4a8f21b318ecb1099c2e2fa136c9478cc"
      },
      "createdAt": "2022-06-29T10:50:16.993014",
      "updatedAt": "2022-06-29T10:50:16.993028"
    };
    const labelsetEntry: LabelsetEntry = {
      datasetObjectId: 'd82df5c5-459f-49a4-9421-7a2cb0fcd398',
      labels: labelType === LabelType.BoundingBoxes ? [
        {
          "labelId": 0,
          "type": "TRBLLabel",
          "labelAnnotation": {
            "left": 0.5453911378555799,
            "top": 0.6273522975929979,
            "right": 0.5847784463894967,
            "bottom": 0.6956236323851204
          }
        }
      ] : [
        {
          "labelId": 0,
          "type": "PolygonLabel",
          "labelAnnotation": {
            "points": [
              {
                "x": 0.27449398249452955,
                "y": 0.45864332603938734
              },
              {
                "x": 0.28631017505470463,
                "y": 0.4448577680525164
              },
              {
                "x": 0.30512855579868714,
                "y": 0.4468271334792123
              },
              {
                "x": 0.3081920131291029,
                "y": 0.4770240700218819
              },
              {
                "x": 0.2880607221006565,
                "y": 0.4967177242888403
              },
              {
                "x": 0.2727434354485777,
                "y": 0.4579868708971554
              }
            ]
          }
        }
      ]
    };

    return (
      <React.Fragment>
        <AppTopBar>
          <Typography variant="h6">
            Canvas Test Page
          </Typography>
          <Box mx="auto" />
          <RadioGroup name="labelType" value={labelType} onChange={evt => this.setState({ labelType: evt.target.value as LabelType, update: undefined })} style={{flexDirection: 'row'}}>
            <FormControlLabel value={LabelType.BoundingBoxes} control={<Radio />} label="Bounding Boxes" />
            <FormControlLabel value={LabelType.Segmentation} control={<Radio />} label="Segmentation" />
          </RadioGroup>
        </AppTopBar>
        <Box display="flex" flex={1} flexDirection="row">
          <Box flex={0.4} style={{overflowY: 'scroll'}}>
            <pre style={{maxHeight: 0}}>
              {JSON.stringify(labelType, null, 2)}
              {JSON.stringify(update || labelsetEntry, null, 2)}
            </pre>
           </Box>
          <ImageCanvas
            key={labelType}
            image={image}
            labelDefinitions={labelDefinitions}
            labelType={labelType}
            labelsetEntry={labelsetEntry}
            aspectRatio={image.attributes.aspectRatio}
            cropBox={cropBox}
            style={{flex: 1, justifyContent: 'center', display: 'flex'}}
            onChange={update => this.setState({ update })}
          />
        </Box>
      </React.Fragment>
    )
  }
}