import { Metric } from '../api';

import moment from 'moment';

export function convertMetricsToChartsData(metrics: Array<Metric>): Record<string, [[number, number]]> {

  const sortedMetricByDateAsc = metrics.sort((a, b) => {
    return moment.utc(a.createdAt).isBefore(moment.utc(b.createdAt)) ? -1 : 1
  })

  const result = sortedMetricByDateAsc.reduce((r, metric) => {
    Object.entries(metric.data)
    .forEach(([key, value]) => {
      r[key] = r[key] || [];
      r[key].push([
        +moment.utc(metric.createdAt),
        // metric.createdAt,
        value
      ]);
    })
    return r;
  }, {} as Record<string, [[number, number]]>);

  return result;
}