import React from 'react';

import {
  Typography,
  Box,
  Grid,
  Button
} from '@material-ui/core';

import {
  ErrorOutline as ErrorOutlineIcon
} from '@material-ui/icons';

interface Props {
  message?: string;
  onReload?: () => void;
}

export class ErrorBox extends React.Component<Props> {

  render() {
    const { message, onReload } = this.props;

    return (
      <Box display="flex" flexDirection="column" flex={1} padding={1}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item>
            <ErrorOutlineIcon fontSize="large" />
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">{ message || 'Something went wrong!' }</Typography>
          </Grid>
          { onReload &&
            <Grid item>
              <Button color="primary" onClick={onReload}>Try Again</Button>
            </Grid>
          }
        </Grid>
      </Box>
    )
  }
}