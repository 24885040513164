import React from 'react';

import {
  Chart
} from 'chart.js';

import * as colors from '@material-ui/core/colors';

import {
  titleCase
} from '../utils';

type Props = {
  label: string;
  index: number;
  data: [[number, number]];
  style?: React.CSSProperties;
}

export class MetricsChart extends React.Component<Props> {

  static readonly COLORS = [
    colors.grey['600'],
    colors.grey['400'],
    colors.grey['800'],
    colors.grey['200']
  ];

  readonly chartRef = React.createRef<HTMLCanvasElement>();

  readonly state = {};

  // TODO findout why
  // @ts-ignore
  private chart?: Chart;

  componentDidMount() {
    const { label, data } = this.props;

    const canvas = this.chartRef.current;
    if (canvas) {
      this.chart = new Chart(canvas, {
        type: 'line',
        data: {
          datasets: [
            {
              label: titleCase(label),
              fill: false,
              borderColor: MetricsChart.COLORS[0],
              backgroundColor: MetricsChart.COLORS[0],
              borderWidth: 2,
              showLine: true,
              data: data.map(p => ({
                x: p[0], y: p[1]
              }))
            }
          ]
        },
        options: {
          responsive: true,
          title: {
            display: true,
            text: titleCase(label),
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: 14
          },
          tooltips: {
            mode: 'index',
            titleFontSize: 14,
            bodyFontSize: 10,
            callbacks: {
              // label: (tooltipItem, data) => {
              //   return `${label}: ${tooltipItem.yLabel}`;
              // }
            }
          },
          hover: {
            mode: 'dataset'
          },
          legend: {
            display: false, //!!Object.keys(data).length,
            position: 'right',
            align: 'start',
            fullWidth: true,
            labels: {
              boxWidth: 10,
              fontSize: 10,
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
            }
          },
          scales: {
            xAxes: [
              {
                type: 'time',
                display: true,
                bounds: 'ticks',
                scaleLabel: {
                  display: true,
                  labelString: 'Time',
                  lineHeight: 1,
                  padding: 4,
                  fontSize: 10,
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                },
                ticks: {
                  suggestedMin: 0,
                  stepSize: 1,
                  fontSize: 10
                }
              }
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: 'Value',
                  lineHeight: 1,
                  padding: 4,
                  fontSize: 10,
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                },
                ticks: {
                  suggestedMin: 0,
                  fontSize: 10
                }
              }
            ]
          }
        }
      });
    }
  }

  render() {
    const { style } = this.props;
    return (
      <canvas ref={this.chartRef} style={style} />
    )
  }
}