import React from 'react';

import {
  Divider,
  Toolbar,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';

import {
  Skeleton
} from '@material-ui/lab';

import {
  Dataset
} from '../api';

import {
  DatasetIcon,
  ErrorBox,
  SearchItem
} from '../components';

import {
  searchCase
} from '../utils';

interface Props {
  datasets: Dataset[] | (() => Promise<Dataset[]>);
  selection?: Dataset;
  renderSelection: (datasets: Dataset[], dataset: Dataset, manager: DatasetManager) => React.ReactNode;
  renderActions?: (datasets: Dataset[], manager: DatasetManager) => React.ReactNode;
  onSelection?: (dataset: Dataset | undefined) => void;
}

class State {
  loading: boolean = true;
  selection?: Dataset;
  error?: Error;
  datasets?: Dataset[];
  searchText: string = '';
}

export class DatasetManager extends React.Component<Props, State> {

  readonly state = new State();

  reload = (selection: Dataset | undefined, notify: boolean = true) => {
    const { datasets, onSelection } = this.props;

    this.setState({ loading: true, datasets: undefined, error: undefined, selection });
    if (notify && onSelection)
      onSelection(selection);

    Promise.resolve(datasets instanceof Function ? datasets() : datasets)
    .then(datasets => {
      this.setState({ loading: false, datasets })
    })
    .catch(error => this.setState({ loading: false, error }))
  }

  componentDidMount() {
    this.reload(this.props.selection, false);
  }

  handleSelection = (selection: Dataset) => {
    this.setState({ selection });
    this.props.onSelection?.(selection);
  }

  render() {
    const { renderActions, renderSelection } = this.props;
    const { selection, loading, error, datasets, searchText } = this.state;

    return (
      <Box display="flex" flex={1} flexDirection="column">
        <Toolbar>
          <DatasetIcon />
          <Box mx={1} />
          <Typography variant="h6" noWrap>Datasets</Typography>
          <Box mx={2} />
          { datasets && renderActions && renderActions(datasets, this) }
        </Toolbar>
        <Divider />
        <Box display="flex" flex="1" flexDirection="row">
          <Box display="flex" flex={1} style={{maxWidth: 250}}>
            { loading && (
              <List style={{flex: 1}}>
                { Array(5).fill(0).map((count, itemIndex) => (
                 <ListItem key={itemIndex}>
                    <Skeleton height={15} width="80%" />
                 </ListItem>
                )) }
              </List>
            )}
            { error && <ErrorBox message={error?.message} onReload={() => this.reload(this.props.selection)} /> }
            { datasets &&
            <Box style={{ flex: '1 1 1px', overflowY: 'scroll'}}>
              <List dense disablePadding>
                <SearchItem hideIcon onUpdate={(searchText) => this.setState({ searchText })} />
              </List>
              <List dense disablePadding>
                { datasets
                    .filter(d => !searchText || searchCase(d.name).indexOf(searchCase(searchText)) !== -1)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(dataset => (
                  <ListItem key={dataset.id}
                    button
                    selected={dataset.datasetVersionId === selection?.datasetVersionId}
                    onClick={() => this.handleSelection(dataset)}
                  >
                    <ListItemText primary={ <Typography variant="body2">{dataset.name} </Typography>} />
                  </ListItem>
                )) }
              </List>
            </Box>
            }
          </Box>
          <Divider orientation="vertical" />
          <Box flex={1} display="flex">
            { !selection && <Box padding={2}><Typography variant="body2" color="textSecondary">Select a Dataset.</Typography></Box> }
            { datasets && selection && renderSelection && renderSelection(datasets, selection, this) }
          </Box>
        </Box>
      </Box>
    )
  }
}