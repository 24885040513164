import React from 'react';

import auth from '../auth';

export class LogoutPage extends React.Component {

  componentDidMount() {
    auth.signout();
  }

  render() {
    return null;
  }
}