import React from 'react';

import {
  Box,
  Button,
  Toolbar,
  Typography
} from '@material-ui/core';

import {
  Add as AddIcon
} from '@material-ui/icons';

import API, {
  Dataset,
  DataType
} from '../api';

import {
  DatasetIcon,
  ContentDialog,
  DatasetList,
  DatasetListSkeleton,
  DatasetEditor,
  ErrorBox,
  SearchField
} from '../components';

import {
  searchCase
} from '../utils';

interface Props {
  dataCollectionId?: string;
  selection?: Dataset;
  onSelection?: (dataset?: Dataset) => void;
  style?: React.CSSProperties;
}

class State {
  loading: boolean = true;
  error?: Error;
  datasets?: Dataset[];
  selection?: Dataset;
  searchText: string = '';

  constructor(props: Props) {
    this.selection = props.selection
  }
}

export class DatasetPicker extends React.Component<Props, State> {

  readonly state = new State(this.props);

  componentDidMount () {
    this.setState({ loading: true, error: undefined, datasets: undefined })
    API.datasets.listDatasets({ dataCollectionId: this.props.dataCollectionId, dataType: DataType.Images })
    .then(datasets => this.setState({ datasets, loading: false }))
    .catch(error => this.setState({ error, loading: false }))
  }

  toggleSelection = (meta: Dataset) => {
    const selection = meta.id !== this.state.selection?.id ? meta : undefined;
    this.setState({ selection });
    this.props.onSelection?.(selection);
  }

  handleUpdate = (datasets: Dataset[], dataset: Dataset) => {
    this.setState({
      datasets: datasets.map(d => d.datasetVersionId === dataset.datasetVersionId ? dataset : d )
    });
  }

  handleDelete = (datasets: Dataset[], dataset: Dataset) => {
    const { selection } = this.state;
    const updates = datasets.filter(d => d.datasetVersionId !== dataset.datasetVersionId);
    if (selection && selection.datasetVersionId === dataset.datasetVersionId) {
      this.setState({
        datasets: updates,
        selection: undefined
      });
      this.props.onSelection?.(undefined);
    }
    else {
      this.setState({
        datasets: updates
      });
    }
  }

  render() {
    const { style, dataCollectionId } = this.props;
    const { datasets, selection, loading, error, searchText } = this.state;

    const filteredDatasets = datasets ? datasets
      .filter(d => !searchText || searchCase(d.name).indexOf(searchCase(searchText)) !== -1)
      .sort((a, b) => a.name.localeCompare(b.name)) : [];

    return (
      <Box display="flex" flexDirection="column" style={style}>
        <Toolbar variant="dense">
          <DatasetIcon />
          <Box mx={1} />
          <Typography variant="subtitle1">Pick a Dataset</Typography>
          <Box mx={2} />
          { datasets && datasets.length > 0 &&
            <SearchField
              autoFocus
              value={searchText}
              onChange={searchText => this.setState({ searchText })}
            />
          }
          <Box mx="auto" />
          { datasets && dataCollectionId &&
            <ContentDialog
              trigger={<Button color="primary" size="small" startIcon={<AddIcon />}>New</Button>}
              fullscreen
              renderContent={(close) => (
                <DatasetEditor
                  dataCollectionId={dataCollectionId}
                  onCreate={dataset => {
                    this.setState({
                      datasets: [...datasets, dataset]
                    });
                  }}
                  onUpdate={dataset => {
                    this.setState({
                      datasets: datasets.map(d => d.datasetVersionId === dataset.datasetVersionId ? dataset : d)
                    });
                  }}
                  onClose={close}
                />
              )}
            />
          }
        </Toolbar>
        <Box style={{flex: '1 1 1px', overflowY: 'scroll'}}>
          { loading && <DatasetListSkeleton countItems={10} /> }
          { error && <ErrorBox message={error?.message} onReload={() => this.componentDidMount()} /> }
          { datasets && filteredDatasets.length > 0 &&
            <DatasetList
              items={filteredDatasets}
              selection={selection}
              onSelection={this.toggleSelection}
              onUpdate={dataset => this.handleUpdate(datasets, dataset) }
              onDelete={dataset => this.handleDelete(datasets, dataset) }
            />
          }
          { datasets && !datasets.length&&
            <Box p={2}>
              <Typography color="textSecondary" variant="body2">No Datasets available.</Typography>
            </Box>
          }
        </Box>
      </Box>
    );
  }
}