import React from 'react';

import {
  Prompt
} from 'react-router-dom';

import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';

import API, {
  LabelConfiguration,
  Labelset
} from '../api';

import {
  ErrorAlert,
  LabelConfigurationForm
} from '../components';

interface Props {
  title: string;
  trigger: React.ReactElement<any>;
  labelset: Labelset;
  onSuccess: (labelset: Labelset) => void;
}

class State {
  open: boolean = false;
  saving: boolean = false;
  error?: Error;
}

export class LabelDefinitionsDialog extends React.Component<Props, State> {

  readonly state = new State();

  readonly formRef = React.createRef<any>();

  handleSubmit = (labelConfiguration: LabelConfiguration) => {
    const { labelset } = this.props;
    this.setState({ saving: true, error: undefined });
    API.labelsets.updateLabelset(labelset.id, {...labelset, labelConfiguration })
    .then(labelset => {
      this.setState({ saving: false, open: false });
      this.props.onSuccess(labelset);
    })
    .catch(error => this.setState({ saving: false, error }))
  }

  handleCancel = () => {
    this.setState({ open: false })
  }

  componentDidUpdate = () => {
    window.onbeforeunload = this.state.open ? () => true : null;
  }

  render() {
    const { trigger, title, labelset } = this.props;
    const { open, error, saving } = this.state;

    const triggerElement = React.cloneElement(trigger, {
      onClick: () => {
        if (trigger.props.onClick)
          trigger.props.onClick();
        this.setState({ open: true });
      }
    });

    return (
      <React.Fragment>
        {triggerElement}
        <Dialog
          open={open}
          maxWidth={'sm'}
          disableBackdropClick
          fullWidth
          onClose={this.handleCancel}
          PaperProps={{style: {height: 600 }}}
        >
          <DialogTitle>{title}</DialogTitle>
          { error && <ErrorAlert error={error} /> }
          <Prompt when={open} message="Changes you made may not be saved." />
          <DialogContent>
            <LabelConfigurationForm
              labelConfiguration={labelset.labelConfiguration}
              innerRef={this.formRef}
              onSubmit={this.handleSubmit}
            />
          </DialogContent>
          <DialogActions>
            <Button color="secondary"
              onClick={this.handleCancel}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={saving}
              startIcon={ saving && <CircularProgress color="inherit" size={20} /> }
              onClick={() => this.formRef.current.submitForm()}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}