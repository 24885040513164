/** Deep clone function based on JSON stringify/parse */
export function deepClone<T>(object?: T): T {
  return object === undefined ? undefined : JSON.parse(JSON.stringify(object));
}

/** Deep equal comparaison based on JSON stringify */
export function deepEqual<T>(a: T, b: T): boolean {
  return JSON.stringify(a) === JSON.stringify(b);
}

type IfEquals<X, Y, A=X, B=never> =
  (<T>() => T extends X ? 1 : 2) extends
  (<T>() => T extends Y ? 1 : 2) ? A : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>
}[keyof T];

export type Writeonly<T> = Pick<T, WritableKeys<T>>;