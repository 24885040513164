import React from 'react';

import {
  FormikErrors
} from 'formik';

import {
  Box,
  Typography,
  Grid,
  Toolbar,
  IconButton,
  Collapse
} from '@material-ui/core';

import {
  ExpandLess as CollapseIcon,
  ExpandMore as ExpandIcon
} from '@material-ui/icons';

import {
  ParameterSchemas,
  ParameterValues
} from '../api';

import {
  ParameterControl
} from '../components';

interface Props {
  title: string;
  schemas: ParameterSchemas;
  values: ParameterValues;
  namePath?: string;
  collapsed?: boolean;
  errors?: FormikErrors<ParameterValues>;
  onChange: (e: React.ChangeEvent<any>) => void;
}

class State {
  constructor(props: Props) {
    this.expanded = !props.collapsed;
  }

  expanded: boolean;
}

export class ParametersControlGrid extends React.Component<Props, State> {

  readonly state = new State(this.props);

  render() {
    const { title, schemas, values, namePath, errors, collapsed, onChange } = this.props;
    const { expanded } = this.state;

    const entries = Object.entries(schemas), count = entries.length, empty = count === 0;

    return (
      <React.Fragment>
        <Toolbar variant="dense" disableGutters>
          { collapsed !== undefined &&
            <IconButton size="small"
              onClick={e => this.setState({ expanded: !expanded})}>
              { expanded ? <CollapseIcon /> : <ExpandIcon /> }
            </IconButton>
          }
          <Typography variant="subtitle2">{title} {`(${count})`}</Typography>
        </Toolbar>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box paddingX={1}>
            { empty &&
              <Box paddingY={1}>
                <Typography variant="body2" color="textSecondary" paragraph>No parameters available.</Typography>
              </Box>
            }
            <Grid container spacing={1}>
              { entries.map(([name, schema], index) => (
              <Grid key={index} item xs={12} sm={12} md={12} lg={6}>
                <ParameterControl
                  key={name}
                  attr={name}
                  name={namePath ? `${namePath}.${name}` : name}
                  schema={schema}
                  onChange={onChange}
                  values={values}
                  errors={errors}
                />
              </Grid>
              )) }
            </Grid>
          </Box>
        </Collapse>
      </React.Fragment>
    );
  }
}