import React, {
  RefObject
} from 'react';

import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  InputBase,
  IconButton
} from '@material-ui/core';

import {
  Search as SearchIcon,
  Close as ResetIcon
} from '@material-ui/icons';

interface Props {
  onUpdate: (text: string) => any;
  hideIcon?: boolean;
}

class State {
  inputText: string = '';
  inputRef: RefObject<any> = React.createRef();
}

export class SearchItem extends React.Component<Props, State> {

  readonly state = new State();

  update = (text: string) => {
    this.setState({ inputText: text});
    this.props.onUpdate(text);
  }

  render() {
    const { hideIcon } = this.props;
    const { inputText, inputRef } = this.state;

    return (
      <ListItem>
        { !hideIcon &&
          <ListItemIcon color="textSecondary" onClick={() => inputRef.current.focus() }>
            <SearchIcon />
          </ListItemIcon>
        }
        <InputBase style={{fontSize: 14, flex: 1}}
          placeholder="Quick search…"
          value={inputText}
          inputRef={inputRef}
          onChange={(evt) => this.update(evt.target.value)}
        />
        <ListItemSecondaryAction>
          { inputText.length > 0 && (
            <IconButton size="small" onClick={() => this.update('')} >
              <ResetIcon fontSize="small" />
            </IconButton>
          ) }
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}