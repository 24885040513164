import React from 'react';

import {
  Slider,
  Typography,
  Box,
  Chip
} from '@material-ui/core';

import {
  PropertyList,
  PropertyListItem
} from '../components';

import {
  ParameterSchema,
  ParameterValue
} from '../api';

import {
  titleCase
} from '../utils';

interface Props {
  values: Record<string, ParameterValue>;
  schemas: Record<string, ParameterSchema>;
  style?: React.CSSProperties;
}

export class ParameterList extends React.Component<Props> {

  render() {
    const { values, schemas, style } = this.props;

    return (
      <PropertyList style={style}>
        { Object.entries(schemas).map(([id, schema]) => (
            <PropertyListItem
              key={id}
              name={titleCase(id)}
              description={schema.description}
            >
                { schema.type === 'enum' ? (
                  <Box style={{width: 250, textAlign: 'right', padding: '8px 0'}}>
                    { values[id] === undefined &&
                      <Chip
                        style={{marginLeft: 8, marginTop: 8, width: 40 }}
                        size="small"
                        label="-"
                      />
                    }
                    { schema.values.map(option => (
                      <Chip key={option}
                        variant={values[id] === option ? 'default' : 'outlined'}
                        style={{marginLeft: 8, marginTop: 8, }}
                        size="small"
                        label={
                          <Typography variant="body2">{option}</Typography>
                        }
                      />
                    ))}
                  </Box>
                ) : schema.type === 'number' ? (
                  <React.Fragment>
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <Typography variant="body2">{values[id] !== undefined ? values[id] : '-'}</Typography>
                      <Slider
                        disabled
                        style={{marginBottom: 10, marginTop: -10, marginRight: 10, width: 150}}
                        defaultValue={values[id] !== undefined ? Number(values[id]) : undefined}
                        min={schema.minimum}
                        max={schema.maximum}
                        step={schema.multipleOf}
                        valueLabelDisplay="off"
                        marks={[
                          { value: schema.maximum!, label: schema.maximum! },
                          { value: schema.minimum!, label: schema.minimum! }

                        ]}
                      />
                    </Box>
                  </React.Fragment>
                ) : ( values[id] !== undefined ? values[id] : '-' )
              }
            </PropertyListItem>
          )) }
      </PropertyList>
    );
  }
}