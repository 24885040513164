import React from 'react';

import {
  Formik,
  FormikProps,
  FormikErrors,
  Form
} from 'formik';

import {
  LabelConfiguration
} from '../api';

import {
  LabelDefinitionsTable
} from '../components';

import {
  lookupLabelDefinitionColor
} from '../utils';

interface Props {
  labelConfiguration: LabelConfiguration;
  innerRef: any;
  onSubmit: (labelConfiguration: LabelConfiguration) => void;
}

export class LabelConfigurationForm extends React.Component<Props> {

  render() {
    const { labelConfiguration, innerRef, onSubmit } = this.props;

    return (
      <Formik
        innerRef={innerRef}
        initialValues={labelConfiguration}
        validate={ (values: LabelConfiguration) => {
          const errors: FormikErrors<LabelConfiguration> = {};
          if (!values.labelDefinitions || values.labelDefinitions.length === 0)
            errors.labelDefinitions = 'Missing Labels';
          else {
            values.labelDefinitions.forEach((label, index) => {
              const [ , alias ] = lookupLabelDefinitionColor(label, index);

              if (!label.name)
                errors.labelDefinitions = 'Missing Label Name(s)';
              if (isNaN(label.id))
                errors.labelDefinitions = 'Missing Label ID(s)';
              if (values.labelDefinitions.filter(l => l.id === label.id).length > 1)
                errors.labelDefinitions = 'Label IDs are not unique';
              if (values.labelDefinitions.filter(l => l.name === label.name).length > 1)
                errors.labelDefinitions = 'Label Names are not unique';
              if (values.labelDefinitions.filter((l, li) => lookupLabelDefinitionColor(l, li)[1] === alias).length > 1)
                errors.labelDefinitions = 'Label Colors are not unique';
            })
          }
          return errors;
        }}
        onSubmit={onSubmit}
      >
      { (props: FormikProps<LabelConfiguration>)  => {
        const { touched, errors, setFieldValue, values } = props;

        return (
          <Form autoComplete="off" noValidate style={{display: 'flex', flexDirection: 'column', flex: 1}}>
            <LabelDefinitionsTable
              originalLabels={values.labelDefinitions}
              error={!!touched.labelDefinitions && !!errors.labelDefinitions}
              onChange={(evt, labelDefinitions) => {
                setFieldValue('labelDefinitions', labelDefinitions, false)
              }}
            />
          </Form>
        );
      } }
      </Formik>
    );
  }
}