import React from 'react';

import {
  withTheme,
  Theme
} from '@material-ui/core/styles';

import {
  Avatar,
  Badge
} from '@material-ui/core';

import {
  LabelType
} from '../api';

export type LabelTypeAvatarProps = {
  labelType: LabelType;
  children?: React.ReactNode;
  selected?: boolean;
  theme: Theme;
}

export const LabelTypeAvatar = withTheme(({
  labelType,
  children,
  selected,
  theme
}: LabelTypeAvatarProps) => {

  const badgeStyles = {
    fontSize: 13,
    width: 17,
    height: 17,
    border: '1px solid #FFF',
    backgroundColor: selected ? theme.palette.primary.main : theme.palette.text.secondary,
    marginRight: -4
  }

  const labelTypeChar = labelType === LabelType.Segmentation ? 'S'
      : labelType === LabelType.BoundingBoxes ? 'B' : 'C';

  const avatar = (
    <Avatar
      style={badgeStyles}
      children={labelTypeChar}
    />
  );

  if (!children)
    return avatar;

  return (
    <Badge
      overlap="circle"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeContent={avatar}
    >
      { children }
    </Badge>
  );
})