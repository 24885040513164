import React from 'react';

import {
  Menu,
  MenuItem,
  Typography,
  IconButton,
  Divider
} from '@material-ui/core';

import {
  MoreVert as MenuIcon,
} from '@material-ui/icons';

import {
  AspectForm,
  AspectDevicesForm,
  AspectDialog,
  ContentDialog,
  AspectDataManager,
  ResourceEditDialog,
  ResourceDeleteDialog
} from '../components';

import API, { Aspect,
  AspectInput,
} from '../api';

interface Props {
  aspect: Aspect;
  onUpdate: (aspect: Aspect) => void;
  onDelete: (aspect: Aspect) => void;
}

class State {
  anchor: HTMLButtonElement | null = null;
}

export class AspectMenu extends React.Component<Props, State> {

  readonly state = new State();

  show = (evt: any) => {
    this.setState({ anchor: evt.currentTarget });
  }

  hide = () => {
    this.setState({ anchor: null });
  }

  render() {
    const { anchor } = this.state;
    const { aspect } = this.props;

    return (
      <React.Fragment>
        <IconButton size="small" color="inherit" disableRipple disableFocusRipple onClick={this.show}>
          <MenuIcon fontSize="small" />
        </IconButton>
        <Menu
          keepMounted
          anchorEl={anchor}
          open={Boolean(anchor)}
          onClose={this.hide}
        >
          <AspectDialog
            aspect={aspect}
            trigger={<MenuItem onClick={this.hide}>Details</MenuItem>}
          />
          <Divider />
          <ResourceEditDialog<AspectInput, Aspect>
            title="Edit Station"
            resource={{...aspect}}
            trigger={<MenuItem onClick={this.hide}>Edit</MenuItem>}
            action={body => API.aspects.updateAspect(aspect.id, body) }
            onSuccess={this.props.onUpdate}
            renderForm={(aspectInput, ref, handleSubmit) => (
              <AspectForm
                aspectInput={aspectInput}
                innerRef={ref}
                onSubmit={handleSubmit} />
            )}
          />
          <ResourceDeleteDialog<Aspect>
            title="Delete Station ?"
            resource={aspect}
            trigger={<MenuItem onClick={this.hide}>Delete</MenuItem>}
            action={aspect => API.aspects.deleteAspect(aspect.id) }
            onSuccess={this.props.onDelete}
          >
            <Typography variant="subtitle1">
              All associated data will be permanently lost.
            </Typography>
          </ResourceDeleteDialog>
          <Divider />
          <ContentDialog
            size="lg"
            trigger={<MenuItem onClick={this.hide}>Manage Data</MenuItem>}
            renderContent={(close) => (
              <AspectDataManager
                aspectId={aspect.id}
                onClose={close}
              />
            )}
          />
          <ResourceEditDialog<AspectInput, Aspect>
            title="Manage Devices"
            maxWidth="sm"
            fullHeight
            resource={{...aspect}}
            trigger={<MenuItem onClick={this.hide}>Manage Devices</MenuItem>}
            action={body => API.aspects.updateAspect(aspect.id, body) }
            onSuccess={this.props.onUpdate}
            renderForm={(aspectInput, ref, handleSubmit) => (
              <AspectDevicesForm
                aspectInput={aspectInput}
                innerRef={ref}
                onSubmit={handleSubmit} />
            )}
          />
        </Menu>
      </React.Fragment>
    );
  }
}