import React from 'react';

import {
  Divider,
  Toolbar,
  Typography,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';

import {
  Add as AddIcon,
  Close as CloseIcon
} from '@material-ui/icons';

import {
  Skeleton
} from '@material-ui/lab';

import API, {
  DataCollection,
  DataType
} from '../api';

import {
  ContentDialog,
  DataCollectionEditor,
  DataCollectionIcon,
  DataCollectionViewer,
  DebugDialog,
  ErrorBox,
  SearchItem
} from '../components';

import {
  searchCase
} from '../utils';

interface Props {
  dataSourceReferenceId?: string;
  onClose?: () => void;
}

class State {
  loading: boolean = true;
  selectionId?: string;
  error?: Error;
  dataCollections?: DataCollection[];
  searchText: string = '';
}

export class DataCollectionManager extends React.Component<Props, State> {

  readonly state = new State();

  componentDidMount() {
    const { dataSourceReferenceId } = this.props;
    this.setState({ loading: true, dataCollections: undefined, error: undefined, selectionId: undefined });
    API.dataCollections.listDataCollections({ dataType: DataType.Images, dataSourceReferenceId })
    .then(dataCollections => this.setState({ loading: false, dataCollections }))
    .catch(error => this.setState({ loading: false, error }))
  }

  render() {
    const { dataSourceReferenceId, onClose } = this.props;
    const { dataCollections, selectionId, loading, error, searchText } = this.state;

    return (
      <Box display="flex" flex={1} flexDirection="column">
        <Toolbar>
          <DataCollectionIcon />
          <Box mx={1} />
          <Typography variant="h6" noWrap>Data Collections</Typography>
          <Box mx={2} />
          <DebugDialog object={this.state} />
          { dataCollections && dataSourceReferenceId &&
            <ContentDialog
              fullscreen
              trigger={<IconButton><AddIcon /></IconButton>}
              renderContent={close => (
                <DataCollectionEditor
                  dataSourceReferenceId={dataSourceReferenceId}
                  onCreate={dataCollection => {
                    this.setState({
                      selectionId: dataCollection.id,
                      dataCollections: [...dataCollections, dataCollection]
                    })
                  }}
                  onUpdate={dataCollection => {
                    this.setState({
                      dataCollections: dataCollections.map(d => d.id === dataCollection.id ? dataCollection : d)
                    });
                  }}
                  onClose={close}
                />
              )}
            />
          }
          <Box mx="auto" />
          { onClose && <IconButton onClick={onClose}><CloseIcon/></IconButton>}
        </Toolbar>
        <Divider />
        <Box display="flex" flex="1" flexDirection="row">
          <Box display="flex" flex={1} style={{maxWidth: 250}}>
            { loading && (
              <List style={{flex: 1}}>
                { Array(5).fill(0).map((count, itemIndex) => (
                 <ListItem key={itemIndex}>
                    <Skeleton height={15} width="80%" />
                 </ListItem>
                )) }
              </List>
            )}
            { error && <ErrorBox message={error?.message} onReload={() => this.componentDidMount() } /> }
            { dataCollections &&
            <Box style={{ flex: '1 1 1px', overflowY: 'scroll'}}>
              <List dense disablePadding>
                <SearchItem hideIcon onUpdate={(searchText) => this.setState({ searchText })} />
              </List>
              <List dense disablePadding>
                { dataCollections
                    .filter(d => !searchText || searchCase(d.name).indexOf(searchCase(searchText)) !== -1)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(dataCollection => (
                  <ListItem key={dataCollection.id}
                    button
                    selected={dataCollection.id === selectionId}
                    onClick={() => this.setState({ selectionId: dataCollection.id })}
                  >
                    <ListItemText primary={ <Typography variant="body2">{dataCollection.name} </Typography>} />
                  </ListItem>
                )) }
              </List>
            </Box>
            }
          </Box>
          <Divider orientation="vertical" />
          <Box flex={1} display="flex">
            { !selectionId && <Box padding={2}><Typography variant="body2" color="textSecondary">Select a Data Collection.</Typography></Box> }
            { dataCollections && selectionId &&
              <DataCollectionViewer
                key={selectionId}
                dataCollectionId={selectionId}
                onUpdate={dataCollection => {
                  this.setState({
                    dataCollections: dataCollections.map(c => c.id === dataCollection.id ? dataCollection : c)
                  })
                }}
                onDelete={dataCollection => {
                  this.setState({
                    dataCollections: dataCollections.filter(c => c.id !== dataCollection.id),
                    selectionId: undefined
                  })
                }}
              />
            }
          </Box>
        </Box>
      </Box>
    )
  }
}