import React from 'react';

import {
  Dialog
} from '@material-ui/core';

interface Props {
  size?: 'sm' | 'md' | 'lg' | 'xl'
  fullscreen?: boolean;
  trigger?: React.ReactElement<any>;
  renderContent: (close: () => void) => React.ReactNode;
}

class State {
  open: boolean;

  constructor (props: Props) {
    this.open = !props.trigger;
  }
}

export class ContentDialog extends React.Component<Props, State> {

  readonly state = new State(this.props);

  handleClose = () => {
    this.setState({ open: false });
  }

  render() {
    const { trigger, renderContent, size, fullscreen } = this.props;
    const { open } = this.state;

    const triggerElement = trigger && React.cloneElement(trigger, {
      onClick: () => {
        if (trigger.props.onClick) {
          trigger.props.onClick();
        }
        this.setState({ open: true });
      }
    });

    const maxWidth = size || (fullscreen ? false : 'lg');

    return (
      <React.Fragment>
        {triggerElement}
        <Dialog
          open={open}
          maxWidth={maxWidth}
          fullScreen={fullscreen}
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          style={{maxWidth: 'none'}}
          onClose={this.handleClose.bind(this)}
          PaperProps={{style: {height: '100vh', background: '#f8f8f8' }}}
        >
          { renderContent(this.handleClose.bind(this)) }
        </Dialog>
      </React.Fragment>
    )
  }
}