import React from 'react';

import {
  isEmpty
} from 'lodash';

import {
  withRouter,
  RouteComponentProps
} from 'react-router-dom';

import {
  Grid,
  Typography,
  Toolbar,
  Box,
  Tabs,
  Tab,
  Divider,
  Button,
  IconButton,
  CircularProgress,
  Tooltip
} from '@material-ui/core';

import {
  Edit as EditIcon,
  FileCopy as MakeCopyIcon,
  DeleteForever as DeleteIcon,
  PlaylistPlay as StartTrainingIcon,
  PlaylistPlay as StartPredictionIcon,
  Stop as StopPredictionIcon,
  Assessment as StageIcon,
  DonutLarge as StatusIcon,
  Schedule as LastUpdateIcon,
  LocalOfferOutlined as TagsIcon,
  Launch as OpenIcon
} from '@material-ui/icons';

import {
  Skeleton
} from '@material-ui/lab';

import {
  MetricsChart,
  TemplateInstanceCreator,
  PropertyListSkeleton,
  DebugDialog,
  ContentDialog,
  ProgressBar,
  DatasetViewer,
  LabelsetViewer,
  DevicesList,
  ErrorPage,
  TemplateInstanceBasicForm,
  TemplateInstancePredictionDialog,
  PropertyChip,
  PropertyTable,
  ResourceEditDialog,
  ResourceDeleteDialog
} from '../components';
import {
  PropertyList,
  PropertyListItem
} from '../components';

import API, {
  deepEqual,
  DeploymentStage,
  DeploymentStatus,
  Metric,
  TemplateInstance,
  TemplateInstanceInput,
  Template,
  Aspect
} from '../api';

import {
  titleCase,
  getTemplateIcon,
  formatLongDate,
  convertMetricsToChartsData,
  getTemplateInstanceColor,
  isTemplateInstanceInDetectionPhase,
  isTemplateInstanceEditable,
  hasTemplateInstanceFailed,
  canTemplateInstanceCompleteTraining,
  canTemplateInstanceStopTraining,
  canTemplateInstanceStartPrediction,
  canTemplateInstanceStopPrediction,
  canTemplateInstanceRestartTraining
} from '../utils';

interface Params {
  assetId: string,
  aspectId: string,
  templateInstanceId: string
}

interface Props extends RouteComponentProps<Params> {
  templateInstancesTags: string[];
  templates: Template[];
  aspect: Aspect;
  onCopy: (templateInstance: TemplateInstance) => void;
  onUpdate: (templateInstance: TemplateInstance) => void;
  onDelete: (templateInstance: TemplateInstance) => void;
}

class State {
  loading: boolean = true;
  templateInstance?: TemplateInstance;
  template?: Template;
  trainingMetrics?: Array<Metric>;
  predictionMetrics?: Array<Metric>;
  error?: Error;
  tabIndex: number = 0;
  isPredictionStopping: boolean = false;
  isTrainingStopping: boolean = false;
}

export const TemplateInstancePage = withRouter(class extends React.Component<Props, State> {

  readonly state = new State();

  private interval?: NodeJS.Timeout;

  handleStopPrediction = (templateInstance: TemplateInstance) => {
    if (!templateInstance.latestDeployment)
      return;

    this.setState({ isPredictionStopping: true });
    API.templateInstances.stopTemplateInstanceDeployment(templateInstance.id, templateInstance.latestDeployment.id)
    .then(deployment => API.templateInstances.getTemplateInstance(templateInstance.id))
    .then(templateInstance => {
      this.setState({ isPredictionStopping: false, templateInstance });
      this.props.onUpdate(templateInstance);
    })
    .catch(error => {
      this.setState({ isPredictionStopping: false, error })
    })
  }

  handleStopTraining = (templateInstance: TemplateInstance) => {
    if (!templateInstance.latestDeployment)
      return

    this.setState({ isTrainingStopping: true });
    API.templateInstances.stopTemplateInstanceDeployment(templateInstance.id, templateInstance.latestDeployment.id)
    .then(deployment => API.templateInstances.getTemplateInstance(templateInstance.id))
    .then(templateInstance => {
      this.setState({ isTrainingStopping: false, templateInstance });
      this.props.onUpdate(templateInstance);
    })
    .catch(error => {
      this.setState({ isTrainingStopping: false, error })
    })
  }

  reload = (previousTemplateInstance?: TemplateInstance) => {
    const { params } = this.props.match;
    API.templateInstances.getTemplateInstance(params.templateInstanceId)
    .then(templateInstance => {
      const changed = !previousTemplateInstance || !deepEqual(templateInstance.latestDeployment, previousTemplateInstance.latestDeployment);
      // do nothing if there was a previous instance loaded and the refreshed latest deployment object has not changed
      if (!changed)
        return;

      // otherwise fully load all dependent objects
      this.setState(new State());
      return API.templateInstances.getTemplateInstanceDeployments(templateInstance.id)
      .then(deployments => {
        const trainingDeployment = deployments.find(d => d.stage === DeploymentStage.Training);
        const predictionDeployment = deployments.find(d => d.stage === DeploymentStage.Prediction && d.id === templateInstance.latestDeployment?.id)
        return Promise.all([
          trainingDeployment ? API.deployments.getDeploymentMetrics(trainingDeployment.id) : undefined,
          predictionDeployment ? API.deployments.getDeploymentMetrics(predictionDeployment.id) : undefined,
        ])
        .then(([trainingMetrics, predictionMetrics]) => {
          return API.templates.getTemplateVersion(templateInstance.templateVersionId)
          .then(template => {
            this.setState({ loading: false, template, templateInstance, trainingMetrics, predictionMetrics, tabIndex: isTemplateInstanceInDetectionPhase(templateInstance) ? 1 : 0 })
            if (changed)
              this.props.onUpdate(templateInstance);
          })
        })
      })
    })
    .catch(error => this.setState({ loading: false, error }) )
  }

  componentDidMount() {
    this.reload();
    this.interval = setInterval(() => {
      this.reload(this.state.templateInstance)
    }, 30*1000)
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const { templateInstancesTags, aspect } = this.props;
    const { loading,  error, templateInstance, template, trainingMetrics, predictionMetrics, tabIndex, isPredictionStopping, isTrainingStopping } = this.state;

    const trainingChartsData = convertMetricsToChartsData(trainingMetrics || []);
    const predictionChartsData = convertMetricsToChartsData(predictionMetrics || []);

    if (loading) {
      return (
        <React.Fragment>
          <Toolbar disableGutters>
            <Skeleton width={200} height={20} />
          </Toolbar>
          <Box paddingLeft={2} paddingBottom={3}>
            <Skeleton width={400} height={15} />
          </Box>
          <Toolbar disableGutters variant="dense">
            <Skeleton width={100} height={15} />
            <Box padding={1} />
            <Skeleton width={100} height={15} />
          </Toolbar>
          <Divider />
          <Box padding={2}>
            <Skeleton width={100} height={20} />
            <Box my={2} />
            <PropertyListSkeleton countItems={4} style={{width: 300}} />
          </Box>
          <Box padding={2}>
            <Skeleton width={100} height={20} />
            <Box my={2} />
            <PropertyListSkeleton countItems={4} style={{width: 300}} />
          </Box>
        </React.Fragment>
      );
    } else if (error || !templateInstance || !template) {
      return <ErrorPage message={error?.message} onReload={() => this.reload()} />
    } else {

      const stage = templateInstance.latestDeployment?.stage;
      const status = templateInstance.latestDeployment?.status;

      const statusFailure = hasTemplateInstanceFailed(templateInstance);
      const statusDetection = isTemplateInstanceInDetectionPhase(templateInstance);
      const isEditable = isTemplateInstanceEditable(templateInstance);
      const stageColor = getTemplateInstanceColor(templateInstance.latestDeployment?.stage);
      const templateIcon = getTemplateIcon(template.templateType);

      const newTemplateInstance = {
        ...templateInstance,
        name: `Copy of ${templateInstance.name}`
      };

      return (
        <React.Fragment>
          <Toolbar disableGutters>
            <Box mx={1} />
            <Typography variant="h5" noWrap style={{maxWidth: '50%'}}>
              { templateInstance.name }
            </Typography>
            <Box mx="auto" />
            {
              isEditable &&
              <ResourceEditDialog<TemplateInstanceInput, TemplateInstance>
                title="Basic Settings"
                resource={templateInstance}
                trigger={<Tooltip title="Basic Settings"><IconButton><EditIcon /></IconButton></Tooltip>}
                action={resource => API.templateInstances.updateTemplateInstance(templateInstance.id, resource) }
                onSuccess={(templateInstance) => {
                  //this.setState({ templateInstance });
                  this.props.onUpdate(templateInstance);
                  this.reload();
                }}
                renderForm={(resource, ref, handleSubmit) => (
                  <TemplateInstanceBasicForm
                    templateInstanceId={templateInstance.id}
                    templateInstanceInput={resource}
                    templateInstancesTags={templateInstancesTags}
                    innerRef={ref}
                    onSubmit={handleSubmit}
                  />
                )}
              />
            }
            {
              isEditable &&
              <ResourceEditDialog<TemplateInstanceInput, TemplateInstance>
                title="Make a Copy"
                resource={{...newTemplateInstance}}
                trigger={<Tooltip title="Make a Copy"><IconButton><MakeCopyIcon /></IconButton></Tooltip>}
                action={resource => API.templateInstances.createTemplateInstance(resource) }
                onSuccess={this.props.onCopy}
                renderForm={(resource, ref, handleSubmit) => (
                  <TemplateInstanceBasicForm
                    templateInstanceInput={resource}
                    templateInstancesTags={templateInstancesTags}
                    innerRef={ref}
                    onSubmit={handleSubmit}
                  />
                )}
              />
            }
            {
              isEditable &&
              <ResourceDeleteDialog<TemplateInstance>
                title="Delete Template Instance ?"
                resource={templateInstance}
                trigger={<Tooltip title="Delete"><IconButton><DeleteIcon /></IconButton></Tooltip>}
                action={templateInstance => API.templateInstances.deleteTemplateInstance(templateInstance.id) }
                onSuccess={this.props.onDelete}
              />
            }
            <DebugDialog object={this.state} />
          </Toolbar>
          <Box paddingLeft={2} paddingBottom={2}>
            { stage && <PropertyChip color={stageColor} title={titleCase(stage)} icon={<StageIcon />} tooltip="Stage" /> }
            { status && <PropertyChip dot={statusFailure} title={titleCase(status)} icon={<StatusIcon />} tooltip="Status" /> }
            { stage === DeploymentStage.Training && (status === DeploymentStatus.Preparing || status === DeploymentStatus.Running) &&
              <PropertyChip
                icon={<ProgressBar value={undefined} style={{width: 40, marginLeft: 5}} />}
                title=""
                tooltip="Progress"
              /> /* FIXME */
            }
            <PropertyChip title={formatLongDate(templateInstance.updatedAt)} icon={<LastUpdateIcon />} tooltip="Last Updated" />
            <PropertyChip title={template.name || template.templateType || 'No template yet'} icon={templateIcon} tooltip="Template Type" />
              { templateInstance.additionalAttributes?.tags && templateInstance.additionalAttributes?.tags.length > 0 &&
              <React.Fragment>
                <br />
                <PropertyChip title={templateInstance.additionalAttributes?.tags?.join(', ') || 'No tags yet'} icon={<TagsIcon />} tooltip="Tags" />
              </React.Fragment>
            }
          </Box>
          <Toolbar disableGutters variant="dense">
            <Tabs
              indicatorColor="secondary"
              variant="standard"
              value={tabIndex}
              onChange={(evt, val) => this.setState({tabIndex: val})}
            >
              <Tab label="Training" style={{minWidth: 120}} />
              <Tab label="Prediction" disabled={!statusDetection} style={{minWidth: 120}} />
            </Tabs>
            <Box m="auto" />
            {
              canTemplateInstanceCompleteTraining(templateInstance) &&
                <ContentDialog
                  size="lg"
                  trigger={
                    <Button variant="outlined" color="primary" startIcon={<StartTrainingIcon/>}>Complete Training</Button>
                  }
                  renderContent={(close) => (
                    <TemplateInstanceCreator
                      templateInstance={templateInstance}
                      aspectId={aspect.id}
                      onSuccess={templateInstance => {
                        this.setState({ templateInstance });
                        this.props.onUpdate(templateInstance);
                        close();
                      }}
                      onClose={close}
                    />
                  )}
                />
            }
            { canTemplateInstanceStopTraining(templateInstance) &&
              (
                isTrainingStopping
                ? (
                  <Button
                    disabled
                    startIcon={<CircularProgress color="inherit" size={20} />}
                  >
                    Aborting
                  </Button>

                ) : (
                  <Tooltip title="Abort Training">
                    <Button
                      startIcon={<StopPredictionIcon />}
                      onClick={() => this.handleStopTraining(templateInstance)}
                    >
                      Abort
                    </Button>
                  </Tooltip>
                )
              )
            }
            { canTemplateInstanceRestartTraining(templateInstance) &&
              null
            }
            {
              canTemplateInstanceStartPrediction(templateInstance) &&
                <TemplateInstancePredictionDialog
                  title="Configure Prediction"
                  templateInstance={templateInstance}
                  aspect={aspect}
                  trigger={
                    <Button color="primary"
                      variant="outlined"
                      startIcon={<StartPredictionIcon />}>Start Prediction</Button>
                  }
                  onSuccess={templateInstance => {
                    this.setState({ templateInstance });
                    this.props.onUpdate(templateInstance);
                  }}
                />
            }
            {
              canTemplateInstanceStopPrediction(templateInstance) &&
                <Button
                  disabled={isPredictionStopping}
                  startIcon={isPredictionStopping ? <CircularProgress color="inherit" size={20} /> : <StopPredictionIcon />}
                  onClick={() => this.handleStopPrediction(templateInstance)}
                >
                  Stop
                </Button>
            }
          </Toolbar>
          <Divider />
          <Box display="flex" flexDirection="column" flex={1} style={{overflowY: 'scroll'}}>
          { tabIndex === 0 &&
            <Box m={2}>
              { trainingMetrics && trainingMetrics.length > 0 &&
                <React.Fragment>
                  <Toolbar variant="dense" disableGutters>
                    <Typography variant="h6">Metrics</Typography>
                    <Box mx="auto" />
                    <DebugDialog object={trainingMetrics} />
                  </Toolbar>
                  <Grid container spacing={1}>
                    { Object.entries(trainingChartsData).map(([label, data], index) => (
                      <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
                        <MetricsChart label={label} data={data} index={index} />
                      </Grid>
                    )) }
                  </Grid>
                  <Box my={2} />
                </React.Fragment>
              }
              { status === DeploymentStatus.Finished &&
                <React.Fragment>
                  <Toolbar variant="dense" disableGutters>
                    <Typography variant="h6">Artifact</Typography>
                    <Box mx="auto" />
                  </Toolbar>
                  <Box>
                  { templateInstance.artifact
                    ? (
                      <PropertyList>
                        <PropertyListItem name="Path" description="The training artifact S3 URL">
                          <Tooltip title={templateInstance.artifact.path} style={{maxWidth: 'none'}}>
                            <Typography noWrap style={{maxWidth: 250}} variant="body2">{templateInstance.artifact.path}</Typography>
                          </Tooltip>
                        </PropertyListItem>
                      </PropertyList>
                  )
                    : <Typography variant="body2" color="textSecondary">No artifact output which is required for prediction.</Typography>
                  }
                  </Box>
                </React.Fragment>
              }
              <React.Fragment>
                <Toolbar variant="dense" disableGutters>
                  <Typography variant="h6">Data Inputs</Typography>
                  <Box mx="auto" />
                  <DebugDialog object={templateInstance.dataInputs} />
                </Toolbar>
                { templateInstance.dataInputs.map((dataInput, index) => (
                  <Toolbar variant="dense" key={index}>
                    { dataInput.datasetVersionId && dataInput.labelsetId &&
                      <ContentDialog
                        trigger={<IconButton size="small"><OpenIcon fontSize="small" /></IconButton>}
                        renderContent={(close) => (
                          <LabelsetViewer
                            datasetVersionId={dataInput.datasetVersionId}
                            labelsetId={dataInput.labelsetId!}
                            onClose={close}
                          />
                        )}
                      />
                    }
                    { dataInput.datasetVersionId && !dataInput.labelsetId &&
                      <ContentDialog
                        trigger={<IconButton size="small"><OpenIcon fontSize="small" /></IconButton>}
                        renderContent={(close) => (
                          <DatasetViewer
                            datasetVersionId={dataInput.datasetVersionId}
                            onClose={close}
                          />
                        )}
                      />
                    }
                    <Box mx={1} />
                    <Typography variant="subtitle1">{titleCase(dataInput.dataInputType)}</Typography>
                    <Box mx="auto" />
                  </Toolbar>
                ))}
              </React.Fragment>
              <Toolbar variant="dense" disableGutters>
                <Typography variant="h6">Settings</Typography>
              </Toolbar>
              <PropertyTable width={300} properties={templateInstance.settings.training} />
            </Box>
          }
          { tabIndex === 1 &&
            <Box m={2}>
              { predictionMetrics && predictionMetrics.length > 0 &&
                <React.Fragment>
                  <Toolbar variant="dense" disableGutters>
                    <Typography variant="h6">Metrics</Typography>
                    <Box mx="auto" />
                    <DebugDialog object={predictionMetrics} />
                  </Toolbar>
                  <Grid container spacing={1}>
                    { Object.entries(predictionChartsData).map(([label, data], index) => (
                      <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
                        <MetricsChart label={label} data={data} index={index} />
                      </Grid>
                    )) }
                  </Grid>
                  <Box my={2} />
                </React.Fragment>
              }
              { !!templateInstance.latestDeployment?.inputs?.length &&
                <Box marginBottom={1}>
                  <Toolbar variant="dense" disableGutters>
                    <Typography variant="h6">Sources</Typography>
                  </Toolbar>
                  <DevicesList
                    devices={aspect.attributes.devices}
                    filter={templateInstance.latestDeployment}
                  />
                </Box>
              }
              <Box marginBottom={1}>
                <Toolbar variant="dense" disableGutters>
                  <Typography variant="h6">Settings</Typography>
                </Toolbar>
                <Box mx={2}>
                  <PropertyTable width={300} properties={templateInstance.settings.prediction} />
                  { isEmpty(templateInstance.settings.prediction) &&
                    <Typography variant="body2" color="textSecondary">No settings available.</Typography>
                  }
                </Box>
              </Box>
            </Box>
          }
          </Box>
        </React.Fragment>
      );
    }
  }
});