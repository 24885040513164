import React from 'react';

import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  TextField,
  IconButton/*,
  InputAdornment,
  Tooltip*/
} from '@material-ui/core';

import {
  Airplay as DeviceIcon,
  CameraAltOutlined as SourceIcon,
  Add as AddIcon,
  Delete as DeleteIcon
} from '@material-ui/icons';

import {
  Device,
  Source,
  deepClone
} from '../api';

import {
  extendArrayItem
} from '../utils';

interface Props {
  value: Device[];
  error: boolean;
  onChange: (values: Device[]) => void;
  style?: React.CSSProperties;
}

class State {
  devices: Device[];

  constructor(props: Props) {
    this.devices = deepClone(props.value);
  }
}

export class DevicesListEditable extends React.Component<Props, State> {

  readonly state = new State(this.props);

  handleChange(devices: Device[]) {
    this.setState({ devices });
    this.props.onChange(devices);
  }

  updateDevice = (device: Device, extension: Partial<Device>) => {
    const devices = extendArrayItem(this.state.devices, { id: device.id }, extension);
    this.handleChange(devices);
  }

  updateSource = (device: Device, source: Source, extension: Partial<Source>) => {
    const devices = extendArrayItem(this.state.devices, { id: device.id }, {
      sources: extendArrayItem(device.sources, { id: source.id }, extension)
    });
    this.handleChange(devices);
  }

  render() {
    const { error, style } = this.props;
    const { devices } = this.state;

    return (
      <List dense style={style}>
        { devices.map((device, deviceIndex) => (
          <React.Fragment key={deviceIndex}>
            <Divider />
            <ListItem dense>
              <ListItemIcon style={{minWidth: 35}}>
                <DeviceIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <TextField
                    required
                    name="name"
                    label="Device Name"
                    variant="outlined"
                    margin="none"
                    size="small"
                    fullWidth
                    style={{marginBottom: 5, marginTop: 5}}
                    value={device.name}
                    onChange={evt => {
                      this.updateDevice(device, { name: evt.currentTarget.value });
                    }}
                    error={error && !device.name.length}
                    InputLabelProps={{shrink: true}}
                  />
                }
                secondary={
                  <TextField
                    required
                    name="id"
                    label="Device Runtime ID"
                    variant="outlined"
                    size="small"
                    fullWidth
                    style={{marginBottom: 5, marginTop: 5}}
                    value={device.id}
                    onChange={evt => {
                      this.updateDevice(device, { id: evt.currentTarget.value });
                    }}
                    error={error && !device.id.length}
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                      /*endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Generate new UUID">
                            <IconButton size="small"
                              onClick={evt => {
                                this.updateDevice(device, { id: uuid() });
                              }}
                            >
                              <RefreshIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      )*/
                    }}
                  />
                }
              />
              <ListItemSecondaryAction>
                <IconButton size="small"
                  onClick={evt => {
                    const newDevices = devices.filter(d => d.id !== device.id);
                    this.handleChange(newDevices);
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            { device.sources.map((source, sourceIndex) => (
              <React.Fragment key={sourceIndex}>
                <Divider style={{ marginLeft: 40 }} />
                <ListItem
                  dense
                  style={{ paddingLeft: 50 }}
                >
                  <ListItemIcon style={{minWidth: 35}}>
                    <SourceIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <TextField
                        required
                        name="name"
                        label="Source Name"
                        variant="outlined"
                        margin="none"
                        size="small"
                        fullWidth
                        style={{marginBottom: 5, marginTop: 5}}
                        value={source.name}
                        onChange={evt => {
                          this.updateSource(device, source, { name: evt.currentTarget.value });
                        }}
                        error={error && !source.name.length}
                        InputLabelProps={{shrink: true}}
                      />
                    }
                    secondary={
                      <>
                        <TextField
                          required
                          name="topic"
                          label="Source Topic"
                          variant="outlined"
                          margin="none"
                          size="small"
                          fullWidth
                          style={{marginBottom: 5, marginTop: 5}}
                          value={source.topic}
                          onChange={evt => {
                            this.updateSource(device, source, { topic: evt.currentTarget.value });
                          }}
                          InputLabelProps={{shrink: true}}
                        />
                        <TextField
                          required
                          name="id"
                          label="Source ID"
                          variant="outlined"
                          size="small"
                          fullWidth
                          style={{marginBottom: 5, marginTop: 5}}
                          value={source.id}
                          onChange={evt => {
                            this.updateSource(device, source, { id: evt.currentTarget.value });
                          }}
                          error={error && !source.id.length}
                          InputLabelProps={{shrink: true}}
                          InputProps={{
                            /*endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Generate new UUID">
                                  <IconButton size="small"
                                    onClick={evt => {
                                      evt.preventDefault();
                                      this.updateSource(device, source, { id: uuid() })
                                    }}
                                  >
                                    <RefreshIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            )*/
                          }}
                        />
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton size="small"
                      onClick={evt => {
                        const newDevices = extendArrayItem(devices, { id: device.id }, {
                          sources: device.sources.filter(s => s.id !== source.id)
                        });
                        this.handleChange(newDevices);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </React.Fragment>
            )) }
            <ListItem dense style={{ marginLeft: 50 }}>
              <ListItemText
                primary={
                  <Button
                    startIcon={<AddIcon fontSize="small" />}
                    color="primary"
                    onClick={() => {
                      const newDevices = extendArrayItem(devices, { id: device.id }, {
                        sources: [...device.sources, { id: 'source-id', name: 'Source Name', topic: '/source-topic' } ]
                      });
                      this.handleChange(newDevices);
                    }}
                    children="Add Source..."
                  />
                }
              />
            </ListItem>
          </React.Fragment>
        )) }
        <Divider />
        <ListItem dense>
          <ListItemText
            primary={
              <Button
                startIcon={<AddIcon fontSize="small" />}
                color="primary"
                onClick={() => {
                  const newDevices = [...devices, { id: 'device-id', name: 'Device Name', sources: [] }];
                  this.handleChange(newDevices);
                }}
                children="Add Device..."
              />
            }
          />
        </ListItem>
      </List>
    );
  }
}