import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig, ResponseType, Method } from "axios";

import { min, mean, max } from 'lodash';

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  method: Method;
  path: string;
  type?: string;
  query?: Record<string | number, any>;
  format?: ResponseType;
  body?: unknown;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data"
}

export default class HTTPClient {
  public instance: AxiosInstance;

  constructor(axiosConfig: AxiosRequestConfig = {}) {
    this.instance = axios.create(axiosConfig);

    this.instance.interceptors.request.use(config => {
      config.headers['request-startTime'] = Date.now();
      return config;
    })

    this.instance.interceptors.response.use(
      response => {
        this.addRequestDuration(response);
        // this.debugResponseURL(response.status, response.config, false);
        return Promise.resolve(response);
      },
      error => {
        if (error.config) {
          this.debugResponseURL(error.response?.status || 500, error.config, true);
        }
        return Promise.reject(error);
      }
    );
  }

  private addRequestDuration (response: AxiosResponse) {
    const start = response.config.headers['request-startTime'];
    if (start > 0) {
      const milliseconds = Date.now() - start;
      response.headers['request-duration'] = milliseconds;
    }
  }

  private debugResponseURL (status: number, config: AxiosRequestConfig, error: boolean) {
    const { method, url: path, data: body } = config;
    const debugURL = `${window.location.origin}/dev/query`
      + `?method=${method}`
      + ( path ? `&path=${encodeURIComponent(path)}` : '' )
      + ( body ? `&body=${encodeURIComponent(body)}` : '' );
    console.debug(`%c[${status}] ${debugURL}`, `background: ${error ? 'pink' : '#35E9E6'}`);
  }

  private mergeRequestParams(params1: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {})
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === "object" && property !== null
          ? JSON.stringify(property)
          : `${property}`,
      );
      return formData;
    }, new FormData());
  }

  public fullRequest = async <T = any>({
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse> => {
    const requestParams = this.mergeRequestParams(params);

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      requestParams.headers.common = { Accept: "*/*" };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      /*const formData = */this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: format,
      data: body,
      url: path,
    })
  }

  public request = async <T = any>(params: FullRequestParams): Promise<T> => {
    return this.fullRequest(params)
      .then(res => {
        return res.data;
      })
  }
}


export function computeStats (responses: Array<PromiseSettledResult<AxiosResponse>>): Record<string, number | undefined> {
  let durations = new Array<number>(),
    successfulRequests = 0,
    failedRequests = 0;
  responses.forEach(response => {
    if (response.status === 'fulfilled') {
      successfulRequests += 1;
      const duration = response.value.headers['request-duration'];
      if (duration > 0)
        durations.push(duration);
    } else {
      failedRequests += 1;
    }
  })
  return {
    successfulRequests,
    failedRequests,
    minDuration: min(durations),
    meanDuration: durations.length ? Math.floor(mean(durations) as number) : undefined,
    maxDuration: max(durations)
  }
}