import React from 'react';

import {
  withRouter,
  RouteComponentProps,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import {
  Box,
  Typography,
  Container,
  Drawer,
  IconButton,
  Toolbar,
  Divider,
  List,
  Tooltip
} from '@material-ui/core';

import {
  Add as AddIcon
} from '@material-ui/icons';

import {
  Skeleton
} from '@material-ui/lab';

import {
  AppLink,
  AppLinkSkeleton,
  AppTopBar,
  AspectMenu,
  ContentDialog,
  DebugDialog,
  FeedbackToast,
  TemplateInstanceList,
  TemplateInstanceListSkeleton,
  ErrorPage,
  TemplateInstancePage,
  SearchItem,
  TemplateInstanceCreator
} from '../components';

import API, {
  Aspect,
  Asset,
  DataType,
  TemplateInstance,
  Template
} from '../api';

import {
  listAllTemplateInstancesTags,
  filterValidTemplates
} from '../utils';

interface Params {
  templateInstanceId: string
}

interface Props extends RouteComponentProps<Params> {
  assetId?: string,
  aspectId: string,
  embedded?: boolean;
}

class State {
  loading: boolean = true;
  error?: Error;
  asset?: Asset;
  aspect?: Aspect;
  templateInstances: TemplateInstance[] = [];
  templates: Template[] = [];
  feedback: string | undefined = undefined;
  searchText: string = '';
}

export const TemplateInstancesPage = withRouter(class extends React.Component<Props, State> {

  readonly state = new State();
  readonly widthDrawer = 320;

  reload = () => {
    this.setState(new State());
    this.componentDidMount();
  }

  componentDidMount() {
    const { assetId, aspectId } = this.props;
    Promise.all([
      assetId ? API.assets.getAsset(assetId) : undefined,
      API.aspects.getAspect(aspectId),
      API.templateInstances.listTemplateInstances({ dataSourceReferenceId: aspectId }),
      API.templates.listTemplates({ dataType: DataType.Images })
    ])
    .then(([asset, aspect, templateInstances, allTemplates]) => {
      const templates = filterValidTemplates(allTemplates);
      this.setState({ loading: false, asset, aspect, templateInstances, templates });
    })
    .catch(error => {
      this.setState({ loading: false, error });
    })
  }

  render() {
    const { embedded } = this.props;
    const { loading, error, asset, aspect, templateInstances, templates, feedback, searchText } = this.state;

    if (loading) {
      return (
        <React.Fragment>
          { !embedded &&
            <AppTopBar>
              <AppLinkSkeleton />
              <Box m={1} />
              <AppLinkSkeleton />
            </AppTopBar>
          }
          <Drawer open={true} variant="permanent" PaperProps={{style: {width: this.widthDrawer}}}>
            { !embedded && <Toolbar /> }
            <Toolbar>
              <Skeleton width={100} height={20} />
            </Toolbar>
            <Divider />
            <TemplateInstanceListSkeleton countGroups={4} countTemplateInstances={2} />
          </Drawer>
        </React.Fragment>
      );
    } else if (error || !aspect) {
      return <ErrorPage message={error?.message} onReload={() => this.reload()} />;
    } else {
      const templateInstancesTags = listAllTemplateInstancesTags(templateInstances);

      return (
        <React.Fragment>
          { !embedded && asset &&
            <AppTopBar>
              <AppLink name={asset.name} secondary href={`/assets/${asset.id}`} />
              <Box m={1} />
              <AppLink name=">" secondary />
              <Box m={1} />
              <AppLink name={aspect.name} href={`/assets/${asset.id}/aspects/${aspect.id}`} />
              <Box mx='auto'/>
              <AspectMenu aspect={aspect}
                onUpdate={aspect => {
                  this.setState({
                    aspect: aspect,
                    feedback: `Station "${aspect.name}" updated.`
                  })
                }}
                onDelete={aspect => {
                  this.setState({
                    feedback: `Station "${aspect.name}" deleted.`
                  });
                  this.props.history.push(`/assets/${asset.id}`);
                }}
              />
            </AppTopBar>
          }
          <Drawer open={true} variant="permanent" PaperProps={{style: {width: this.widthDrawer, display: 'flex', overflowY: 'hidden'}}}>
            { !embedded && <Toolbar /> }
            <Toolbar>
              <Typography variant="subtitle1" noWrap>
                Template Instances
              </Typography>
              <Box m="auto" />
              <ContentDialog
                size="lg"
                trigger={
                  <Tooltip title="Add New Template Instance">
                    <IconButton color="primary"><AddIcon /></IconButton>
                  </Tooltip>
                }
                renderContent={(close) => (
                  <TemplateInstanceCreator
                    aspectId={aspect.id}
                    onSuccess={templateInstance => {
                      this.setState({
                        templateInstances: [templateInstance, ...templateInstances],
                        feedback: `New TemplateInstance "${templateInstance.name}" created and started`
                      });
                      this.props.history.push(`/assets/${aspect.assetId}/aspects/${aspect.id}/templateInstances/${templateInstance.id}`);
                      close();
                    }}
                    onClose={close}
                  />
                )}
              />
              <DebugDialog object={templateInstances} />
            </Toolbar>
            { templateInstances.length > 0 &&
              <List dense disablePadding>
                <SearchItem onUpdate={(searchText) => this.setState({ searchText })} />
              </List>
            }
            <Divider />
            <Box display="flex" flexDirection="column" flex={1} style={{overflowY: 'scroll'}}>
              { templateInstances.length > 0
                ? <TemplateInstanceList aspect={aspect} templateInstances={templateInstances} searchText={searchText} />
                : <Typography variant="subtitle1" align="center" paragraph>No template instances yet.</Typography>
              }
            </Box>
          </Drawer>
          <Container style={{paddingLeft: this.widthDrawer + 24, flex: 1}}>
            <Switch>
              <Route exact path="/assets/:assetId/aspects/:aspectId">
                {
                  templateInstances.length > 0
                  ? <Redirect to={`/assets/${aspect.assetId}/aspects/${aspect.id}/templateInstances/${templateInstances[templateInstances.length-1].id}`} />
                  : (
                    <Box py={2}>
                      <Typography paragraph variant="subtitle1">
                        Select a Template Instance.
                      </Typography>
                    </Box>
                  )
                }
              </Route>
              <Route exact path="/assets/:assetId/aspects/:aspectId/templateInstances/:templateInstanceId" render={(props) => (
                <TemplateInstancePage key={props.match.params.templateInstanceId}
                  aspect={aspect}
                  templateInstancesTags={templateInstancesTags}
                  templates={templates}
                  onCopy={(templateInstance) => {
                    this.setState({
                      templateInstances: [...templateInstances, templateInstance],
                      feedback: `TemplateInstance "${templateInstance.name}" created.`
                    });
                    this.props.history.push(`/assets/${aspect.assetId}/aspects/${aspect.id}/templateInstances/${templateInstance.id}`);
                  }}
                  onUpdate={(templateInstance) => {
                    this.setState({
                      templateInstances: templateInstances.map(item => item.id === templateInstance.id ? templateInstance : item),
                      // feedback: `TemplateInstance "${templateInstance.name}" updated.`
                    });
                  }}
                  onDelete={(templateInstance) => {
                    this.setState({
                      templateInstances: templateInstances.filter(item => item.id !== templateInstance.id),
                      feedback: `TemplateInstance "${templateInstance.name}" deleted.`
                    })
                    this.props.history.push(`/assets/${aspect.assetId}/aspects/${aspect.id}`);
                  }}
                  {...props}
                />
              )} />
            </Switch>
            <FeedbackToast key={feedback} message={feedback} onHide={() => this.setState({ feedback: undefined })} />
          </Container>
        </React.Fragment>
      );
    }
  }
});