import React from 'react';

import {
  NavLink,
  LinkProps
} from 'react-router-dom';

import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Avatar,
  Badge
} from '@material-ui/core';

import {
  Skeleton
} from '@material-ui/lab';

import {
  TemplateInstance,
  DeploymentStage,
  DeploymentStatus
} from '../api';

import {
  ProgressBar,
  TemplateInstanceIcon
} from '../components';

import {
  formatShortDate,
  getTemplateInstanceColor,
  hasTemplateInstanceFailed,
  hasTemplateInstanceStopped
} from '../utils';

export class TemplateInstanceItemSkeleton extends React.Component {
  render() {
    return (
      <ListItem>
        <ListItemAvatar>
          <Skeleton variant="circle" height={40} width={40} />
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton height={20} width="80%" />}
          secondary={<Skeleton height={15} width="50%" />}
        />
      </ListItem>
    );
  }
}

interface Props {
  templateInstance: TemplateInstance;
  href: string;
}

export class TemplateInstanceItem extends React.Component<Props> {

  render() {
    const { templateInstance, href } = this.props;

    const failure = hasTemplateInstanceFailed(templateInstance);
    const stopped = hasTemplateInstanceStopped(templateInstance);
    const color = getTemplateInstanceColor(templateInstance.latestDeployment?.stage);
    const lastUpdated = formatShortDate(templateInstance.updatedAt);

    const avatar = <Avatar style={{backgroundColor: color}}>{<TemplateInstanceIcon />}</Avatar>;

    const { stage, status } = templateInstance.latestDeployment || {};

    return (
      <ListItem
        button
        component={
          React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>((props, ref) => (
            <NavLink to={href} activeClassName="Mui-selected" {...props} ref={ref} style={{paddingRight: 60}} />
          )
        )}
      >
        <ListItemAvatar>
        { failure
          ? <Badge overlap="circle" color="error" badgeContent=" " variant="dot">{avatar}</Badge>
          : stopped
            ? <Badge overlap="circle" color="secondary" badgeContent="x" variant="dot">{avatar}</Badge>
            : avatar
        }
        </ListItemAvatar>
        <ListItemText
          primary={<Typography noWrap variant="subtitle1">{templateInstance.name}</Typography>}
          secondary={<Typography noWrap variant="body2" color="textSecondary">{templateInstance.additionalAttributes?.tags?.join(', ') || '-'}</Typography>}
        />
        <ListItemSecondaryAction>
          { stage === DeploymentStage.Training && ( status === DeploymentStatus.Preparing || status === DeploymentStatus.Running)
            ? <ProgressBar value={undefined} style={{width: 30}} /> /* FIXME */
            : <Typography variant="caption" color="textSecondary">{lastUpdated}</Typography>
          }
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}