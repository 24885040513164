import React from 'react';

import {
  Alert,
  AlertTitle
} from '@material-ui/lab';

import {
  DebugDialog
} from '../components';

import {
  lookupErrorNameAndMessage
} from '../utils';

type Props = {
  error: Error;
  style?: React.CSSProperties;
}

export class ErrorAlert extends React.Component<Props> {

  render() {
    const { error, style } = this.props;

    const [ name, message ] = lookupErrorNameAndMessage(error);

    return (
      <Alert
        severity="error"
        action={<DebugDialog object={error} />}
        style={style}
      >
        <AlertTitle>{ name }</AlertTitle>
        { message }
      </Alert>
    );
  }
}