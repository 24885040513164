import React from 'react';

import {
  List
} from '@material-ui/core';

import {
  TemplateItem,
  TemplateItemSkeleton
} from '../components';

import {
  Template
} from '../api';

interface SkeletonProps {
  countItems: number;
}

interface Props {
  items: Template[];
  selection?: Template;
  onSelection: (version: Template) => void;
  showVersions?: boolean;
  style?: React.CSSProperties;
}

export class TemplateListSkeleton extends React.Component<SkeletonProps> {
  render() {
    const { countItems } = this.props;
    return (
      <List dense disablePadding>
        { Array(countItems).fill(0).map((val, index) => (
          <TemplateItemSkeleton key={index} />
        )) }
      </List>
    );
  }
}

export class TemplateList extends React.Component<Props> {

  render() {
    const { showVersions, items, style, selection, onSelection } = this.props;

    return (
      <List dense disablePadding style={style}>
        { items.map(item => (
          <TemplateItem
            key={item.id}
            template={item}
            showVersions={showVersions}
            selection={selection}
            onSelection={onSelection}
          />
        )) }
      </List>
    );
  }
}