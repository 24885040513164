import moment from 'moment';

import * as colors from '@material-ui/core/colors';

import {
  LabelType,
  TemplateInstance,
  TemplateInstanceInput,
  Template,
  DeploymentStatus,
  DeploymentStage
} from '../api';

import { searchCase } from '../utils/strings';

export const getTemplateInstanceColor = (stage: DeploymentStage | undefined): string => {
  switch(stage) {
    // case DeploymentStage.Evaluation: return colors.green[500];
    case DeploymentStage.Prediction: return colors.orange[500];
    case DeploymentStage.Training: return colors.indigo[500];
    default: return colors.cyan[500];
  }
}

export const groupTemplateInstances = (templateInstances: TemplateInstance[], text: string = ''): Map<DeploymentStage | undefined, TemplateInstance[]> => {
  const init = new Map<DeploymentStage | undefined, TemplateInstance[]>();
  init.set(undefined, []);
  init.set(DeploymentStage.Training, []);
  // init.set(DeploymentStage.Evaluation, []);
  init.set(DeploymentStage.Prediction, []);

  const searchText = searchCase(text);

  return templateInstances
    .filter(m => {
      return !searchText.length
        || searchCase(`${m.name} ${m.additionalAttributes?.tags?.join(' ')}`).indexOf(searchText) !== -1;
    })
    .sort((a, b) => moment(b.latestDeployment?.updatedAt).diff(a.latestDeployment?.updatedAt))
    .reduce((r, m) => {
      let group = m.latestDeployment?.stage;
      const array = r.get(group);
      if (array)
        array.push(m);
      return r;
    }, init);
}

export const setTemplateInstanceTemplate = (templateInstance: TemplateInstanceInput, template: Template | undefined): void => {
  if (template) {
    templateInstance.templateId = template.id;
    templateInstance.templateVersionId = template.templateVersionId;
    templateInstance.settings = {
      ...template.settings,
      trainingSchema: {
        testSplit: {
          type: 'number',
          description: 'Test/Training Ratio (%) to be used for test split. Default (0) means no test set.',
          minimum: 0,
          maximum: 50,
          multipleOf: 1
        },
        validationSplit: {
          type: 'number',
          description: 'Validation/Training Ratio (%) to be used for validation split. Default (0) means no validation set.',
          minimum: 0,
          maximum: 50,
          multipleOf: 1
        },
        ...template.settings.trainingSchema
      },
      training: {
        testSplit: 0,
        validationSplit: 0,
        ...template.settings.training
      }
    }
  } else {
    templateInstance.templateId = '';
    templateInstance.templateVersionId = '';
  }
}

export const isTemplateInstanceActive = (templateInstance: TemplateInstance): boolean => {
  const { status } = templateInstance.latestDeployment || {};
  return !!status && [ DeploymentStatus.Preparing, DeploymentStatus.Running].includes(status);
}

export const hasTemplateInstanceFailed = (templateInstance: TemplateInstance): boolean => {
  return templateInstance.latestDeployment?.status === DeploymentStatus.Failed;
}

export const hasTemplateInstanceStopped = (templateInstance: TemplateInstance): boolean => {
  return templateInstance.latestDeployment?.status === DeploymentStatus.Stopped;
}

export const isTemplateInstanceInDetectionPhase = (templateInstance: TemplateInstance): boolean => {
  return templateInstance.latestDeployment?.stage === DeploymentStage.Prediction;
}

export const isTemplateInstanceEditable = (templateInstance: TemplateInstance): boolean => {
  const { stage, status } = templateInstance.latestDeployment || {};
  if (stage === undefined) {
    return true;
  }
  else if (stage === DeploymentStage.Training ) {
    return !!status && [ DeploymentStatus.Stopped, DeploymentStatus.Failed].includes(status);
  }
  return false;
}

export const canTemplateInstanceCompleteTraining = (templateInstance: TemplateInstance): boolean => {
  const { stage } = templateInstance.latestDeployment || {};
  if (stage === undefined) {
    return true;
  }
  return false;
}

export const canTemplateInstanceRestartTraining = (templateInstance: TemplateInstance): boolean => {
  const { stage, status } = templateInstance.latestDeployment || {};
  if (stage === DeploymentStage.Training ) {
    return !!status && [ DeploymentStatus.Stopped, DeploymentStatus.Failed].includes(status);
  }
  return false;
}

export const canTemplateInstanceStopTraining = (templateInstance: TemplateInstance): boolean => {
  const { stage, status } = templateInstance.latestDeployment || {};
  return stage === DeploymentStage.Training
    && !!status && [ DeploymentStatus.Preparing, DeploymentStatus.Running].includes(status);
}

export const canTemplateInstanceStartPrediction = (templateInstance: TemplateInstance): boolean => {
  const { stage, status } = templateInstance.latestDeployment || {};
  const { artifact } = templateInstance;

  if (!artifact)
    return false;
  else if (stage === DeploymentStage.Training) {
    return status === DeploymentStatus.Finished;
  }
  else if (stage === DeploymentStage.Prediction) {
    return !!status && [ DeploymentStatus.Stopped, DeploymentStatus.Failed].includes(status);
  }
  return false;
}

export const canTemplateInstanceStopPrediction = (templateInstance: TemplateInstance): boolean => {
  const { stage, status } = templateInstance.latestDeployment || {};
  return stage === DeploymentStage.Prediction
      && !!status && [ DeploymentStatus.Preparing, DeploymentStatus.Running].includes(status);
}

export const listAllTemplateInstancesTags = (templateInstances: TemplateInstance[]): string[] => {
  return templateInstances.reduce((r, m) => {
    m.additionalAttributes?.tags?.forEach(t => {
      if (r.indexOf(t) === -1)
        r.push(t);
    })
    return r;
  }, new Array<string>())
  .sort();
}

export const initTemplateInstance = (init: Partial<TemplateInstanceInput>): TemplateInstanceInput => {
  return {
    name: 'New Template Instance',
    description: '',
    additionalAttributes: {
      tags: []
    },
    templateId: '',
    templateVersionId: '',
    dataInputs: [],
    settings: {
      labelSettings: {
        singleLabels: false,
        fixedLabels: false,
        labelConfiguration: {
          labelType: LabelType.Classification,
          labelDefinitions: []
        }
      },
      training: {},
      prediction: {},
      commons: {},
      trainingSchema: {},
      predictionSchema: {},
      commonsSchema: {}
    },
    ...init
  };
}