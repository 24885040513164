import React from 'react';

import {
  Prompt
} from 'react-router-dom';

import {
  createMuiTheme,
  ThemeProvider
} from '@material-ui/core/styles';

import {
  red
} from '@material-ui/core/colors';

import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';

import {
  ErrorAlert
} from '../components';

interface Props<R> {
  title: string;
  trigger: React.ReactElement<any>;
  resource: R;
  action: (resource: R) => Promise<any>;
  onSuccess: (resource: R) => void;
  onCancel?: () => void;
  children?: React.ReactNode;
}

class State {
  open: boolean = false;
  error?: Error;
  isSubmitting: boolean = false;
}

const errorTheme = createMuiTheme({
  palette: {
    primary: {
      main: red[500],
    },
  },
});

export class ResourceDeleteDialog<R extends unknown> extends React.Component<Props<R>, State> {

  readonly state = new State();

  handleSubmit = () => {
    const { resource } = this.props;
    this.setState({ isSubmitting: true, error: undefined })
    this.props.action(resource)
    .then(() => {
      this.setState({ open: false });
      this.props.onSuccess(resource);
    })
    .catch(error => {
      this.setState({ error, isSubmitting: false });
    })
  }

  handleCancel = () => {
    this.setState(new State());
    if (this.props.onCancel)
      this.props.onCancel();
  }

  componentDidUpdate = () => {
    window.onbeforeunload = this.state.open ? () => true : null;
  }

  render() {
    const { trigger, title, children } = this.props;
    const { open, error, isSubmitting } = this.state;

    const triggerElement = React.cloneElement(trigger, {
      onClick: () => {
        if (trigger.props.onClick)
          trigger.props.onClick();
        this.setState({ open: true });
      }
    });

    return (
      <React.Fragment>
        {triggerElement}
        <Dialog
          open={open}
          maxWidth="xs"
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          onClose={this.handleCancel}
        >
          <DialogTitle>{title}</DialogTitle>
          { error && <ErrorAlert error={error} /> }
          <Prompt when={open} message="Changes you made may not be saved." />
          <DialogContent>
            { children }
          </DialogContent>
          <DialogActions >
            <Button color="secondary"
              onClick={this.handleCancel}>
              No, Cancel
            </Button>
            <ThemeProvider theme={errorTheme}>
              <Button color="primary" variant="contained"
                disabled={isSubmitting}
                startIcon={ isSubmitting && <CircularProgress color="inherit" size={20} /> }
                onClick={this.handleSubmit}>
                Yes, Delete
              </Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}