import { Assets, Aspects, DataCollections, Datasets, Deployments, Labelsets, TemplateInstances, Templates } from './services';

import config from './config';

import HTTPClient from './http-client';

export default {
  default:           new HTTPClient(config),
  assets:            new Assets(config),
  aspects:           new Aspects(config),
  datasets:          new Datasets(config),
  dataCollections:   new DataCollections(config),
  deployments:       new Deployments(config),
  labelsets:         new Labelsets(config),
  templateInstances: new TemplateInstances(config),
  templates:         new Templates(config)
};

export { default as HTTPClient, computeStats } from './http-client';

export * from './models';