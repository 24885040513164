import React from 'react';

import {
  Prompt
} from 'react-router-dom';

import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';

import API, {
  DeploymentStage,
  DeploymentArtifactInput,
  TemplateInstance,
  TemplateInstanceInput,
  Aspect
} from '../api';

import {
  ErrorAlert,
  TemplateInstancePredictionForm
} from '../components';

interface Props {
  title: string;
  trigger: React.ReactElement<any>;
  templateInstance: TemplateInstance;
  aspect: Aspect;
  onSuccess: (templateInstance: TemplateInstance) => void;
  onCancel?: () => void;
}

class State {
  open: boolean = false;
  error?: Error;
  isSubmitting: boolean = false;
}

export class TemplateInstancePredictionDialog extends React.Component<Props, State> {

  readonly state = new State();

  readonly formRef = React.createRef<any>();

  handleSubmit = (values: TemplateInstanceInput) => {
    const { id, artifact } = this.props.templateInstance;
    const { latestDeployment } = values;
    if (!artifact || !latestDeployment || !latestDeployment.inputs)
      return this.setState({ error: new Error('missing artifact/latestDeployment') });

    const deploymentInput = {
      stage: DeploymentStage.Prediction,
      inputs: [
        {
          type: 'DeploymentArtifactInput',
          deployment_artifact_id: artifact.id
        } as DeploymentArtifactInput,
        ...latestDeployment.inputs
      ],
      runtimeId: latestDeployment.runtimeId
    }
    this.setState({ isSubmitting: true, error: undefined })
    // return API.templateInstances.updateTemplateInstance(id, values)
    // .then(instance => API.templateInstances.startTemplateInstanceDeployment(id, deploymentInput))
    return API.templateInstances.startTemplateInstanceDeployment(id, deploymentInput)
    .then(deployment => API.templateInstances.getTemplateInstance(id))
    .then(templateInstance => {
      this.setState(new State());
      this.props.onSuccess(templateInstance);
    })
    .catch(error => {
      this.setState({ error, isSubmitting: false });
    })
  }

  handleCancel = () => {
    this.setState(new State());
    if (this.props.onCancel)
      this.props.onCancel();
  }

  componentDidUpdate = () => {
    window.onbeforeunload = this.state.open ? () => true : null;
  }

  render() {
    const { trigger, title, templateInstance, aspect } = this.props;
    const { open, error, isSubmitting } = this.state;

    const triggerElement = React.cloneElement(trigger, {
      onClick: () => {
        if (trigger.props.onClick)
          trigger.props.onClick();
        this.setState({ open: true });
      }
    });

    return (
      <React.Fragment>
        {triggerElement}
        <Dialog
          open={open}
          maxWidth={'sm'}
          disableBackdropClick
          fullWidth
          onClose={this.handleCancel}
          PaperProps={{style: {height: 600 }}}
        >
          <DialogTitle>{title}

          </DialogTitle>
          { error && <ErrorAlert error={error} /> }
          <Prompt when={open} message="Changes you made may not be saved." />
          <DialogContent>
            <TemplateInstancePredictionForm
              innerRef={this.formRef}
              onSubmit={this.handleSubmit}
              templateInstanceInput={templateInstance}
              aspect={aspect}
            />
          </DialogContent>
          <DialogActions>
            <Box mx="auto" />
            <Button color="secondary"
              onClick={this.handleCancel}>
              Cancel
            </Button>
            <Button color="primary" variant="contained"
              disabled={isSubmitting}
              startIcon={ isSubmitting && <CircularProgress color="inherit" size={20} /> }
              onClick={() => this.formRef.current.submitForm()}>
              Start
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}