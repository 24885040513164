import React from 'react';
import preval from 'preval.macro';
import moment from 'moment';

import {
  Divider,
  Toolbar,
  Avatar,
  Box,
  Tooltip,
  Typography
} from '@material-ui/core';

import {
  AvatarGroup
} from '@material-ui/lab';

import logo from '../graphics/logo.jpg';

export class AppFooter extends React.Component {
  render() {
    const version = preval`module.exports = process.env.APP_VERSION;`
    const timestamp = preval`module.exports = new Date().toISOString();`

    return (
      <React.Fragment>
        <Divider />
        <Toolbar>
          <AvatarGroup max={2}>
            <Avatar src={logo} />
          </AvatarGroup>
          <Box mx="auto" />
          <Tooltip title={moment(timestamp).format()}>
            <Typography variant="caption" color="textSecondary">
              {version} © {moment(timestamp).year()}
            </Typography>
          </Tooltip>
        </Toolbar>
      </React.Fragment>
    );
  }
}