import React from 'react';

import {
  Container,
  Typography
} from '@material-ui/core';

import {
  AppTopBar,
  ErrorBox
} from '../components';

interface Props {
  title?: string;
  message?: string;
  onReload?: () => void;
}

export class ErrorPage extends React.Component<Props> {

  render() {
    const { title, message, onReload } = this.props;

    return (
      <React.Fragment>
        <AppTopBar>
          <Typography variant="h6">
            { title || 'Error' }
          </Typography>
        </AppTopBar>
        <Container>
          <ErrorBox message={message} onReload={onReload} />
        </Container>
      </React.Fragment>
    )
  }
}