import React from 'react';

import preval from 'preval.macro';

import {
  BrowserRouter
} from 'react-router-dom';

import {
  ThemeProvider
} from '@material-ui/core/styles';

import {
  CssBaseline
} from '@material-ui/core';

import {
  AppRouter, TemplatesPage
} from '../components';

import theme from '../theme';

export class App extends React.Component {
  render() {
  	const basename = process.env.PUBLIC_URL || '';
  	const router = preval`module.exports = process.env.APP_ROUTER;`;
  	const title = preval`module.exports = process.env.APP_TITLE;`;

  	if (title)
  		document.title = title;

  	return (
  		<BrowserRouter basename={basename}>
		    <ThemeProvider theme={theme}>
		      <CssBaseline />
		      { router === 'templates'
		    		? <TemplatesPage embedded baseURL="" />
		    		: <AppRouter />
		    	}
		    </ThemeProvider>
		  </BrowserRouter>
  	);
  }
};