import React from 'react';

import {
  Box,
  Divider,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core';

import {
  Close as CloseIcon,
  Settings as SettingsIcon,
  Edit as EditIcon,
  DeleteForever as DeleteIcon
} from '@material-ui/icons';

import API, {
  DataCollectionObject,
  DataCollection,
  Dataset,
  DatasetInput
} from '../api';

import {
  ContentDialog,
  DatasetBadge,
  DatasetEditor,
  DatasetIcon,
  DatasetForm,
  DatasetSkeleton,
  DebugDialog,
  ErrorBox,
  ImageContainer,
  ImagePreview,
  ImageTile,
  ImageZoomControl,
  PaginationToolbar,
  ResourceDeleteDialog,
  ResourceEditDialog
} from '../components';

import {
  loadDatasetObjectsPage
} from '../utils';

interface Props {
  datasetVersionId: string;
  actions?: React.ReactNode;
  style?: React.CSSProperties;
  onClose?: () => void;
  onUpdate?: (dataset: Dataset) => void;
  onDelete?: (dataset: Dataset) => void;
}

class State {
  columns: number = 0;
  loading: boolean = true;
  paginating: boolean = false;
  error?: Error;
  dataset?: Dataset;
  dataCollection?: DataCollection;
  objects?: Array<DataCollectionObject>;
  locked: boolean = true;
  pageIndex: number = 0;
  imagePreview?: DataCollectionObject;
}

export class DatasetViewer extends React.Component<Props, State> {

  private containerRef = React.createRef<HTMLDivElement>();

  readonly state = new State();

  onPageIndexChange = (datasetVersionId: string, pageIndex: number) => {
    this.setState({ paginating: true, error: undefined, pageIndex });
    loadDatasetObjectsPage(datasetVersionId, pageIndex)
    .then(objects => this.setState({ paginating: false, objects }))
    .catch(error => this.setState({ paginating: false, error }))
  }

  componentDidMount() {
    const { datasetVersionId } = this.props;
    this.setState({ loading: true, error: undefined, dataset: undefined })
    Promise.all([
      API.datasets.getDatasetVersion(datasetVersionId),
      loadDatasetObjectsPage(datasetVersionId)
    ])
    .then(([dataset, objects]) => {
      return API.dataCollections.getDataCollection(dataset.dataCollectionId)
      .then(dataCollection => {
        // temporary workaround to find out if object is locked
        return API.datasets.patchDatasetVersionObjects(datasetVersionId, [], [])
        .then(dataset => this.setState({ loading: false, dataset, objects, dataCollection, locked: false }))
        .catch(error => this.setState({ loading: false, dataset, objects, dataCollection, locked: true }))
      })
    })
    .catch(error => this.setState({ loading: false, error }))
  }

  render() {
    const { actions, style, onClose, onUpdate, onDelete } = this.props;
    const { loading, paginating, error, dataset, dataCollection, objects, columns, pageIndex, locked, imagePreview } = this.state;

    if (loading)
      return <DatasetSkeleton aspectRatio={1} columns={8} imagesCount={20} />;

    if (error || !dataset || !dataCollection || !objects)
      return <ErrorBox message={error?.message} onReload={() => this.componentDidMount()} />

    const { numberOfObjects } = dataset;
    const { aspectRatio } = objects[0]?.attributes || {};

    return (
      <Box style={style} flex={1} display="flex" flexDirection="column">
        <Toolbar>
          <DatasetIcon />
          <Box mx={1} />
          <Typography noWrap variant="h6">{dataset.name}</Typography>
          <Box mx={1} />
          <ResourceEditDialog<DatasetInput, Dataset>
            title="Basic Settings"
            resource={{...dataset}}
            trigger={<IconButton><SettingsIcon/></IconButton>}
            action={body => API.datasets.updateDatasetVersion(dataset.datasetVersionId, body) }
            onSuccess={dataset => {
              this.setState({ dataset });
            }}
            renderForm={(datasetInput, ref, handleSubmit) => (
              <DatasetForm
                datasetInput={datasetInput}
                innerRef={ref}
                onSubmit={handleSubmit} />
            )}
          />
          { !locked &&
            <ContentDialog
              trigger={<IconButton><EditIcon/></IconButton>}
              fullscreen
              renderContent={(close) => (
                <DatasetEditor
                  dataCollectionId={dataset.dataCollectionId}
                  datasetVersionId={dataset.datasetVersionId}
                  onUpdate={dataset => {
                    this.setState({ dataset });
                    this.onPageIndexChange(dataset.datasetVersionId, pageIndex);
                  }}
                  onClose={close}
                />
              )}
            />
          }
          { !locked &&
            <ResourceDeleteDialog<Dataset>
              resource={dataset}
              title="Delete Dataset ?"
              trigger={<IconButton><DeleteIcon/></IconButton>}
              action={resource => API.datasets.deleteDatasetVersion(resource.datasetVersionId) }
              onSuccess={dataset => {
                onDelete?.(dataset);
                onClose?.();
              }}
            />
          }
          { actions }
          <DebugDialog object={this.state} />
          <Box mx="auto" />
          { onClose &&
            <IconButton
              onClick={() => {
                onUpdate?.(dataset)
                onClose?.();
              }}>
              <CloseIcon />
            </IconButton>
          }
        </Toolbar>
        { imagePreview &&
          <ContentDialog
            size="lg"
            renderContent={(close) => (
              <ImagePreview
                icon={<DatasetIcon/>}
                object={imagePreview}
                onClose={() => this.setState({ imagePreview: undefined })}
              />
            )}
          />
        }
        <Divider />
        <Toolbar variant="dense">
          <DatasetBadge dataset={dataset} dataCollection={dataCollection} />
          <Box mx="auto" />
          <PaginationToolbar
            pageIndex={pageIndex}
            totalItems={numberOfObjects}
            onPageIndexChange={pageIndex => this.onPageIndexChange(dataset.datasetVersionId, pageIndex)}
          />
        </Toolbar>
        <Box display="flex" flexDirection="column-reverse" style={{flex: '1 1 1px', overflowY: 'hidden'}} paddingX={0.5}>
          { paginating &&
            <Box style={{flex: '1 1 1px', overflowY: 'scroll'}}>
              <ImageContainer
                columns={columns}
                aspectRatio={aspectRatio}
              >
                {Array(100).fill(0).map((val, index) => (
                  <ImageTile key={index} />
                ))}
              </ImageContainer>
            </Box>
          }
          { !paginating &&
            <Box style={{flex: '1 1 1px', overflowY: 'scroll'}} { ...{ref: this.containerRef}}>
              { numberOfObjects === 0 &&
                <Box display="flex" p={1} justifyContent="center"><Typography variant="body2" color="textSecondary">No images selected yet.</Typography></Box>
              }
              <ImageContainer
                columns={columns}
                aspectRatio={aspectRatio}
              >
                { objects.map((image, index) => (
                  <ImageTile
                    key={index}
                    image={image}
                    onClick={() => this.setState({ imagePreview: image })}
                  />
                ))}
              </ImageContainer>
            </Box>
          }
        </Box>
        <Toolbar variant="dense">
          <PaginationToolbar
            pageIndex={pageIndex}
            totalItems={numberOfObjects}
          />
          <Box mx="auto" />
          <ImageZoomControl
            containerRef={this.containerRef}
            columns={columns}
            onChange={columns => {
              this.setState({ columns });
            }}
          />
        </Toolbar>
      </Box>
    )
  }
}