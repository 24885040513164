import jwtDecode from 'jwt-decode'

import { User, AuthProvider } from '../definitions';

import { parseURLQuery, fullURLPath } from '../utils';

const STORAGE_TOKEN = 'authToken';

interface OAuthConfig {
  base: string;
  client_id: string;
  client_secret?: string;
  redirect_uri: string;
}

export interface OAuthPayload {
  aud: string;
  exp: number;
  iss: string;
}

export abstract class OAuthProvider<P extends OAuthPayload> implements AuthProvider  {

  readonly config: OAuthConfig;

  // parse config based on given URL
  constructor (protected configURL: URL) {
    this.config = this.parseConfig(configURL);
  }

  // compute login URL based on configuration
  abstract loginURL (): Promise<URL>;

  // compute logout URL based on configuration
  abstract logoutURL (): Promise<URL>;

  // parse authentication code from given URL
  abstract parseCode (url: URL): Promise<string | null>;

  // exchange code for user token promise
  abstract exchangeCodeForToken (code: string, url: URL): Promise<string>;

  // checks token is valid
  abstract validatePayload (payload: P): Promise<boolean>;

  // extract user information from token
  abstract deserializeUser (payload: P): User;

  private parseConfig (configURL: URL): OAuthConfig {
    // retrieve URL full path without parameters/hash
    const base = fullURLPath(configURL).href;
    // parse URL query parameters
    const { client_id, redirect_uri, client_secret } = parseURLQuery(configURL);

    // build config object
    return {
      base,
      client_id,
      redirect_uri,
      client_secret
    };
  }

  private decodeToken(token: string): P {
    return jwtDecode<P>(token);
  }

  async validate(): Promise<void> {
    // retrieve config required fields
    const { base, client_id, redirect_uri } = this.config;
    // individual checks
    if (!base) throw new Error('invalid configuration URL');
    else if (!client_id) throw new Error('no `client_id` parameter found');
    else if (!redirect_uri) throw new Error('no `redirect_uri` parameter found');
  }

  async signin(): Promise<void> {
    // redirect to external login URL
    const url = await this.loginURL();
    window.location.href = url.href;
  }

  async signout(): Promise<void> {
    // clear localStorage
    localStorage.removeItem(STORAGE_TOKEN);

    // redirect to external logout URL
    const url = await this.logoutURL();
    window.location.href = url.href;
  }

  currentUser(): User | null {
    // retrieve token from storage
    const token = localStorage.getItem(STORAGE_TOKEN);
    if (!token)
      return null;

    // retrieve payload
    const payload = this.decodeToken(token);

    // deserialize and return user info
    return this.deserializeUser(payload);
  }

  async confirm (url: URL): Promise<string | undefined> {

    // parse code from URL
    const code = await this.parseCode(url);
    // cancel when no code found
    if (!code)
      return undefined;

    // exchange code for user token
    const token = await this.exchangeCodeForToken(code, url);

    // decode token and extract payload
    const payload = this.decodeToken(token);

    // validate payload and reject if invalid
    const valid = this.validatePayload(payload);
    if (!valid)
      throw new Error('invalid token');

    // save token to storage
    localStorage.setItem(STORAGE_TOKEN, token);
    // return token
    return token;
  }
}