import preval from 'preval.macro'

import { AuthProvider } from './definitions'

import CognitoProvider from './providers/cognito'
import KeycloakProvider from './providers/keycloak'
import AnonymousProvider from './providers/anonymous'

const AUTH_PROVIDER = preval`module.exports = process.env.AUTH_PROVIDER;`;
const AUTH_URL = preval`module.exports = process.env.AUTH_URL;`;

export function loadProvider(): AuthProvider {
  if (!AUTH_URL || !AUTH_PROVIDER)
    return new AnonymousProvider();

  const configURL = new URL(AUTH_URL);

  switch (AUTH_PROVIDER) {
    case 'cognito': return new CognitoProvider(configURL);
    case 'keycloak': return new KeycloakProvider(configURL);
    default: throw new Error("unsupported AUTH_PROVIDER");
  }
}

const provider = loadProvider();

provider.validate();

export default provider;