import React from 'react';

import {
  Toolbar,
  Dialog,
  Box,
  IconButton,
  Typography
} from '@material-ui/core';

import {
  Close as CloseIcon,
} from '@material-ui/icons';

import {
  Aspect
} from '../api';

import {
  DebugDialog
} from '../components';

interface Props {
  trigger: React.ReactElement<any>;
  aspect: Aspect;
}

class State {
  open: boolean = false;
}

export class AspectDialog extends React.Component<Props, State> {

  readonly state = new State();

  handleClose = () => {
    this.setState(new State());
  }

  render() {
    const { trigger, aspect } = this.props;
    const { open } = this.state;

    const triggerElement = React.cloneElement(trigger, {
      onClick: (evt: any) => {
        if (trigger.props.onClick)
          trigger.props.onClick(evt);
        this.setState({ open: true });
      }
    });

    return (
      <React.Fragment>
        {triggerElement}
        <Dialog
          open={open}
          maxWidth={'xs'}
          disableBackdropClick
          fullWidth
          onClose={this.handleClose}
          PaperProps={{style: {maxHeight: 600 }}}
        >
          <Toolbar>
            <Typography variant="h6">{aspect.name}</Typography>
            <Box mx={1} />
            <DebugDialog object={aspect} />
            <Box mx="auto" />
            <IconButton onClick={this.handleClose}><CloseIcon /></IconButton>
          </Toolbar>
          <Box px={3}>
            <Typography variant="subtitle1" paragraph>
              {aspect.description}
            </Typography>
          </Box>
        </Dialog>
      </React.Fragment>
    )
  }
}