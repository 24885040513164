import React from 'react';

import {
  Snackbar
} from '@material-ui/core';

interface Props {
  message?: string;
  onHide?: () => void;
}

class State {
  open: boolean = true;
}

export class FeedbackToast extends React.Component<Props, State> {

  readonly state = new State();

  render() {
    const { message } = this.props;
    const { open } = this.state;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open && Boolean(message)}
        autoHideDuration={2000}
        onClose={() => {
          this.setState({open: false });
          if(this.props.onHide) {
            this.props.onHide();
          }
        }}
        message={message}
      />
    );
  }
}