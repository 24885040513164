import React from 'react';

import {
  IconButton, Tooltip
} from '@material-ui/core';

import {
  Fullscreen as EnterIcon,
  FullscreenExit as ExitIcon
} from '@material-ui/icons';

interface Props {
  targetRef: React.RefObject<HTMLDivElement>;
}

class State {
  active: boolean = false;
}

export class FullscreenButton extends React.Component<Props, State> {

  readonly state = new State();

  onExit = () => {
    this.setState({ active: document.fullscreenElement != null });
  }

  componentDidMount() {
    setTimeout(this.onExit, 100);

    document.addEventListener('fullscreenchange', this.onExit);
  }

  componentWillUnmount() {
    document.removeEventListener('fullscreenchange', this.onExit);
  }

  render() {
    const { targetRef } = this.props;
    const { active } = this.state;

    if (!!document.exitFullscreen && active) {
      return (
        <Tooltip title="Exit Fullscreen">
          <IconButton onClick={e => document.exitFullscreen()}>
            <ExitIcon />
          </IconButton>
        </Tooltip>
      )
    } else if (targetRef.current && targetRef.current.requestFullscreen) {
      return (
        <Tooltip title="Enter Fullscreen">
          <IconButton onClick={e => targetRef.current!.requestFullscreen()}>
            <EnterIcon />
          </IconButton>
        </Tooltip>
      )
    } else {
      return null;
    }
  }
}