import React from 'react';

import {
  Box,
  Typography
} from '@material-ui/core';


import {
  Pagination
} from '@material-ui/lab';

export type PaginationToolbarProps = {
  pageIndex: number;
  totalItems: number;
  itemsPerPage?: number;
  onPageIndexChange?: (pageIndex: number) => void;
}

export const PaginationToolbar = ({
  pageIndex,
  totalItems,
  itemsPerPage = 100,
  onPageIndexChange
}: PaginationToolbarProps) => {

  if (totalItems <= itemsPerPage)
    return null;

  const countPages = Math.ceil(totalItems / itemsPerPage * 1.0);
  const fromIndex = itemsPerPage * pageIndex;
  const toIndex = fromIndex + Math.min(itemsPerPage, (totalItems - fromIndex)) - 1;

  const label = `Images ${fromIndex+1}-${toIndex+1} of ${totalItems}`;

  if (!onPageIndexChange)
    return <Typography variant="body2" color="textSecondary" children={label} />;

  return (
    <Box display="flex" flexDirection="row">
      <Typography variant="body1">Page:</Typography>
      <Box mx={0.5} />
      <Pagination
        hidePrevButton
        hideNextButton
        size="small"
        color="secondary"
        count={countPages}
        page={pageIndex + 1}
        onChange={(evt, page) => onPageIndexChange(page - 1)}
      />
    </Box>
  );
}