import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent
} from "@material-ui/core";

interface Props {
  title: string;
  show: boolean;
  children: React.ReactNode;
}

class State {
  open: boolean = false;
}

export class ProgressDialog extends React.Component<Props, State> {

  readonly state = new State();

  componentDidMount() {
    console.log('ProgressDialog mounted!');
  }

  componentDidUpdate = () => {
    window.onbeforeunload = this.props.show ? () => true : null;
  };

  render() {
    const { show, title, children } = this.props;

    if (!this.props.show) {
      return null;
    }

    return (
      <React.Fragment>
        <Dialog
          open={show}
          maxWidth="xs"
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{children}</DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}
