import React from 'react';

import {
  startCase
} from 'lodash';

import {
  Chip
} from '@material-ui/core';

import {
  LabelType
} from '../api';

import {
  LabelTypeAvatar
} from '../components';

export type LabelTypeBadgeProps = {
  labelType: LabelType;
  showAvatar?: boolean;
}

export const LabelTypeBadge = ({
  labelType,
  showAvatar
}: LabelTypeBadgeProps) => {

  return (
    <Chip
      label={startCase(labelType)}
      size="small"
      variant="outlined"
      avatar={showAvatar ? <LabelTypeAvatar labelType={labelType} /> : undefined}
      disabled
    />
  );
}