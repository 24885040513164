import React from 'react';

import {
  Dataset,
  DataCollection
} from '../api';

import {
  Chip
} from '@material-ui/core';

import {
  CropOriginal as ImagesIcon,
} from '@material-ui/icons';

export type DatasetBadgeProps = {
  dataset: Dataset;
  dataCollection?: DataCollection;
  style?: React.CSSProperties;
}

export const DatasetBadge = ({
  style,
  dataset,
  dataCollection
}: DatasetBadgeProps) => {

  const { numberOfObjects } = dataset;

  if (numberOfObjects === undefined)
    return null;

  const styles = {
    color: numberOfObjects === 0 ? 'red' : undefined,
    background: 'none',
    ...style
  }

  const label = dataCollection ? `${numberOfObjects} / ${dataCollection.numberOfObjects}` : numberOfObjects;

  return (
    <Chip
      size="small"
      icon={<ImagesIcon fontSize="small" style={{color: styles.color}} />}
      disabled
      style={styles}
      label={label}
    />
  );
}