import React from 'react';

import {
  Link as RouterLink
} from 'react-router-dom';

import {
  Box,
  Link,
  Card,
  CardActionArea,
  CardHeader,
  Typography
} from '@material-ui/core';

import {
  Skeleton
} from '@material-ui/lab';

import {
  Aspect
} from '../api';

import {
  AspectMenu
} from '../components';

interface Props {
  aspect: Aspect;
  href: string;
  onUpdate: (aspect: Aspect) => void;
  onDelete: (aspect: Aspect) => void;
}

export class AspectCardSkeleton extends React.Component {

  render() {
    return (
      <Card>
        <Skeleton height={150} variant="rect" />
        <CardHeader
          avatar={<Skeleton variant="circle" height={40} width={40} />}
          title={<Skeleton height={20} width="80%" />}
          subheader={<Skeleton height={15} width="50%" />}
        />
      </Card>
    );
  }
}

export class AspectCard extends React.Component<Props> {

  render() {
    const { aspect, href, onUpdate, onDelete } = this.props;

    return (
      <Card>
        <CardActionArea component={RouterLink} to={href}>
          <Skeleton height={150} variant="rect" animation={false} />
        </CardActionArea>
        <CardHeader
          avatar={
            <Skeleton variant="circle" height={40} width={40} animation={false} />
          }
          title={
            <Link component={RouterLink} to={href} noWrap>
              <Typography variant="subtitle1" noWrap>{aspect.name}</Typography>
            </Link>
          }
          subheader={
            <Typography variant="body2" color="textSecondary" noWrap>
              {aspect.description}&nbsp;
            </Typography>
          }
          action={
            <Box mt={2}>
              <AspectMenu aspect={aspect} onUpdate={onUpdate} onDelete={onDelete} />
            </Box>
          }
        />
      </Card>
    );
  }
}