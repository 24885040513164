import React from 'react';

import {
  IconButton
} from '@material-ui/core';

import {
  MoreHoriz as ViewIcon,
} from '@material-ui/icons';

import {
  Dataset,
  Labelset
} from '../api';

import {
  ContentDialog,
  LabelsetViewer,
} from '../components';

interface Props {
  dataset: Dataset;
  labelset: Labelset;
  onUpdate: (labelset: Labelset) => void;
  onDelete: (labelset: Labelset) => void;
}

class State {
  anchor: HTMLButtonElement | null = null;
}

export class LabelsetMenu extends React.Component<Props, State> {

  readonly state = new State();

  show = (evt: any) => {
    this.setState({ anchor: evt.currentTarget });
  }

  hide = () => {
    this.setState({ anchor: null });
  }

  render() {
    const { labelset, onUpdate, onDelete } = this.props;

    return (
      <ContentDialog
        trigger={<IconButton><ViewIcon /></IconButton>}
        renderContent={(close) => (
          <LabelsetViewer
            labelsetId={labelset.id}
            datasetVersionId={labelset.datasetVersionId}
            onClose={close}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        )}
      />
    );
  }
}