import 'typeface-roboto';

import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

import './index.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0653b6',
    },
    secondary: {
      main: '#262626',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#f8f8f8',
    },
    text: {
      primary: "#262626",
      secondary: "#8e8e8e"
    }
  },
  overrides: {
    MuiDialog: {
      container: {
        maxWidth: 1280
      }
    },
    MuiContainer: {
      root: {
        marginLeft: 0,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden'
      },
    },
    MuiAppBar: {
      root: {
        zIndex: 1300
      }
    }
  },
});

export default theme;