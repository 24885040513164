import React from 'react';

import {
  SelectableGroup
} from 'react-selectable-fast';

import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Paper,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core';

import {
  Close as CloseIcon,
  Settings as SettingsIcon,
  CheckCircleOutline as SelectAllIcon,
  HighlightOff as UnselectAllIcon
} from '@material-ui/icons';

import {
  Skeleton
} from '@material-ui/lab';

import API, {
  DataCollection,
  DataCollectionObject,
  Dataset,
  DatasetInput,
  DataType,
} from '../api';

import {
  AutoSaveBadge,
  DatasetBadge,
  DatasetIcon,
  DatasetForm,
  DatasetSkeleton,
  DebugDialog,
  ErrorBox,
  ImageContainer,
  ImageTile,
  ImageZoomControl,
  PaginationToolbar,
  ResourceEditDialog
} from '../components';

import {
  loadDataCollectionPageObjectsAndDatasetIds
} from '../utils';

interface Props {
  datasetVersionId?: string;
  dataCollectionId: string;
  onCreate?: (dataset: Dataset) => void;
  onUpdate?: (dataset: Dataset) => void;
  onClose: () => void;
}

class State {
  loading: boolean = true;
  paginating: boolean = false;
  saving: boolean = false;
  error?: Error;
  dataset?: Dataset;
  dataCollection?: DataCollection;
  objects?: Array<DataCollectionObject>;
  includeIds?: Array<string>;
  pageIndex: number = 0;
  columns: number = 0;
}

export class DatasetEditor extends React.Component<Props, State> {

  readonly state = new State();

  private selectableRef = React.createRef<SelectableGroup>();

  private containerRef = React.createRef<HTMLDivElement>();

  onPageIndexChange = (dataCollectionId: string, datasetVersionId: string, pageIndex: number) => {
    this.setState({ paginating: true, error: undefined, pageIndex });
    loadDataCollectionPageObjectsAndDatasetIds(dataCollectionId, datasetVersionId, pageIndex)
    .then(([objects, includeIds]) => this.setState({ paginating: false, objects, includeIds }))
    .catch(error => this.setState({ paginating: false, error }))
  }

  resetDatasetVersion = (datasetVersionId: string, pageIds: Array<string>, includeAll: boolean) => {
    this.setState({ saving: true, error: undefined });
    API.datasets.patchDatasetVersionObjects(datasetVersionId, includeAll ? 'all' : [], includeAll ? [] : 'all')
    .then(dataset => {
      this.setState({ saving: false, dataset, includeIds: includeAll ? pageIds : [] })
      const group = this.selectableRef.current!;
      if (includeAll) group.selectAll();
      else group.clearSelection();
      this.props.onUpdate?.(dataset);
    })
    .catch(error => this.setState({ saving: false, error }))
  }

  patchDatasetVersion = (datasetVersionId: string, pageIds: Array<string>, includeIds: Array<string>) => {
    this.setState({ saving: true, error: undefined });
    const excludeIds = pageIds.filter(id => !includeIds.includes(id));
    API.datasets.patchDatasetVersionObjects(datasetVersionId, includeIds, excludeIds)
    .then(dataset => {
      this.setState({ saving: false, dataset, includeIds });
      this.props.onUpdate?.(dataset);
    })
    .catch(error => this.setState({ saving: false, error }))
  }

  componentDidMount () {
    const { datasetVersionId, dataCollectionId, onCreate } = this.props;
    const newDataset: DatasetInput = {
      dataCollectionId,
      dataType: DataType.Images,
      name: 'New Dataset',
      description: ''
    };

    this.setState({ loading: true, error: undefined });
    Promise.all([
      API.dataCollections.getDataCollection(dataCollectionId),
      (datasetVersionId ? API.datasets.getDatasetVersion(datasetVersionId) : API.datasets.createDataset(newDataset))
    ])
    .then(([dataCollection, dataset]) => {
      if (!datasetVersionId)
        onCreate?.(dataset);

      return loadDataCollectionPageObjectsAndDatasetIds(dataCollectionId, dataset.datasetVersionId)
      .then(([objects, includeIds]) => {
        this.setState({
          loading: false,
          dataCollection,
          dataset,
          objects,
          includeIds
        });
      });
    })
   .catch(error => this.setState({ loading: false, error }))
  }

  render() {
    const { onUpdate, onClose } = this.props;
    const { loading, paginating, error, dataset, dataCollection, objects, includeIds, columns, pageIndex, saving } = this.state;

    if (loading) {
      return <DatasetSkeleton aspectRatio={1} columns={8} imagesCount={20} />;
    }

    if (error || !dataset || !dataCollection || !objects || !includeIds) {
      return <ErrorBox message={error?.message} onReload={() => this.componentDidMount()} />
    }

    const pageIds = objects.map(o => o.id);

    const { numberOfObjects } = dataCollection;
    const { aspectRatio } = objects[0]?.attributes;
    const hasMultiplePages = objects.length < numberOfObjects;

    return (
      <Box display="flex" flexDirection="column" flex={1} component={Paper}>
        <Toolbar>
          <DatasetIcon />
          <Box mx={1} />
          <Typography variant="h6" noWrap>{dataset.name}</Typography>
          <Box mx={1} />
          <ResourceEditDialog<DatasetInput, Dataset>
            title="Basic Settings"
            resource={{...dataset}}
            trigger={
              <Tooltip title="Basic Settings">
                <IconButton>
                  <SettingsIcon/>
                </IconButton>
              </Tooltip>
            }
            action={body => API.datasets.updateDatasetVersion(dataset.datasetVersionId, body) }
            onSuccess={dataset => {
              this.setState({ dataset });
              onUpdate?.(dataset);
            }}
            renderForm={(datasetInput, ref, handleSubmit) => (
              <DatasetForm
                datasetInput={datasetInput}
                innerRef={ref}
                onSubmit={handleSubmit} />
            )}
          />
          { hasMultiplePages &&
            <Tooltip title="Select All">
              <IconButton
                children={<SelectAllIcon />}
                onClick={() => this.resetDatasetVersion(dataset.datasetVersionId, pageIds, true)}
              />
            </Tooltip>
          }
          { hasMultiplePages &&
            <Tooltip title="Reset">
              <IconButton
                style={{color: 'red'}}
                children={<UnselectAllIcon />}
                onClick={() => this.resetDatasetVersion(dataset.datasetVersionId, pageIds, false)}
              />
            </Tooltip>
          }
          <DebugDialog object={this.state} />
          <Box mx="auto" />
          <AutoSaveBadge lastEditAt={dataset.updatedAt} saving={saving} />
          <IconButton onClick={() => {
            onClose?.();
          }}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <Toolbar variant="dense">
          <DatasetBadge dataset={dataset} dataCollection={dataCollection} />
          <Box mx="auto" />
          <PaginationToolbar
            pageIndex={pageIndex}
            totalItems={numberOfObjects}
            onPageIndexChange={pageIndex => this.onPageIndexChange(dataset.dataCollectionId, dataset.datasetVersionId, pageIndex)}
          />
        </Toolbar>
        { paginating &&
          <Toolbar variant="dense">
            <Skeleton width={200} height={20} />
            <Box mx="auto" />
          </Toolbar>
        }
        { !paginating &&
          <Toolbar variant="dense">
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: includeIds.length > 0 ? 'red' : 'initial'}}
                  checked={includeIds.length === pageIds.length}
                  indeterminate={includeIds.length > 0 && includeIds.length < pageIds.length}
                  onChange={evt => {
                    const group = this.selectableRef.current!;
                    if (includeIds.length === 0) group.selectAll();
                    else group.clearSelection();
                  }}
                />
              }
              label={
                <Typography variant="subtitle1" noWrap>{includeIds.length} / {pageIds.length} selected</Typography>
              }
            />
            <Box mx="auto" />
          </Toolbar>
        }
        <Box display="flex" flexDirection="column-reverse" style={{flex: '1 1 1px', overflowY: 'hidden'}} paddingX={0.5}>
          { paginating &&
            <Box style={{flex: '1 1 1px', overflowY: 'scroll'}}>
              <ImageContainer
                columns={columns}
                aspectRatio={aspectRatio}
              >
                {Array(100).fill(0).map((val, index) => (
                  <ImageTile key={index} />
                ))}
              </ImageContainer>
            </Box>
          }
          { !paginating &&
            <Box style={{flex: '1 1 1px', overflowY: 'scroll'}} { ...{ref: this.containerRef}}>
              <ImageContainer
                columns={columns}
                reverseShift
                aspectRatio={aspectRatio}
                selectable
                selectableRef={this.selectableRef}
                onSelection={tiles => {
                  this.patchDatasetVersion(dataset.datasetVersionId, pageIds, tiles.map(t => t.image!.id));
                }}>
                { objects.map(image => (
                  <ImageTile key={image.id}
                    image={image}
                    isSelected={includeIds.includes(image.id)}
                  />
                ))}
              </ImageContainer>
            </Box>
          }
        </Box>
        <Toolbar variant="dense">
          <PaginationToolbar
            pageIndex={pageIndex}
            totalItems={numberOfObjects}
          />
          <Box mx="auto" />
          <ImageZoomControl
            containerRef={this.containerRef}
            columns={columns}
            onChange={columns => {
              this.setState({ columns });
            }}
          />
        </Toolbar>
      </Box>
    )
  }
}