import React from 'react';

import {
  withRouter,
  Route,
  Link as RouterLink,
  RouteComponentProps,
  Switch
} from 'react-router-dom';

import {
  IconButton
} from '@material-ui/core';

import {
  Add as AddIcon,
} from '@material-ui/icons';

import API, {
  DataCollection,
  DataType,
} from '../api';

import {
  DataCollectionIcon,
  LabelsetIcon,
  DatasetPicker,
  DatasetManager,
  DatasetEditor,
  DatasetViewer
} from '../components';

import {
  ContentDialog,
  DebugDialog,
} from '../components';

interface Props extends RouteComponentProps {
  path: string;
  dataCollectionId?: string;
}

class State {
  dataCollection?: DataCollection;
}

export const DatasetRouter = withRouter(class extends React.Component<Props, State> {

  readonly state = new State();

  componentDidMount() {
    const { dataCollectionId } = this.props;
    if (dataCollectionId) {
      API.dataCollections.getDataCollection(dataCollectionId)
      .then(dataCollection => this.setState({ dataCollection }))
    }
  }

  render() {
    const { path, dataCollectionId } = this.props;
    const { dataCollection } = this.state;

    return (
      <Switch>
        <Route exact path={`${path}/pick`}>
          <DatasetPicker
            style={{flex: 1}}
            dataCollectionId={dataCollectionId}
          />
        </Route>
        <Route exact path={`${path}`}>
          <DatasetManager
            datasets={() => API.datasets.listDatasets({ dataCollectionId, dataType: DataType.Images })}
            renderSelection={(datasets, selection, manager) => (
              <DatasetViewer
                key={selection.datasetVersionId}
                datasetVersionId={selection.datasetVersionId}
                actions={
                  <IconButton component={RouterLink} to={`/dev/labelsets?datasetVersionId=${selection.datasetVersionId}`}><LabelsetIcon /></IconButton>
                }
                onUpdate={dataset => {
                  manager.reload(dataset);
                }}
                onDelete={dataset => manager.reload(undefined)}
              />
            )}
            renderActions={(datasets, manager)=>
              <React.Fragment>
                { dataCollectionId &&
                  <ContentDialog
                    fullscreen
                    trigger={<IconButton><AddIcon /></IconButton>}
                    renderContent={(close) => (
                      <DatasetEditor
                        dataCollectionId={dataCollectionId}
                        onCreate={dataset => {
                          manager.reload(dataset)
                        }}
                        onUpdate={dataset => {
                          manager.reload(dataset)
                        }}
                        onClose={close}
                      />
                    )}
                  />
                }
                <DebugDialog object={datasets} />
                { dataCollection &&
                  <IconButton component={RouterLink} to={`/dev/data-collections?dataSourceReferenceId=${dataCollection.dataSourceReferenceId}`}><DataCollectionIcon /></IconButton>
                }
              </React.Fragment>
            }
          />
        </Route>
      </Switch>
    );
  }
});