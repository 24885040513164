import React from 'react';

import {
  Formik,
  FormikProps,
  FormikErrors,
  Form
} from 'formik';

import {
  Divider
} from '@material-ui/core';

import {
  ParametersControlGrid
} from '../components';

import {
  TemplateInstanceInput
} from '../api';

interface Props {
  templateInstanceInput: TemplateInstanceInput;
  innerRef?: any;
  onSubmit: (input: TemplateInstanceInput) => void;
  style?: React.CSSProperties;
}

export class TemplateInstanceAdvancedForm extends React.PureComponent<Props> {

  render() {
    const { templateInstanceInput, innerRef, onSubmit, style } = this.props;
    return (
      <Formik
        innerRef={innerRef}
        initialValues={{...templateInstanceInput}}
        validate={ (values: TemplateInstanceInput) => {
          const errors: FormikErrors<TemplateInstanceInput> = {};
          Object.keys(values.settings.trainingSchema || {}).forEach(name => {
            if (`${values.settings.training[name]}` === '') {
              errors.settings = errors.settings || {};
              errors.settings.training = errors.settings.training || {};
              errors.settings.training[name] = 'Required';
            }
          });
          // Object.keys(values.settings.predictionSchema || {}).forEach(name => {
          //   if (`${values.settings.prediction[name]}` === '') {
          //     errors.settings = errors.settings || {};
          //     errors.settings.prediction = errors.settings.prediction || {};
          //     errors.settings.prediction[name] = 'Required';
          //   }
          // });
          Object.keys(values.settings.commonsSchema || {}).forEach(name => {
            if (`${values.settings.commons[name]}` === '') {
              errors.settings = errors.settings || {};
              errors.settings.commons = errors.settings.commons || {};
              errors.settings.commons[name] = 'Required';
            }
          });
          onSubmit(values);
          return errors;
        }}
        onSubmit={onSubmit}
      >
      { (props: FormikProps<TemplateInstanceInput>)  => {
        const { values, errors, handleChange } = props;

        return (
          <Form autoComplete="off" noValidate style={style}>
            <ParametersControlGrid
              title="TRAINING"
              schemas={values.settings.trainingSchema}
              values={values.settings.training}
              namePath="settings.training"
              errors={errors.settings?.training}
              onChange={handleChange}
            />
            {<Divider />}
            <ParametersControlGrid
              title="DETECTION PARAMETERS"
              schemas={values.settings.predictionSchema}
              values={values.settings.prediction}
              namePath="settings.prediction"
              errors={errors.settings?.prediction}
              onChange={handleChange}
            />
            {<Divider />}
            <ParametersControlGrid
              title="MISCELLANEOUS"
              schemas={values.settings.commonsSchema}
              values={values.settings.commons}
              namePath="settings.commons"
              errors={errors.settings?.commons}
              onChange={handleChange}
            />
          </Form>
        );
      } }
      </Formik>
    );
  }
}