import React from 'react';

import {
  Box,
  Divider,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography
} from '@material-ui/core';

import {
  Close as CloseIcon,
} from '@material-ui/icons';

import {
  DataCollection,
  Dataset,
  Labelset,
} from '../api';

import {
  DebugDialog,
  DataCollectionPicker,
  DatasetPicker,
  LabelsetPicker,
  DataCollectionIcon,
  DatasetIcon,
  LabelsetIcon,
} from '../components';

interface Props {
  aspectId: string;
  onClose?: () => void;
}

class State {
  dataCollection?: DataCollection;
  dataset?: Dataset;
  labelset?: Labelset;
  activeStep: number = 0;
}

export class AspectDataManager extends React.Component<Props, State> {

  readonly state = new State();

  selectDataCollection = (dataCollection?: DataCollection) => {
    if (!dataCollection) {
      this.setState({
        dataCollection: undefined,
        dataset: undefined,
        labelset: undefined,
     })
    } else {
      this.setState({
        dataCollection,
        dataset: undefined,
        labelset: undefined,
        activeStep: 1,
      })
    }
  }

  selectDataset = (dataset?: Dataset) => {
    if (!dataset) {
      this.setState({
        dataset: undefined,
        labelset: undefined
      });
    } else {
      this.setState({
        dataset,
        labelset: undefined,
        activeStep: 2
      })
    }
  }

  selectLabelset = (labelset?: Labelset) => {
    if (!labelset) {
      this.setState({
        labelset: undefined,
      })
    } else {
      this.setState({
        labelset
      });
    }
  }

  render() {
    const { aspectId, onClose } = this.props;
    const { activeStep, dataCollection, dataset, labelset } = this.state;

    const label = (text: string) => <Typography variant="subtitle2">{text}</Typography>
    const optionalLabel = (text?: string) => text && <Typography variant="body2" color="textSecondary" noWrap>{text}</Typography>

    return (
      <Box display="flex" flexDirection="column" flex={1} style={{overflowY: 'hidden'}}>
        <Toolbar>
          <Typography variant="h6">Manage Data</Typography>
          <Box mx={2} />
          <DebugDialog object={this.state} />
          <Box mx="auto" />
          { onClose &&
            <IconButton onClick={onClose}><CloseIcon /></IconButton>
          }
        </Toolbar>
        <Divider />
        <Box display="flex" flexDirection="row" flex={1} style={{overflowY: 'hidden'}}>
          <Box style={{width: 300}} paddingRight={2}>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              style={{background: 'inherit'}}>
              <Step>
                <StepLabel
                  icon={<DataCollectionIcon color={activeStep === 0 ? 'primary' : 'secondary'} />}
                  optional={optionalLabel(dataCollection?.name)}
                  onClick={() => {
                    this.setState({ activeStep: 0 })
                  }}
                >
                  {label('Data Collection')}
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  icon={<DatasetIcon color={activeStep === 1 ? 'primary' : dataCollection ? 'secondary' : 'disabled'} />}
                  disabled={!dataCollection}
                  optional={optionalLabel(dataset?.name)}
                  onClick={() => {
                    if (!dataCollection) return;
                    this.setState({ activeStep: 1  })
                  }}
                >
                  {label('Dataset')}
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  icon={<LabelsetIcon color={activeStep === 2 ? 'primary' : dataset ? 'secondary' : 'disabled'} />}
                  disabled={!dataset}
                  optional={optionalLabel(labelset?.name)}
                  onClick={() => {
                    if (!dataset) return;
                    this.setState({ activeStep: 2 })
                  }}
                >
                  {label('Labelset')}
                </StepLabel>
              </Step>
            </Stepper>
          </Box>
          <Divider orientation="vertical" />
          <Box display="flex" flex={1} flexDirection="column">
            {
              activeStep === 0 &&
              <DataCollectionPicker
                style={{flex: 1}}
                aspectId={aspectId}
                selection={dataCollection}
                onSelection={this.selectDataCollection.bind(this)}
              />
            }
            {
              activeStep === 1 && dataCollection &&
              <DatasetPicker
                dataCollectionId={dataCollection.id}
                style={{flex: 1}}
                selection={dataset}
                onSelection={this.selectDataset.bind(this)}
              />
            }
            {
              activeStep === 2 && dataset &&
              <LabelsetPicker
                style={{flex: 1}}
                datasetVersionId={dataset.datasetVersionId}
                selection={labelset}
                onSelection={this.selectLabelset.bind(this)}
              />
            }
          </Box>
        </Box>
      </Box>
    );
  }
}