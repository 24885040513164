import React from 'react';

import {
  List
} from '@material-ui/core';

import {
  LabelsetItem,
  LabelsetItemSkeleton
} from '../components';

import {
  Dataset,
  Labelset
} from '../api';

interface SkeletonProps {
  countItems: number;
}

interface Props {
  items: Labelset[];
  selection?: Labelset;
  dataset: Dataset;
  onSelection: (labelset: Labelset) => void;
  onUpdate: (labelset: Labelset) => void;
  onDelete: (labelset: Labelset) => void;
  style?: React.CSSProperties;
}

export class LabelsetListSkeleton extends React.Component<SkeletonProps> {
  render() {
    const { countItems } = this.props;

    return (
      <List dense disablePadding>
        { Array(countItems).fill(0).map((val, index) => (
          <LabelsetItemSkeleton key={index} />
        )) }
      </List>
    );
  }
}

export class LabelsetList extends React.Component<Props> {

  render() {
    const { items, dataset, style, selection, onSelection, onUpdate, onDelete } = this.props;

    return (
      <List dense disablePadding style={style}>
        { items.map(labelset => (
          <LabelsetItem
            key={labelset.id}
            labelset={labelset}
            dataset={dataset}
            selected={selection && selection.id === labelset.id}
            onClick={() => onSelection(labelset)}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        )) }
      </List>
    );
  }
}