import React from 'react';

import preval from 'preval.macro';

import {
  withRouter,
  RouteComponentProps,
  NavLink
} from 'react-router-dom';

import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemIcon
} from '@material-ui/core';

import {
  Menu as MenuIcon,
  PostAdd as TemplatesIcon,
  ExitToApp as LogoutIcon
} from '@material-ui/icons';

import {
  Skeleton
} from '@material-ui/lab';

import {
  AssetList,
  AppFooter,
  ErrorPage
} from '../components';

import API, {
  Asset
} from '../api';

import auth from '../auth';

interface Props extends RouteComponentProps {
  trigger: React.ReactElement;
}

class State {
  open: boolean = false;
  loading: boolean = true;
  assets: Asset[] = [];
  error?: Error;
}

export class AppDrawerSkeleton extends React.Component {

  render() {
    const lists = [ 5, 1, 1 ];
    return (
      <Box paddingY={2}>
        { lists.map((countItems, listIndex) => (
        <Box key={listIndex} marginBottom={1}>
          <List dense
            subheader={<Box padding={2} paddingTop={0}><Skeleton height={15} width="60%" /></Box>}
          >
            { Array(countItems).fill(0).map((count, itemIndex) => (
            <ListItem key={itemIndex}>
              <ListItemIcon><Skeleton height={20} width={20}/></ListItemIcon>
              <ListItemText primary={<Skeleton height={15} width="80%" />} />
            </ListItem>
            )) }
          </List>
        </Box>
        ))}
      </Box>
    );
  }
}

export const AppDrawer = withRouter(class extends React.Component<Props, State> {

  readonly state = new State();

  handleClose = () => {
    this.setState({ open: false });
  }

  handleOpen = () => {
    this.setState({ loading: true, error: undefined });
    API.assets.listAssets()
    .then((data: Asset[]) => this.setState({ loading: false, assets: data }))
    .catch((error: Error) => this.setState({ loading: false, error: error }))
  }

  render() {
    const { trigger } = this.props;
    const { open, loading, error, assets } = this.state;

    const production = preval`process.env.APP_ENV` === 'production';

    const user = auth.currentUser();

    const triggerElement = React.cloneElement(trigger, {
      onClick: () => {
        if (trigger.props.onClick)
          trigger.props.onClick();
        this.setState({ open: true });
      }
    });

    const idealState = (
      <React.Fragment>
        <AssetList
          assets={assets}
          onCreate={(asset) => {
            this.setState({ assets: [...assets, asset] });
            this.props.history.push(`/assets/${asset.id}`);
          }}
        />
        { (true || user?.groups.includes('scientist')) &&
          <List dense
            subheader={<ListSubheader disableSticky>ADMINISTRATION</ListSubheader>}
          >
            <ListItem
              button
              component={NavLink}
              to="/templates"
              activeClassName="Mui-selected"
              onClick={() => this.setState({ open: false })}
            >
              <ListItemIcon>
                <TemplatesIcon />
              </ListItemIcon>
              <ListItemText primary="Templates Management" />
            </ListItem>
          </List>
        }
        { user && !user.groups.includes('guest') &&
          <List dense
            subheader={
              <ListSubheader disableSticky style={{textTransform: 'uppercase'}}>{user.username}</ListSubheader>
            }
          >
            <ListItem
              button
              onClick={() => auth.signout()}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        }

      </React.Fragment>
    );

    return (
      <React.Fragment>
        { triggerElement }
        <Drawer
          anchor="left"
          open={open}
          onClose={this.handleClose}
          onRendered={this.handleOpen}
          PaperProps={{style: { display: 'flex', overflowY: 'hidden'}}}
        >
          <AppBar
            color={production ? 'secondary' : 'primary'}
            position="static"
          >
            <Toolbar>
              <IconButton edge="start" color="inherit"
                disableFocusRipple
                onClick={this.handleClose}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                Visual Quality Inspection
              </Typography>
            </Toolbar>
          </AppBar>
          <Box style={{flex: '1 1 1px', overflowY: 'scroll'}}>
            {
              error
              ? <ErrorPage message={error.message} onReload={() => this.handleOpen()} />
              : loading
                ? <AppDrawerSkeleton />
                : idealState
            }
          </Box>
          <AppFooter />
        </Drawer>
      </React.Fragment>
    );
  }
})