import React from 'react';

import {
  withRouter,
  RouteComponentProps
} from 'react-router-dom';

import {
  Box,
  Typography,
  Container,
  Toolbar,
  Button,
  Tooltip
} from '@material-ui/core';

import {
  Add as AddIcon
} from '@material-ui/icons';

import {
  Skeleton
} from '@material-ui/lab';

import API, {
  Asset,
  Aspect,
  AspectInput
} from '../api';

import {
  filterAspectsByText
} from '../utils';

import {
  AppTopBar,
  AssetMenu,
  AspectGrid,
  AspectGridSkeleton,
  AspectForm,
  AppLink,
  AppLinkSkeleton,
  ErrorPage,
  FeedbackToast,
  ResourceEditDialog,
  SearchField
} from '../components';

interface Params {
  assetId: string;
}

interface Props extends RouteComponentProps<Params> {}

class State {
  asset?: Asset;
  aspects: Aspect[] = [];
  loading: boolean = true;
  error?: Error;
  search: string = '';
  feedback?: string;
}

export const AssetPage = withRouter(class extends React.Component<Props, State> {

  readonly state = new State();

  componentDidMount() {
    const { params } = this.props.match;
    this.setState(new State());
    Promise.all([
      API.assets.getAsset(params.assetId),
      API.aspects.listAspects({ assetId: params.assetId })
    ])
    .then(([asset, aspects]) => {
      this.setState({ loading: false, asset, aspects });
    })
    .catch(error => {
      this.setState({ loading: false, error });
    })
  }

  render() {
    const { loading, error, asset, aspects, search, feedback } = this.state;

    if (loading) {
      return (
        <React.Fragment>
          <AppTopBar>
            <AppLinkSkeleton />
          </AppTopBar>
          <Container>
            <Toolbar disableGutters>
              <Skeleton width={100} height={20} />
            </Toolbar>
            <Box p={1}>
              <AspectGridSkeleton countAspects={4} />
            </Box>
          </Container>
        </React.Fragment>
      );
    } else if (error || !asset) {
      return <ErrorPage message={error?.message} onReload={() => this.componentDidMount()} />
    } else {
      const filteredAspects = filterAspectsByText(aspects, search);

      return (
        <React.Fragment>
          <AppTopBar>
            <AppLink name={asset.name} href={`/assets/${asset.id}`} />
            <Box mx='auto'/>
            <AssetMenu asset={asset}
              onUpdate={(asset) => {
                this.setState({
                  asset: asset,
                  feedback: `Production Line "${asset.name}" updated.`
                })
              }}
              onDelete={(asset) => {
                this.setState({
                  feedback: `Production Line "${asset.name}" deleted.`
                })
                this.props.history.push('/assets')
              }}
            />
          </AppTopBar>
          <Container>
            <Toolbar disableGutters>
              <Typography variant="h6" noWrap>
                Stations
              </Typography>
              <Box mx={2} />
              <ResourceEditDialog<AspectInput, Aspect>
                title="Add Station"
                resource={{assetId: asset.id, name: '', description: '', attributes: { devices: [] } }}
                trigger={<Tooltip title="Add Station"><Button startIcon={<AddIcon />} color="primary">Add</Button></Tooltip>}
                action={aspect => API.aspects.createAspect(aspect) }
                onSuccess={aspect => {
                  this.setState({
                    search: '',
                    aspects: [aspect, ...aspects],
                    feedback: `Station "${aspect.name}" added.`
                  });
                }}
                renderForm={(aspectInput, ref, handleSubmit) => (
                  <AspectForm
                    aspectInput={aspectInput}
                    innerRef={ref}
                    onSubmit={handleSubmit} />
                )}
              />
              <Box mx="auto" />
              { aspects.length > 0 &&
                <SearchField
                  value={search}
                  onChange={search => this.setState({ search })}
                />
              }
            </Toolbar>
            <Box display="flex" flexDirection="column" flex={1} style={{overflowY: 'auto'}}>
              <Box p={2}>
                { aspects.length ? (
                  <React.Fragment>
                    <AspectGrid aspects={filteredAspects}
                      onUpdate={aspect => {
                        this.setState({
                          search: '',
                          aspects: aspects.map(a => a.id === aspect.id ? aspect : a),
                          feedback: `Station "${aspect.name}" updated.`
                        })
                      }}
                      onDelete={aspect => {
                        this.setState({
                          search: '',
                          aspects: aspects.filter(a => a.id !== aspect.id),
                          feedback: `Station "${aspect.name}" deleted.`
                        })
                      }}
                    />
                    <Box p={1} my={1}>
                      <Typography align="center" variant="body2" color="textSecondary">Showing {filteredAspects.length} out of {aspects.length} results</Typography>
                    </Box>
                </React.Fragment>
                ) : (
                  <Typography variant="subtitle1">
                    No stations yet.
                  </Typography>
                )}
              </Box>
            </Box>
            <FeedbackToast key={feedback} message={feedback} onHide={() => this.setState({ feedback: undefined })} />
          </Container>
        </React.Fragment>
      )
    }
  }
});