import React from 'react';

import {
  Grid
} from '@material-ui/core';

import {
  AspectCard,
  AspectCardSkeleton
} from '../components';

import {
  Aspect
} from '../api';

interface SkeletonProps {
  countAspects: number;
}

interface Props {
  aspects: Aspect[];
  onUpdate: (aspect: Aspect) => void;
  onDelete: (aspect: Aspect) => void;
}

export class AspectGridSkeleton extends React.Component<SkeletonProps> {

  render() {
    const { countAspects } = this.props;

    return (
      <Grid
          spacing={3}
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
        { Array(countAspects).fill(0).map((val, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <AspectCardSkeleton />
          </Grid>
        )) }
      </Grid>
    );
  }
}

export class AspectGrid extends React.Component<Props> {

  render() {

    const { aspects, onUpdate, onDelete } = this.props;

    return (
      <React.Fragment>
        <Grid
          spacing={3}
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
        { aspects.sort((a, b) => a.name.localeCompare(b.name)).map((aspect) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={aspect.id}>
            <AspectCard
              aspect={aspect}
              href={`/assets/${aspect.assetId}/aspects/${aspect.id}`}
              onUpdate={onUpdate}
              onDelete={onDelete} />
          </Grid>
        )) }
        </Grid>
      </React.Fragment>
    );
  }
}