import React from 'react';

import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Avatar
} from '@material-ui/core';

import {
  Error as ErrorIcon,
} from '@material-ui/icons';

import {
  Skeleton
} from '@material-ui/lab';

import API, {
  DataCollectionObject,
  Dataset
} from '../api';

import {
  DatasetBadge,
  DatasetIcon,
  DatasetMenu
} from '../components';

interface Props  {
  dataset: Dataset;
  selected?: boolean;
  onClick: () => void;
  onUpdate: (dataset: Dataset) => void;
  onDelete: (dataset: Dataset) => void;
}

class State {
  objects?: Array<DataCollectionObject>;
  loading: boolean = true;
  error?: Error;
}

export class DatasetItem extends React.Component<Props, State> {

  readonly state = new State();

  componentDidMount() {
    const { dataset } = this.props;
    API.datasets.getDatasetVersionObjects(dataset.datasetVersionId, { take: 10 })
    .then(objects => this.setState({ loading: false, objects }))
    .catch(error => this.setState({ loading: false, error }))
  }

  render() {
    const { dataset, selected, onClick, onUpdate, onDelete } = this.props;
    const { objects, loading } = this.state;

    const avatarStyle = { height: 45, width: 45, backgroundColor: '#E3E3E3' };

    return (
      <React.Fragment>
        <ListItem
          style={{paddingLeft: 16}}
          button
          selected={selected}
          onClick={() => onClick()}
        >
          <ListItemAvatar>
            { loading
              ? <Skeleton style={avatarStyle} variant="rect"  />
              : (objects && objects.length > 0)
              ? <Avatar style={avatarStyle} variant="square" src={objects[0].attributes.previewUrl} />
              : (objects && objects.length === 0)
              ? <Avatar style={avatarStyle} variant="square"><DatasetIcon /></Avatar>
              : <Avatar style={avatarStyle} variant="square" ><ErrorIcon /></Avatar>
            }
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography noWrap variant="subtitle1">
                { dataset.name }
                <DatasetBadge dataset={dataset} style={{marginLeft: 8}} />
              </Typography>
            }
            secondary={
              <Typography noWrap variant="body2" color="textSecondary">
                { dataset.description || '-' }
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            <DatasetMenu
              dataset={dataset}
              onUpdate={onUpdate}
              onDelete={onDelete}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </React.Fragment>
    );
  }
}