import { Deployment, DeploymentStage, Metric } from '../models';

import HTTPClient from '../http-client';

export class Deployments extends HTTPClient {

  getDeployments(query: { stage?: DeploymentStage, dataSourceReferenceId?: string }): Promise<Array<Deployment>> {
    return this.request({
      method: 'get',
      path: `/deployments`,
      query
    })
  }

  getDeployment(deploymentId: string): Promise<Deployment> {
    return this.request({
      method: 'get',
      path: `/deployments/${deploymentId}`
    })
  }

  getDeploymentMetrics(deploymentId: string): Promise<Array<Metric>> {
    return this.request({
      method: 'get',
      path: `/deployments/${deploymentId}/metrics`
    });
  }
}