import React from 'react';

import {
  Chip
} from '@material-ui/core';

import {
  LabelDefinition,
  LabelType
} from '../api';

import {
  lookupLabelDefinitionAndColor
} from '../utils';

interface Props {
  labelDefinitions: Array<LabelDefinition>;
  labelId: number;
  labelType?: LabelType;
  disabled?: boolean;
  outlined?: boolean;
  style?: React.CSSProperties;
}

export class LabelDefinitionChip extends React.Component<Props> {

  render() {
    const { labelDefinitions, labelId, style, disabled, outlined } = this.props;

    const [ label, color ] = lookupLabelDefinitionAndColor(labelDefinitions, labelId);

    const styles = {
      ...style,
      backgroundColor: outlined ? undefined : color,
      opacity: disabled ? 0.7 : 1,
      color: outlined ? color : '#FFF',
      height: 20,
      lineHeight: 1,
      fontSize: 12,
      fontWeight: 600
    };

    return (
      <Chip
        variant={outlined ? 'outlined' : 'default'}
        style={styles}
        size="small"
        label={label.name}
      />
    );
  }
}