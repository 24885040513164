import React from 'react';

import {
  Route,
  Switch
} from 'react-router-dom';

import {
  Box
} from '@material-ui/core';

import {
  DevicesList,
  DevicesListEditable
} from '../components';

import API, {
  Aspect,
  Device
} from '../api';

interface Props {
  path: string;
  aspectId: string;
}

class State {
  aspect?: Aspect;
  devices?: Device[];
}

export class AspectRouter extends React.Component<Props, State> {

  readonly state = new State();

  componentDidMount() {
    API.aspects.getAspect(this.props.aspectId)
    .then(aspect => this.setState({ aspect, devices: aspect.attributes.devices }));
  }

  render() {
    const { path } = this.props;
    const { aspect, devices } = this.state;

    if (!aspect || !devices)
      return null;

    return (
      <Switch>
        <Route path={`${path}/${aspect.id}/devices`}>
          <Box display="flex" flexDirection="row">
            <DevicesList devices={devices} style={{flex: 1}}/>
            <DevicesListEditable value={devices} error onChange={devices => this.setState({ devices })}  style={{flex: 1}} />
            <pre style={{flex: 1}}>
              { JSON.stringify(devices, null, 2) }
            </pre>
          </Box>
        </Route>
      </Switch>
    );
  }
}