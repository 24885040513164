import React from 'react';

import {
  SelectableGroup
} from 'react-selectable-fast'

import {
  TRBLLabel
} from '../api';

import {
  ImageTileProps
} from './ImageTile';

import {
  computeAspectRatio
} from '../utils';

interface Props {
  columns?: number;
  cropBox?: TRBLLabel;
  aspectRatio?: number;
  selectable?: boolean;
  selectableRef?: React.RefObject<SelectableGroup>;
  onSelection?: (tiles: Array<ImageTileProps>) => void;
  reverseShift?: boolean;
  noWrap?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

class State {
  shiftKey: boolean;

  constructor (props: Props) {
    this.shiftKey = props.reverseShift || false;
  }
}

export class ImageContainer extends React.Component<Props, State> {

  readonly state = new State(this.props);

  componentDidMount() {
    document.addEventListener("keyup", this.handleKeyDown);
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleKeyDown);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (evt: KeyboardEvent) => {
    const { shiftKey } = evt;
    this.setState({ shiftKey: this.props.reverseShift ? !shiftKey : shiftKey })
  }

  render() {
    const { children, cropBox, selectable, selectableRef, onSelection, noWrap, columns = 1, style } = this.props;
    const { shiftKey } = this.state;

    const aspectRatio = computeAspectRatio(this.props.aspectRatio || 0, cropBox);

    return (
      <SelectableGroup
        allowShiftClick
        resetOnStart={!shiftKey}
        enableDeselect={shiftKey}
        mixedDeselect={shiftKey}
        selectOnClick={true}
        disabled={!selectable}
        onSelectionFinish={(items: React.ReactElement<ImageTileProps>[]) => {
          if (onSelection) {
            onSelection(items.map(item => item.props));
          }
        }}
        ref={selectableRef}
        style={style}
      >
        <ul
          style={{
            display: "flex",
            padding: 0,
            flexWrap: noWrap ? 'nowrap' : 'wrap',
            overflowY: "auto",
            overflowX: 'hidden',
            margin: 0
          }}
        >
        {
          React.Children.map(children, (child) => {
          if (!React.isValidElement(child)) {
            return null;
          }
          return React.cloneElement(child, {
            format: 'previewUrl',
            selectable,
            cropBox,
            onLoad: (img: HTMLImageElement) => {
            },
            style: {
              width: `${100/columns}%`,
              paddingTop: `${100/columns/aspectRatio}%`
            },
          });
        }) }
      </ul>
      </SelectableGroup>
    );
  }
}