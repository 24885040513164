import React from 'react';

import moment from 'moment';

import {
  withTheme,
  Theme
} from '@material-ui/core/styles';

import {
  Avatar,
  Box,
  Collapse,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography
} from '@material-ui/core';

import {
  ExpandMore as ExpandIcon,
  ExpandLess as CollapseIcon
} from '@material-ui/icons';

import {
  Skeleton
} from '@material-ui/lab';

import API, {
  Template
} from '../api';

import {
  LabelTypeAvatar,
  LabelTypeBadge,
  TemplateIcon
} from '../components';

interface Props  {
  theme: Theme,
  template: Template;
  selection?: Template;
  onSelection: (version: Template) => void;
  showVersions?: boolean;
}

class State {
  collapsed: boolean = true;
  versions?: Array<Template>;
}

export class TemplateItemSkeleton extends React.Component {
  render() {
    return (
      <ListItem style={{paddingTop: 8, paddingBottom: 8}}>
        <ListItemAvatar>
          <Skeleton variant="circle" height={40} width={40} />
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton height={20} width={150} />}
          secondary={<Skeleton height={15} width={200} />}
        />
      </ListItem>
    );
  }
}

export const TemplateItem = withTheme(class extends React.Component<Props> {

  readonly state = new State();

  componentDidMount() {
    const { template, showVersions } = this.props;
    if (showVersions) {
      API.templates.getTemplateVersions(template.id)
      .then(versions => this.setState({ versions }))
    }
  }

  render() {
    const { template, selection, onSelection, theme } = this.props;
    const { collapsed, versions } = this.state;

    const hasVersions = versions && versions.length > 1;
    const selected = template.id === selection?.id;
    const item = selected ? selection : template;

    const { labelType } = template.settings.labelSettings.labelConfiguration;

    return (
      <React.Fragment>
        <ListItem
          style={{paddingLeft: 16}}
          button
          selected={selected}
          onClick={() => onSelection(item)}
        >
          <ListItemAvatar>
            <LabelTypeAvatar
              labelType={labelType}
              selected={selected}
            >
              <Avatar
                variant="circle"
                style={{backgroundColor: selected ? theme.palette.primary.main : undefined, width: 40, height: 40}}>
                <TemplateIcon fontSize="small" />
              </Avatar>
            </LabelTypeAvatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography noWrap variant="subtitle1">
                {item.name}
                <Box mx={1} component="span" />
                <LabelTypeBadge labelType={labelType} />
              </Typography>
            }
            secondary={
              <Typography noWrap variant="body2" color="textSecondary">
                {`v${item.version}`} - { item.description || 'no description' }
              </Typography>
            }
          />
          { hasVersions &&
            <ListItemSecondaryAction>
              <IconButton onClick={() => this.setState({ collapsed: !collapsed})}>
                { collapsed ? <ExpandIcon /> : <CollapseIcon /> }
              </IconButton>
            </ListItemSecondaryAction>
          }
        </ListItem>
        { hasVersions &&
          <Collapse in={!collapsed} timeout="auto" unmountOnExit>
            { versions
              .filter(v => v.templateVersionId !== item.templateVersionId)
              .sort((a, b) => moment(b.createdAt).diff(a.createdAt))
              .map(version => (
                <ListItem button onClick={() => onSelection(version)}>
                  <ListItemAvatar><div/></ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography noWrap variant="body2">
                        {`v${version.version}`} - { version.description || 'no description' }
                      </Typography>
                    }
                  />
                </ListItem>
              ))
            }
          </Collapse>
        }
      </React.Fragment>
    );
  }
});