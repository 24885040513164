import { Writeonly } from './utils';

import { Source } from './aspects';

export enum DeploymentStatus {
  Preparing = 'preparing',
  Running = 'running',
  Failed = 'failed',
  Finished = 'finished',
  Stopped = 'stopped'
}

export enum DeploymentStage {
  Training = 'training',
  Prediction = 'prediction',
  Evaluation = 'evaluation'
}

export enum DeploymentStaticDataInputType {
  TrainingSet = 'training_set',
  ValidationSet = 'validation_set',
  TestSet = 'test_set'
}

export type DeploymentStaticDataInput = {
  type: 'DeploymentStaticDataInput';
  dataInputType: DeploymentStaticDataInputType;
  datasetVersionId: string;
  labelsetId?: string;
}

export type DeploymentArtifactInput = {
  type: 'DeploymentArtifactInput',
  deployment_artifact_id: string;
}

export type DeploymentDynamicDataInput = {
  type: 'DeploymentDynamicDataInput';
  source: Source;
}

export type DeploymentDataOutput = {
  readonly artifact?: {
    path: string;
  };
  readonly createdAt: string;
  readonly updatedAt: string;
}

export type Deployment = {
  readonly id: string;
  readonly templateInstanceVersionId: string;
  runtimeId?: string;
  stage: DeploymentStage;
  readonly status: DeploymentStatus;
  readonly output?: DeploymentDataOutput;
  inputs?: Array<DeploymentArtifactInput | DeploymentDynamicDataInput | DeploymentStaticDataInput>;
  readonly createdAt: string;
  readonly updatedAt: string;
}

export type DeploymentInput = Writeonly<Deployment>;