import React from 'react';

import {
  NavLink,
  LinkProps
} from 'react-router-dom';

import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography
} from '@material-ui/core';

import {
  Folder as AssetIcon
} from '@material-ui/icons';

import {
  Asset
} from '../api';

interface Props {
  asset: Asset;
  href: string;
}

export class AssetItem extends React.Component<Props> {

  render() {
    const { asset, href } = this.props;

    return (
      <ListItem
        button
        component={
          React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>((props, ref) => (
            <NavLink to={href} activeClassName="Mui-selected" {...props} ref={ref} />
          )
        )}
      >
        <ListItemIcon><AssetIcon /></ListItemIcon>
        <ListItemText
          primary={<Typography noWrap variant="body2">{asset.name}</Typography>}
        />
      </ListItem>
    );
  }
}