import { TemplateInstance, TemplateInstanceInput, Deployment, DeploymentInput } from '../models';

import HTTPClient from '../http-client';

import { pick } from 'lodash';

export class TemplateInstances extends HTTPClient {

  listTemplateInstances(query: { dataSourceReferenceId?: string }): Promise<Array<TemplateInstance>> {
    return this.request({
      method: 'get',
      path: `/template-instances`,
      query
    })
  }

  createTemplateInstance(input: TemplateInstanceInput): Promise<TemplateInstance> {
    return this.request({
      method: 'post',
      path: `/template-instances`,
      body: pick(input, 'templateId', 'dataSourceReferenceId', 'name', 'description', 'settings', 'additionalAttributes', 'dataInputs')
    })
  }

  getTemplateInstance(templateInstanceId: string): Promise<TemplateInstance> {
    return this.request({
      method: 'get',
      path: `/template-instances/${templateInstanceId}`
    })
  }

  updateTemplateInstance(templateInstanceId: string, input: TemplateInstanceInput): Promise<TemplateInstance> {
    return this.request({
      method: 'put',
      path: `/template-instances/${templateInstanceId}/version`,
      body: pick(input, 'templateId', 'name', 'description', 'settings', 'additionalAttributes', 'dataInputs')
    })
  }

  deleteTemplateInstance(templateInstanceId: string): Promise<void> {
    return this.request({
      method: 'delete',
      path: `/template-instances/${templateInstanceId}`
    })
  }

  getTemplateInstanceDeployments(templateInstanceId: string): Promise<Array<Deployment>> {
    return this.request({
      method: 'get',
      path: `/template-instances/${templateInstanceId}/deployments`
    })
  }

  startTemplateInstanceDeployment(templateInstanceId: string, input: DeploymentInput): Promise<Deployment> {
    return this.request({
      method: 'post',
      path: `/template-instances/${templateInstanceId}/deployments`,
      body: {
        ...pick(input, 'stage', 'runtimeId', 'inputs')
      }
    })
  }

  stopTemplateInstanceDeployment(templateInstanceId: string, deploymentId: string): Promise<Deployment> {
    return this.request({
      method: 'delete',
      path: `/template-instances/${templateInstanceId}/deployments/${deploymentId}`
    })
  }
}