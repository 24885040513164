import React from 'react';

import {
  List
} from '@material-ui/core';

import {
  TemplateInstanceItem,
  TemplateInstanceItemSkeleton,
  TemplateInstanceItemGroup,
  TemplateInstanceItemGroupSkeleton
} from '../components';

import {
  TemplateInstance,
  Aspect
} from '../api';

import {
  groupTemplateInstances
} from '../utils';

interface SkeletonProps {
  countGroups: number;
  countTemplateInstances: number;
}

interface Props {
  aspect: Aspect;
  templateInstances: TemplateInstance[];
  searchText?: string;
  style?: React.CSSProperties;
}

export class TemplateInstanceListSkeleton extends React.Component<SkeletonProps> {
  render() {
    const { countGroups, countTemplateInstances } = this.props;
    return (
      <List dense disablePadding>
        { Array(countGroups).fill(0).map((val, index) => (
        <React.Fragment key={index}>
          <TemplateInstanceItemGroupSkeleton />
          { Array(countTemplateInstances).fill(0).map((val, index2) => (
            <TemplateInstanceItemSkeleton key={index2} />
          )) }
        </React.Fragment>
        )) }
      </List>
    );
  }
}

export class TemplateInstanceList extends React.Component<Props> {

  render() {
    const { aspect, templateInstances, searchText, style } = this.props;

    const templateInstancesMap = groupTemplateInstances(templateInstances, searchText);

    return (
      <List dense disablePadding style={style}>
        { Array.from(templateInstancesMap.entries()).map(([group, templateInstances], index) => (
          <React.Fragment key={index}>
            <TemplateInstanceItemGroup
              name={group || 'Draft'}
              countTemplateInstances={templateInstances.length}
            />
            { templateInstances.map(templateInstance => (
              <TemplateInstanceItem
                key={templateInstance.id}
                templateInstance={templateInstance}
                href={`/assets/${aspect.assetId}/aspects/${aspect.id}/templateInstances/${templateInstance.id}`}
              />
            )) }
          </React.Fragment>
        ))}
      </List>
    );
  }
}