import React from 'react';

import ReactJson from 'react-json-view';

import axios, {
  AxiosError
} from 'axios';

import {
  serializeError
} from 'serialize-error';

import {
  Link,
  Box,
  Divider,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core';

import {
  Code as CodeIcon,
  Close as CloseIcon,
  Launch as OpenIcon
} from '@material-ui/icons';

import {
  ContentDialog
} from '../components';

interface Props {
  object: any;
  title?: string;
  small?: boolean;
}

export class DebugDialog extends React.Component<Props> {

  render() {
    const { object, title, small } = this.props;

    const disabled = window.location.hostname !== 'localhost';
    if (disabled)
      return null;

    if (axios.isAxiosError(object)) {
      const error = object as AxiosError;
      const { method, url: path, data: body } = error.config;
      const debugURL = `${window.location.origin}/dev/query`
          + `?method=${method}`
          + ( path ? `&path=${encodeURIComponent(path)}` : '' )
          + ( body ? `&body=${encodeURIComponent(body)}` : '' );

      return (
        <Link href={debugURL} target="_blank">
          <IconButton size={small ? 'small' : 'medium'}>
            <OpenIcon fontSize={small ? 'small' : 'default' }/>
          </IconButton>
        </Link>
      );
    }

    const json = axios.isAxiosError(object) ? serializeError(object as AxiosError) : object;

    return (
      <ContentDialog
        size={ small ? 'sm' : 'md' }
        trigger={
          <IconButton size={small ? 'small' : 'medium'}>
            <CodeIcon fontSize={small ? 'small' : 'default' }/>
          </IconButton>
        }
        renderContent={(close) => (
          <React.Fragment>
            <Toolbar variant={small ? 'dense' : undefined}>
              <Typography variant="h6">{ title || 'Debug'}</Typography>
              <Box mx="auto" />
              <IconButton onClick={close} size={small ? 'small' : 'medium'}>
                <CloseIcon fontSize={small ? 'small' : 'default' } />
              </IconButton>
            </Toolbar>
            <Divider />
            <Box style={{flex: '1 1 1px', overflowY: 'scroll'}} p={2}>
              <ReactJson src={json}
                displayDataTypes={false}
                displayObjectSize={false}
                name={false}
                enableClipboard={false}
                collapsed={false}
              />
            </Box>
          </React.Fragment>
        )}
      />
    )
  }
}