import React from 'react';

import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  IconButton,
  Divider,
  Tooltip
} from '@material-ui/core';

import {
  Airplay as DeviceIcon,
  CameraAlt as SourceIcon,
  FileCopyOutlined as CopyIcon
} from '@material-ui/icons';

import {
  Device,
  Deployment,
  DeploymentInput,
  DeploymentDynamicDataInput,
  Source
} from '../api';

import {
  filterDeploymentDynamicDataInput
} from '../utils';

interface Props {
  devices: Device[];
  filter?: Deployment;
  deploymentInput?: DeploymentInput;
  onChange?: (value: DeploymentInput) => void;
  style?: React.CSSProperties;
}

export class DevicesList extends React.Component<Props> {

  handleSelection = (device: Device, source: Source) => {
    const { deploymentInput, onChange } = this.props;
    if (deploymentInput) {
      const { stage } = deploymentInput;
      const sourcesInputs = filterDeploymentDynamicDataInput(deploymentInput);

      let newOptions;
      if (deploymentInput.runtimeId === device.id) {
        // remove existing input from selected device
        if (sourcesInputs.find(d => d.source.id === source.id)) {
            newOptions = {
              stage,
              runtimeId: sourcesInputs.length > 1 ? device.id : undefined,
              inputs: [
                ...sourcesInputs.filter(s => s.source.id !== source.id)
              ]
            };
        } else {
          // add input to selected device
          newOptions = {
            stage,
            runtimeId: device.id,
            inputs: [
              ...sourcesInputs,
              {
                type: 'DeploymentDynamicDataInput',
                source
              } as DeploymentDynamicDataInput
            ]
          };
        }
      } else {
        // start new selection under new device
        newOptions = {
          stage,
          runtimeId: device.id,
          inputs: [
            {
              type: 'DeploymentDynamicDataInput',
              source
            } as DeploymentDynamicDataInput
          ]
        };
      }
      if (onChange)
        onChange(newOptions);
    }
  }

  render() {
    const { devices, filter, deploymentInput, style } = this.props;

    const sourcesInputs = filterDeploymentDynamicDataInput(deploymentInput);

    return (
      <List dense style={style}>
        { devices
            .filter(d => !filter || d.id === filter.runtimeId)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((device) => (
          <React.Fragment key={device.id}>
            { !filter && <Divider /> }
            <ListItem dense>
              <ListItemIcon style={{minWidth: 35}}>
                <DeviceIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={<Typography noWrap variant="body1">{device.name}</Typography>}
                secondary={<Typography noWrap variant="caption" color="textSecondary">{device.id}</Typography>}
              />
              <ListItemSecondaryAction>
                <Tooltip title={`Copy Device Runtime ID '${device.id}'`}>
                  <IconButton size="small" onClick={evt => {
                    navigator.clipboard.writeText(device.id);
                  }}>
                    <CopyIcon fontSize="small"/>
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
            { device.sources
                .filter(s => !filter || sourcesInputs.find(i => i.source.id === s.id))
                .sort((a, b) => a.name.localeCompare(b.name)).map((source) => (
              <ListItem key={source.id}
                dense
                alignItems="flex-start"
                selected={!!sourcesInputs.find(d => d.source.id === source.id)}
                onClick={evt=> this.handleSelection(device, source)}
                style={{ paddingLeft: 50, cursor: deploymentInput ? 'pointer' : 'inherit'}}>
                <ListItemIcon style={{minWidth: 35}}>
                  <SourceIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography noWrap variant="body1">{source.name}</Typography>}
                  secondary={
                    <Box>
                      { source.topic && <Typography noWrap variant="body2" color="textPrimary">{source.topic}</Typography> }
                      <Typography noWrap variant="caption" color="textSecondary">{source.id}</Typography>
                    </Box>
                  }
                />
                <ListItemSecondaryAction>
                  <Tooltip title={`Copy Source ID '${source.id}'`}>
                    <IconButton
                      size="small"
                      onClick={evt => navigator.clipboard.writeText(source.id) }>
                      <CopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </React.Fragment>
        )) }
      </List>
    );
  }
}