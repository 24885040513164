import { AxiosError } from 'axios';

const ERROR_CODE_MESSAGES: Record<string, [string, string]> = {
  'General.PendingReference': [
    "Resource Locked",
    "Resource is already in use hence cannot be modified."
  ],
  'Template.AlreadyReferenced': [
    "Template Locked",
    "Template is already in use hence cannot be modified."
  ],
}

export function lookupErrorNameAndMessage(error: Error | AxiosError): [string, string] {
  let { name, message } = error;
  if (error.hasOwnProperty('config')) {
    const data = (error as AxiosError).response?.data || {};
    const { code } = data;
    if (ERROR_CODE_MESSAGES.hasOwnProperty(code)) {
      [ name, message ] = ERROR_CODE_MESSAGES[code];
    } else if (code) {
      name = code;
      message = data.message
    }
  }
  return [ name, message ];
}