import React from 'react';

import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Avatar
} from '@material-ui/core';

import {
  Error as ErrorIcon,
} from '@material-ui/icons';

import {
  Skeleton
} from '@material-ui/lab';

import API, {
  DataCollection,
  DataCollectionObject
} from '../api';

import {
  DataCollectionBadge,
  DataCollectionIcon,
  DataCollectionMenu
} from '../components';

interface Props  {
  item: DataCollection;
  selected?: boolean;
  onClick: () => void;
  onUpdate: (dataCollection: DataCollection) => void;
  onDelete: (dataCollection: DataCollection) => void;
}

class State {
  objects?: Array<DataCollectionObject>;
  loading: boolean = true;
  error?: Error;
}

const avatarStyle = { height: 45, width: 45, backgroundColor: '#E3E3E3' };

export class DataCollectionItemSkeleton extends React.Component {

  render() {
    return (
      <ListItem style={{paddingTop: 8, paddingBottom: 8}}>
        <ListItemAvatar>
          <Skeleton style={avatarStyle} variant="rect"  />
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton height={20} width={150} />}
          secondary={<Skeleton height={15} width={200} />}
        />
      </ListItem>
    );
  }
}

export class DataCollectionItem extends React.Component<Props, State> {

  readonly state = new State();

  componentDidMount() {
    const dataCollectionId = this.props.item.id;

    this.setState({ loading: true, error: undefined })
    API.dataCollections.getDataCollectionObjects(dataCollectionId, { take: 10 })
    .then(objects => this.setState({ loading: false, objects }))
    .catch(error => this.setState({ loading: false, error }))
  }

  render() {
    const { item, selected, onClick, onUpdate, onDelete } = this.props;
    const { objects, loading } = this.state;

    return (
      <React.Fragment>
        <ListItem
          style={{paddingLeft: 16}}
          button
          selected={selected}
          onClick={() => onClick()}
        >
          <ListItemAvatar>
            { loading
              ? <Skeleton style={avatarStyle} variant="rect"  />
              : (objects && objects.length > 0)
              ? <Avatar style={avatarStyle} variant="square" src={objects[0].attributes.previewUrl} />
              : (objects && objects.length === 0)
              ? <Avatar style={avatarStyle} variant="square"><DataCollectionIcon /></Avatar>
              : <Avatar style={avatarStyle} variant="square" ><ErrorIcon /></Avatar>
            }
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography noWrap variant="subtitle1">
                { item.name }
                <DataCollectionBadge dataCollection={item} style={{marginLeft: 8}} />
              </Typography>
            }
            secondary={
              <Typography noWrap variant="body2" color="textSecondary">
                { item.description || '-' }
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            <DataCollectionMenu
              dataCollection={item}
              onUpdate={onUpdate}
              onDelete={onDelete}/>
          </ListItemSecondaryAction>
        </ListItem>
      </React.Fragment>
    );
  }
}