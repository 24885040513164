import React from 'react';

import {
  LabelConfiguration,
  LabelsetEntry,
  LabelDefinition,
  PolygonLabel,
  TRBLLabel,
} from '../api';

import {
  LabelDefinitionChip
} from '../components';

import {
  lookupLabelDefinitionAndColor
} from '../utils/labelsets';

export interface Props {
  cropBox?: TRBLLabel;
  labelConfiguration?: LabelConfiguration;
  labelsetEntry: LabelsetEntry;
  standalone?: boolean;
}

class State {
  loading: boolean = true;
}

export class ImageTileOverlay extends React.Component<Props, State> {

  readonly state = new State();

  private createBoundingBox(bounds: TRBLLabel, color: string, cropBox: TRBLLabel | undefined, key: number | undefined): any {

    const crop = cropBox || { left: 0, top: 0, right: 1, bottom: 1};

    const scaleX = 1 / (crop.right - crop.left);
    const scaleY = 1 / (crop.bottom - crop.top);

    const x = (bounds.left - crop.left) * scaleX;
    const y = (bounds.top - crop.top) * scaleY;
    const width = (bounds.right - (bounds.right < bounds.left ? 0 : bounds.left)) * scaleX;
    const height = (bounds.bottom - (bounds.bottom < bounds.top ? 0 : bounds.top)) * scaleY;

    const style = {
      fill: color, fillOpacity: 0.2,
      stroke: color, strokeWidth: 1, strokeOpacity: 1
    }

    return (
      <rect
        key={key}
        x={`${x * 100}%`}
        y={`${y * 100}%`}
        width={`${width * 100}%`}
        height={`${height * 100}%`}
        style={style} />
    );
  }

  private createSvgPolygon(label: PolygonLabel, color: string, cropBox: TRBLLabel | undefined, key: number | undefined): any {

    const crop = cropBox || { left: 0, top: 0, right: 1, bottom: 1};

    const scaleX = 1 / (crop.right - crop.left);
    const scaleY = 1 / (crop.bottom - crop.top);

    const points = label.points?.map(p => {
      const x = (p.x - crop.left) * scaleX;
      const y = (p.y - crop.top) * scaleY;
      return [x * 100, y * 100].join(',')
    }).join(' ');

    const style = {
      fill: color, fillOpacity: 0.2,
      stroke: color, strokeWidth: 1, strokeOpacity: 1
    }

    return (
      <polygon
        key={key}
        points={points}
        style={style} />
    );
  }

  render() {
    const { labelsetEntry, labelConfiguration, cropBox, standalone } = this.props;

    const boundingBoxes = new Array<{label: TRBLLabel, color: string}>();
    const polygons = new Array<{label: PolygonLabel, color: string}>();
    const singleLabels = new Array<LabelDefinition>();

    if (labelConfiguration) {
      const { labelDefinitions } = labelConfiguration;

      labelsetEntry.labels.forEach(l => {
        const [ label, color ] = lookupLabelDefinitionAndColor(labelDefinitions, l.labelId);

        if (l.type === 'TRBLLabel') {
          boundingBoxes.push({
            label: l.labelAnnotation as TRBLLabel,
            color
          })
        } else if (l.type === 'EmptyLabel' && !standalone) {
          singleLabels.push(label);
        } else if (l.type === 'PolygonLabel') {
          polygons.push({
            label: l.labelAnnotation as PolygonLabel,
            color
          })
        }
      });
    }

    const divStyle = {
      width: '100%',
      height: '100%',
      position: 'absolute' as 'absolute',
      overflow: 'hidden',
      left: 0,
      top: 0,
      border: standalone ? 'none' : '3px solid transparent',
      boxSizing: "border-box" as 'border-box',
      pointerEvents: "none" as 'none'
    };

    const labelStyle = {
      position: 'absolute' as 'absolute',
      top: 5,
      right: 5
    }

    const svgStyle = {
      width: '100%',
      height: '100%'
    };

    return (
      <div style={divStyle}>
        <div style={labelStyle}>
          { !standalone && labelConfiguration && singleLabels.map(label => (
            <LabelDefinitionChip key={label.id}
              style={{margin: 5, marginLeft: 0 }}
              labelDefinitions={labelConfiguration.labelDefinitions}
              labelType={labelConfiguration.labelType}
              labelId={label.id}
            />
          ))}
        </div>
        <svg style={svgStyle} viewBox="0 0 100 100">
          { boundingBoxes.map((item, idx: number) => (
            this.createBoundingBox(item.label, item.color, cropBox, idx)
          )) }
          { polygons.map((item, idx: number) => (
            this.createSvgPolygon(item.label, item.color, cropBox, idx)
          )) }
        </svg>
      </div>
    )
  }
}