import React from 'react';

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  MenuItem,
  IconButton,
  Typography
} from '@material-ui/core';

import {
  PlaylistAdd as AddIcon,
  Delete as DeleteIcon
} from '@material-ui/icons';

import {
  maxBy
} from 'lodash';

import {
  LabelDefinition,
  deepClone
} from '../api';

import {
  titleCase,
  lookupLabelDefinitionColor,
  listLabelDefinitionColors
} from '../utils';

interface Props {
  originalLabels: LabelDefinition[];
  error: boolean;
  onChange: (event: React.ChangeEvent<any>, values: LabelDefinition[]) => void;
}

class State {
  labels: LabelDefinition[];

  constructor(props: Props) {
    this.labels = deepClone<LabelDefinition[]>(props.originalLabels);
  }
}

export class LabelDefinitionsTable extends React.Component<Props, State> {

  readonly state = new State(this.props);

  render() {
    const { labels } = this.state;

    const nextId = (maxBy(labels, 'id')?.id || 0) + 1;

    return (
      <React.Fragment>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{padding: 0}}>
                  <IconButton
                    size="small"
                    onClick={evt => {
                      const newLabels = [
                        { id: nextId, name: 'New Definition' },
                        ...labels
                      ];
                      this.setState({ labels: newLabels });
                      this.props.onChange(evt, newLabels);
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Color</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            { labels.map((label, index) => {
              const [ rgb, alias ] = lookupLabelDefinitionColor(label, index);

              return (
                <TableRow key={index}>
                  <TableCell style={{padding: 0}}>
                    <IconButton
                      size="small"
                      onClick={evt => {
                        const newLabels = [
                          ...labels.slice(0, index+1),
                          { id: nextId, name: 'New Definition' },
                          ...labels.slice(index+1)
                        ];
                        this.setState({ labels: newLabels });
                        this.props.onChange(evt, newLabels);
                      }}
                    >
                      <AddIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      required
                      margin="none"
                      label=""
                      name="id"
                      value={label.id}
                      onChange={evt => {
                        label.id = parseInt(evt.currentTarget.value);
                        this.setState({ labels });
                        this.props.onChange(evt, labels);
                      }}
                      style={{width: 70}}
                      placeholder=""
                      error={isNaN(label.id) || labels.filter(l => l.id === label.id).length > 1}
                      InputProps={{
                        disableUnderline: false
                      }}
                      InputLabelProps={{shrink: true}}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      required
                      margin="none"
                      label=""
                      name="name"
                      value={label.name}
                      onChange={evt => {
                        label.name = evt.currentTarget.value;
                        this.setState({ labels });
                        this.props.onChange(evt, labels);
                      }}
                      fullWidth
                      placeholder=""
                      error={!label.name || labels.filter(l => l.name === label.name).length > 1}
                      InputProps={{
                        disableUnderline: false
                      }}
                      InputLabelProps={{shrink: true}}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      required
                      select
                      margin="none"
                      label=""
                      name="color"
                      value={alias}
                      onChange={evt => {
                        label.attributes = { color: evt.target.value as string };
                        this.setState({ labels });
                        this.props.onChange(evt, labels);
                      }}
                      error={labels.filter((l, li) => lookupLabelDefinitionColor(l, li)[1] === alias).length > 1}
                      fullWidth
                      placeholder=""
                      SelectProps={{style: { color: rgb } }}
                      InputLabelProps={{shrink: true}}
                    >
                      { listLabelDefinitionColors.map(key => (
                        <MenuItem key={key}
                          value={key}
                        >
                          {titleCase(key)}
                        </MenuItem>
                      )) }
                    </TextField>
                  </TableCell>
                  <TableCell style={{padding: 0}}>
                    <IconButton
                      size="small"
                      onClick={evt=> {
                        const newLabels = labels.filter(l => l.id !== label.id);
                        this.setState({ labels: newLabels });
                        this.props.onChange(evt, newLabels);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            }) }
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="caption" color="textSecondary">
          <ul>
            <li>A label name and color must be unique within the list</li>
            <li>A label can only be deleted if it's currently not in use</li>
          </ul>
        </Typography>
      </React.Fragment>
    )
  }
}