import React from 'react';

import {
  withTheme,
  Theme
} from '@material-ui/core/styles';

import {
  CircularProgress,
  Chip,
  Tooltip
} from '@material-ui/core';

import {
  Save as SaveIcon,
} from '@material-ui/icons';

import {
  formatTimeAgo
} from '../utils';

export type AutoSaveBadgeProps = {
  theme: Theme;
  lastEditAt: string;
  saving?: boolean;
}

export const AutoSaveBadge = withTheme(({
  theme,
  lastEditAt,
  saving
}: AutoSaveBadgeProps) => {

  const style = {
    color: theme.palette.text.secondary
  }

  const tooltip = `Every change you make is automatically saved.`
    + `\nLast edit was ${formatTimeAgo(lastEditAt)}.`

  const icon = saving
    ? <CircularProgress color="secondary" size={12} style={{margin: 2}} />
    : <SaveIcon />;

  return (
    <Tooltip
      title={tooltip}
      children={
        <Chip
          label="All changes saved"
          size="small"
          variant="outlined"
          style={style}
          icon={icon}
        />
      }
    />
  );
})