import React from 'react';

import {
  Box,
  Divider,
  IconButton,
  Toolbar,
} from '@material-ui/core';

import {
  Close as CloseIcon
} from '@material-ui/icons';

import {
  Skeleton
} from '@material-ui/lab';

import {
  ImageContainer,
  ImageTile
} from '../components';

interface Props {
  aspectRatio: number;
  columns: number;
  imagesCount: number;
  onClose?: () => void;
}

export class LabelsetSkeleton extends React.Component<Props> {

  render() {
    const { aspectRatio, columns, imagesCount, onClose } = this.props;

    return (
      <Box flex={1} display="flex" flexDirection="column">
        <Toolbar>
          <Skeleton width={350} height={20} />
          <Box mx="auto" />
          { onClose && <IconButton onClick={onClose}><CloseIcon /></IconButton> }
        </Toolbar>
        <Divider />
        <Toolbar variant="dense">
          <Skeleton width={150} height={15} />
          <Box mx="auto" />
          <Skeleton width={150} height={15} />
        </Toolbar>
        <Box display="flex" flexDirection="row" style={{flex: '1 1 1px', overflowY: 'hidden'}} padding={0.5}>
          <Box style={{flex: '1 1 1px', overflowY: 'scroll'}}>
            <ImageContainer
              columns={columns}
              aspectRatio={aspectRatio}
            >
              {Array(imagesCount).fill(0).map((val, index) => (
                <ImageTile key={index} />
              ))}
            </ImageContainer>
          </Box>
        </Box>
        <Toolbar variant="dense">
          <Skeleton width={150} height={15} />
          <Box mx="auto" />
          <Skeleton width={150} height={15} />
        </Toolbar>
      </Box>
    );
  }
}