import axios from 'axios'

import moment from 'moment'

import { User } from '../definitions'

import { OAuthProvider, OAuthPayload } from './oauth';

import { buildURL } from '../utils';

import { base64URLEncode, generateNonce, sha256 } from '../crypto';

interface CognitoPayload extends OAuthPayload {
  'cognito:groups': string[];
  'cognito:username': string;
  email: string;
  email_verified: boolean;
  token_use: string;
}

export default class CognitoProvider extends OAuthProvider<CognitoPayload> {

  async exchangeCodeForToken (code: string, url: URL): Promise<string> {
    const { base, client_id, redirect_uri } = this.config;

    const state = url.searchParams.get('state');
    const code_verifier = sessionStorage.getItem(`codeVerifier-${state}`);
    sessionStorage.removeItem(`codeVerifier-${state}`);
    if (code_verifier === null) {
      throw new Error('Unexpected code');
    }

    const body = new URLSearchParams({
      grant_type: 'authorization_code',
      client_id,
      code,
      code_verifier,
      redirect_uri
    }).toString();

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    const res = await axios.post(`${base}/oauth2/token`, body, { headers });
    return res.data.id_token;
  }

  async validatePayload (payload: CognitoPayload): Promise<boolean> {
    const { client_id } = this.config;
    const { aud, token_use, exp } = payload;

    return aud === client_id
      && token_use === 'id'
      && moment.unix(exp).diff(undefined) > 0;
  }

  deserializeUser (payload: CognitoPayload): User {
    return {
      username: payload.email,
      groups: payload['cognito:groups'] || []
    };
  }

  async createStateURL(path: string): Promise<URL> {
    const { base, client_id, redirect_uri } = this.config;

    const state = await generateNonce();
    const codeVerifier = await generateNonce();

    sessionStorage.setItem(`codeVerifier-${state}`, codeVerifier);
    const code_challenge = base64URLEncode(await sha256(codeVerifier));

    return buildURL(base, path, {
      response_type: 'code',
      client_id,
      redirect_uri,
      state,
      code_challenge,
      code_challenge_method: 'S256'
    });
  }

  async loginURL (): Promise<URL> {
    return this.createStateURL('/login');
  }

  async logoutURL (): Promise<URL> {
    return this.createStateURL('/logout');
  }

  async parseCode (url: URL): Promise<string | null> {
    return url.searchParams.get('code');
  }
}