import { Labelset, LabelsetInput, LabelsetEntry } from '../models';

import HTTPClient from '../http-client';

import { pick } from 'lodash';

export class Labelsets extends HTTPClient {

  listLabelsets(query: { datasetVersionId?: string }): Promise<Array<Labelset>> {
    return this.request({
      method: 'get',
      path: `/labelsets`,
      query
    })
  }

  getLabelset(labelsetId: string): Promise<Labelset> {
    return this.request({
      method: 'get',
      path: `/labelsets/${labelsetId}`
    })
  }

  createLabelset (datasetVersionId: string, labelsetInput: LabelsetInput): Promise<Labelset> {
    return this.request({
      method: 'post',
      path: `/datasets/versions/${datasetVersionId}/labelsets`,
      body: pick(labelsetInput, 'name', 'description', 'labelConfiguration', 'additionalAttributes', 'dataType')
    })
  }

  updateLabelset (labelsetId: string, labelsetInput: LabelsetInput): Promise<Labelset> {
    return this.request({
      method: 'put',
      path: `/labelsets/${labelsetId}`,
      body: pick(labelsetInput, 'name', 'description', 'labelConfiguration', 'additionalAttributes')
    })
  }

  deleteLabelset (labelsetId: string): Promise<void> {
    return this.request({
      method: 'delete',
      path: `/labelsets/${labelsetId}`
    })
  }

  patchLabelsetEntries (labelsetId: string, include: Array<LabelsetEntry>, exclude: Array<string> | 'all'): Promise<Labelset> {
    return this.request({
      method: 'patch',
      path: `/labelsets/${labelsetId}/entries`,
      body: { include, exclude }
    })
  }

  searchLabelsetEntries (labelsetId: string, objectIds: Array<String>): Promise<Array<LabelsetEntry>> {
    return this.request({
      method: 'post',
      path: `/labelsets/${labelsetId}/entries/searches`,
      body: { objectIds }
    })
  }
}