import React from 'react';

import preval from 'preval.macro';

import debounce from 'lodash/debounce';

import {
  AxiosError,
  AxiosResponse
} from "axios";

import {
  RouteComponentProps,
  withRouter
} from 'react-router-dom';

import {
  Box,
  Link,
  IconButton,
  Typography,
  Button,
  TextField
} from '@material-ui/core';

import {
  Alert
} from '@material-ui/lab';

import {
  Launch as OpenIcon
} from '@material-ui/icons';

import {
  createMuiTheme,
  ThemeProvider
} from '@material-ui/core/styles';

import {
  red,
  orange
} from '@material-ui/core/colors';

import {
  AppTopBar,
  ErrorAlert
} from '../components';

import API from '../api';

interface Props extends RouteComponentProps {}

class State {
  constructor (props: Props) {
    const params = new URLSearchParams(props.location.search);
    this.path = decodeURIComponent(params.get('path') || '');

    const body = params.get('body');

    this.body = body ? JSON.stringify(JSON.parse(decodeURIComponent(body)), null, 2) : '';
    this.method = params.get('method');
  }
  loading: boolean = false;
  error: AxiosError | null = null;
  response: AxiosResponse | null = null;

  path: string;
  body: string;
  method: string | null;
}

const deleteTheme = createMuiTheme({
  palette: {
    primary: {
      main: red[500],
    },
  },
});

const modifyTheme = createMuiTheme({
  palette: {
    primary: {
      main: orange[500],
    },
  },
});

export const DevQueryPage = withRouter(class extends React.Component<Props, State> {

  readonly state = new State(this.props);

  handlePathChange = debounce(path => {
    this.setState({ path })
  }, 200)

  handleGet = () => {
    const { path } = this.state;
    this.setState({ method: 'get', loading: true, error: null, response: null });
    this.props.history.push(`?method=get&path=${path}`);
    API.default.fullRequest({ method: 'get', path })
      .then(response => this.setState({ loading: false, response }))
      .catch(error => this.setState({ loading: false, error }))
  }

  handlePut = () => {
    const { path, body } = this.state;
    this.setState({ method: 'put', loading: true, error: null, response: null })
    this.props.history.push(`?method=put&path=${path}&body=${body}`);
    API.default.fullRequest({ method: 'put', path, body })
      .then(response => this.setState({ loading: false, response }))
      .catch(error => this.setState({ loading: false, error }))
  }

  handlePatch = () => {
    const { path, body } = this.state;
    this.setState({ method: 'post', loading: true, error: null, response: null })
    this.props.history.push(`?method=post&path=${path}&body=${body}`);
    API.default.fullRequest({ method: 'patch', path, body: body })
      .then(response => this.setState({ loading: false, response }))
      .catch(error => this.setState({ loading: false, error }))
  }

  handlePost = () => {
    const { path, body } = this.state;
    this.setState({ method: 'post', loading: true, error: null, response: null })
    this.props.history.push(`?method=post&path=${path}&body=${body}`);
    API.default.fullRequest({ method: 'post', path, body: body })
      .then(response => this.setState({ loading: false, response }))
      .catch(error => this.setState({ loading: false, error }))
  }

  handleDelete = () => {
    const { path } = this.state;
    this.props.history.push(`?method=delete&path=${path}`);
    this.setState({ method: 'delete', loading: true, error: null, response: null })
    API.default.fullRequest({ method: 'delete', path })
      .then(response => this.setState({ loading: false, response }))
      .catch(error => this.setState({ loading: false, error }))
  }

  componentDidMount() {
    const { method } = this.state;

    if (method === 'get') this.handleGet();
    else if (method === 'put') this.handlePut();
    else if (method === 'post') this.handlePost();
    else if (method === 'delete') this.handleDelete();
  }

  componentDidUpdate() {
    (window as any).props = this.props;
    (window as any).state = this.state;
  }

  render() {
    const { loading, error, path, body, response, method } = this.state;

    const API_BASE = preval`module.exports = process.env.API_BASE;`;
    const API_KEY = preval`module.exports = process.env.API_KEY;`;

    const apiURL = `${API_BASE}/docs/`.replace('://', `://swagger:${API_KEY}@`);

    let commandCURL: string | null = null;
    if (method) {
      try {
        commandCURL = `curl -i '${API_BASE}${path}' -X '${method.toUpperCase()}' -H 'content-type: application/json'  -H 'x-api-key: ${API_KEY}'`
        + ( body ? ` --data-raw '${JSON.stringify(JSON.parse(body))}'` : '');
      } catch (err) {}
    }

    return (
      <React.Fragment>
        <AppTopBar>
          <Typography variant="h6">
            API Gateway
          </Typography>
          <Box mx={1} />
          <Link href={apiURL} target="_blank">
            <IconButton style={{color: 'white'}}>
              <OpenIcon />
            </IconButton>
          </Link>
        </AppTopBar>
        <Box display="flex" flex={1} flexDirection="row" p={2}>
          <Box display="flex" flex={1} flexDirection="column">
            <TextField
              size="small"
              disabled={loading}
              onSubmit={e => this.handleGet()}
              required
              margin="none"
              name="path"
              label="Path"
              defaultValue={path}
              variant="outlined"
              onChange={evt => this.handlePathChange(evt.target.value)}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  this.handleGet();
                  ev.preventDefault();
                }
              }}
              autoFocus
              fullWidth
              placeholder=""
              InputLabelProps={{ shrink: true }}
            />
            <Box m={0.5} />
            <TextField
              style={{ flex: 1 }}
              InputProps={{ rows: undefined, style: { flex: 1 } }}
              inputProps={{ style: { height: '100%', overflow: 'visible' } }}
              disabled={loading}
              label="Body"
              name="body"
              placeholder=""
              multiline
              variant="outlined"
              value={body}
              fullWidth
              onChange={evt => {
                const body = evt.target.value;
                this.setState({ body });
              }}
            />
          </Box>
          <Box px={1}>
            <Button
              style={{width: 100}}
              color="primary"
              onClick={e => this.handleGet()}
              variant={method === 'get' ? 'contained' : 'outlined'}
            >GET</Button>
            <Box m={1} />
            <ThemeProvider theme={modifyTheme}>
              <Button
                style={{width: 100}}
                color="primary"
                onClick={e => this.handlePut()}
                variant={method === 'put' ? 'contained' : 'outlined'}
              >PUT</Button>
            </ThemeProvider>
            <Box m={1} />
            <ThemeProvider theme={modifyTheme}>
              <Button
                style={{width: 100}}
                color="primary"
                onClick={e => this.handlePatch()}
                variant={method === 'put' ? 'contained' : 'outlined'}
              >PATCH</Button>
            </ThemeProvider>
            <Box m={1} />
            <ThemeProvider theme={modifyTheme}>
              <Button
                style={{width: 100}}
                color="primary"
                onClick={e => this.handlePost()}
                variant={method === 'post' ? 'contained' : 'outlined'}
              >POST</Button>
            </ThemeProvider>
            <Box m={1} />
            <ThemeProvider theme={deleteTheme}>
              <Button
                style={{width: 100}}
                color="primary"
                onClick={e => this.handleDelete()}
                variant={method === 'delete' ? 'contained' : 'outlined'}
              >DELETE</Button>
            </ThemeProvider>
            <Box my={1} />
            <Button onClick={() => navigator.clipboard.writeText(window.location.href)}>
              Copy URL
            </Button>
            <Box my={1} />
            { commandCURL !== null &&
              <Button onClick={() => navigator.clipboard.writeText(commandCURL || "")}>
                Copy CURL
              </Button>
            }
          </Box>
          <Box display="flex" flex={1} flexDirection="column" style={{overflow: 'hidden'}}>
            { loading &&
              <Alert
                variant="outlined"
                style={{marginBottom: 10 }}
                severity="info">
                Loading...
              </Alert>
            }
            { response &&
              <Alert
                variant="filled"
                style={{marginBottom: 10 }}
                severity="success"
              >
                {response.status}
              </Alert>
            }
            { error &&
              <ErrorAlert
                error={error}
                style={{marginBottom: 10 }}
              />
            }
            { !loading &&
              <TextField
                style={{ flex: 1 }}
                InputProps={{ rows: undefined, style: { flex: 1 } }}
                inputProps={{ style: { height: '100%', overflow: 'visible' } }}
                disabled
                label="Response"
                name="response"
                multiline
                variant="outlined"
                value={error ? JSON.stringify(error.response || error, null, 2) : response ? JSON.stringify(response.data, null, 2) : ''}
                error={!!error}
                fullWidth
              />
            }
          </Box>
        </Box>
      </React.Fragment>
    )
  }
});