import React from 'react';

import {
  Badge,
  Chip,
  Box,
  Typography,
  Tooltip
} from '@material-ui/core';

interface Props {
  title: string;
  tooltip?: string;
  icon?: React.ReactElement;
  color?: string;
  dot?: boolean;
}

export class PropertyChip extends React.Component<Props> {

  render() {
    const { color, title, icon, dot, tooltip } = this.props;

    const styles = {
      border: 'none',
      background: color || 'inherit',
      marginBottom: 8
    };

    const chip = (
      <Chip
        color={color ? 'primary' : 'default'}
        size="small"
        style={styles}
        label={
          <Typography variant="body2">{title}</Typography>
        }
        icon={icon} />
    );

    return (
      <Box
        component="span"
        paddingRight={1}
        paddingBottom={1}
      >
        <Tooltip title={tooltip || ''}>
        {
          dot
            ? <Badge color="error" badgeContent=" " variant="dot">{chip}</Badge>
            : chip
        }
        </Tooltip>
      </Box>
    );
  }
}