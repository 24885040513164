import React from 'react';

import {
  List
} from '@material-ui/core';

import {
  DataCollection
} from '../api';

import {
  DataCollectionItem,
  DataCollectionItemSkeleton
} from '../components';

interface SkeletonProps {
  countItems: number;
}

interface Props {
  items: DataCollection[];
  selection?: DataCollection;
  onSelection: (item: DataCollection) => void;
  onUpdate: (item: DataCollection) => void;
  onDelete: (item: DataCollection) => void;
  style?: React.CSSProperties;
}

export class DataCollectionListSkeleton extends React.Component<SkeletonProps> {
  render() {
    const { countItems } = this.props;

    return (
      <List dense disablePadding>
        { Array(countItems).fill(0).map((val, index) => (
          <DataCollectionItemSkeleton key={index} />
        )) }
      </List>
    );
  }
}

export class DataCollectionList extends React.Component<Props> {

  render() {
    const { items, style, selection, onSelection, onUpdate, onDelete } = this.props;

    return (
      <List dense disablePadding style={style}>
        { items.map(item => (
          <DataCollectionItem
            key={item.id}
            item={item}
            selected={selection && selection.id === item.id}
            onClick={() => onSelection(item)}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        )) }
      </List>
    );
  }
}