import React from 'react';

import {
  Formik,
  FormikProps,
  FormikErrors,
  Form
} from 'formik';

import {
  AspectInput
} from '../api';

import {
  DevicesListEditable
} from '../components';

interface Props {
  aspectInput: AspectInput;
  innerRef: any;
  onSubmit: (aspectInput: AspectInput) => void;
}

export class AspectDevicesForm extends React.PureComponent<Props> {
  render() {
    const { aspectInput, innerRef, onSubmit } = this.props;
    return (
      <Formik
        innerRef={innerRef}
        initialValues={aspectInput}
        validate={ (values: AspectInput) => {
          const errors: FormikErrors<AspectInput> = {};
          values.attributes.devices.forEach(device => {
            if (!device.name)
              errors.attributes = { devices: 'Required' };

            device.sources.forEach(source => {
              if (!source.name)
                errors.attributes = { devices: 'Required' };
            })
          })

          return errors;
        }}
        onSubmit={onSubmit}
      >
      { (props: FormikProps<AspectInput>)  => {
        const { values, errors, touched, setFieldValue } = props;

        return (
          <Form autoComplete="off" noValidate style={{overflow: 'hidden'}}>
            <DevicesListEditable
              value={values.attributes.devices}
              error={!!touched.attributes?.devices && !!errors.attributes?.devices}
              onChange={devices => {
                setFieldValue('attributes', {...values.attributes, devices }, false)
              }} />
          </Form>
        );
      } }
      </Formik>
    );
  }
}