import React from 'react';

import {
  List
} from '@material-ui/core';

import {
  DatasetItem,
} from '../components';

import {
  Dataset
} from '../api';

interface Props {
  items: Dataset[];
  selection?: Dataset;
  onSelection: (item: Dataset) => void;
  onUpdate: (item: Dataset) => void;
  onDelete: (item: Dataset) => void;
  style?: React.CSSProperties;
}

export class DatasetList extends React.Component<Props> {

  render() {
    const { items, style, selection, onSelection, onUpdate, onDelete } = this.props;

    return (
      <List dense disablePadding style={style}>
        { items.map(item => (
          <DatasetItem
            key={item.id}
            dataset={item}
            selected={selection && selection.datasetVersionId === item.datasetVersionId}
            onClick={() => onSelection(item)}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        )) }
      </List>
    );
  }
}