import React from 'react';

import {
  Formik,
  FormikProps,
  FormikErrors,
  Form
} from 'formik';

import {
  TextField,
  Chip
} from '@material-ui/core';

import {
  Autocomplete
} from '@material-ui/lab';

import {
  TemplateInstanceInput
} from '../api';

interface Props {
  templateInstanceInput: TemplateInstanceInput;
  templateInstanceId?: string;
  templateInstancesTags?: string[];
  innerRef?: any;
  onChange?: (templateInstanceInput: TemplateInstanceInput) => void;
  onSubmit: (templateInstanceInput: TemplateInstanceInput) => void;
}

export class TemplateInstanceBasicForm extends React.PureComponent<Props> {

  render() {
    const { templateInstanceId, templateInstanceInput, innerRef, onChange, onSubmit, templateInstancesTags } = this.props;
    return (
      <Formik
        innerRef={innerRef}
        initialValues={templateInstanceInput}
        validate={ (values: TemplateInstanceInput) => {
          const errors: FormikErrors<TemplateInstanceInput> = {};
          if (!values.name)
            errors.name = 'Required';
          if (onChange)
            onChange(values);
          return errors;
        }}
        onSubmit={onSubmit}
      >
      { (props: FormikProps<TemplateInstanceInput>)  => {
        const { values, touched, errors, handleChange, setFieldValue } = props;

        return (
          <Form autoComplete="off" noValidate style={{display: 'flex', flexDirection: 'column', flex: 1}}>
            <TextField
              required
              margin="normal"
              name="name"
              label="Name"
              value={values.name}
              onChange={handleChange}
              autoFocus
              fullWidth
              placeholder=""
              error={touched.name && !!errors.name}
              helperText={errors.name || 'Choose a unique name'}
              InputLabelProps={{shrink: true}}
              onFocus={event => {
                if (!templateInstanceId)
                  event.target.select()
              }}
            />
            <Autocomplete<string, true, false, true>
              autoSelect
              multiple
              freeSolo
              filterSelectedOptions
              options={templateInstancesTags || []}
              getOptionLabel={(option) => option}
              value={values.additionalAttributes?.tags || []}
              onChange={(evt, tags) => {
                setFieldValue('additionalAttributes.tags', tags, true);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="tag"
                  variant="standard"
                  label="Tags"
                  placeholder=""
                  fullWidth
                  helperText="Enter a list of keywords to describe the templateInstance."
                  InputLabelProps={{shrink: true}}
                />
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    size="small"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          </Form>
        );
      } }
      </Formik>
    );
  }
}