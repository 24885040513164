import React from 'react';

import {
  Menu,
  MenuItem,
  Typography,
  IconButton
} from '@material-ui/core';

import {
  MoreVert as MenuIcon
} from '@material-ui/icons';

import {
  AssetForm,
  ResourceEditDialog,
  ResourceDeleteDialog
} from '../components';

import API, {
  Asset,
  AssetInput
} from '../api';

interface Props {
  asset: Asset;
  onUpdate: (asset: Asset) => void;
  onDelete: (asset: Asset) => void;
}

class State {
  anchor: HTMLButtonElement | null = null;
}

export class AssetMenu extends React.Component<Props, State> {

  readonly state = new State();

  show = (evt: any) => {
    this.setState({ anchor: evt.currentTarget });
  }

  hide = () => {
    this.setState({ anchor: null });
  }

  render() {
    const { anchor } = this.state;
    const { asset } = this.props;

    return (
      <React.Fragment>
        <IconButton size="small" color="inherit" disableRipple disableFocusRipple onClick={this.show}>
          <MenuIcon fontSize="small" />
        </IconButton>
        <Menu
          keepMounted
          anchorEl={anchor}
          open={Boolean(anchor)}
          onClose={this.hide}
        >
          <ResourceEditDialog<AssetInput, Asset>
            title="Edit Production Line"
            resource={{...asset}}
            trigger={<MenuItem onClick={this.hide}>Edit</MenuItem>}
            action={body => API.assets.updateAsset(asset.id, body) }
            onSuccess={this.props.onUpdate}
            renderForm={(assetInput, ref, handleSubmit) => (
              <AssetForm
                assetInput={assetInput}
                innerRef={ref}
                onSubmit={handleSubmit} />
            )}
          />
          <ResourceDeleteDialog<Asset>
            title="Delete Production Line ?"
            resource={asset}
            trigger={<MenuItem onClick={this.hide}>Delete</MenuItem>}
            action={asset => API.assets.deleteAsset(asset.id) }
            onSuccess={this.props.onDelete}
          >
            <Typography variant="subtitle1">
              All associated data will be permanently lost.
            </Typography>
          </ResourceDeleteDialog>
        </Menu>
      </React.Fragment>
    );
  }
}