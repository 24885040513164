import React from 'react';

import {
  Formik,
  FormikProps,
  FormikErrors,
  Form
} from 'formik';

import {
  TextField
} from '@material-ui/core';

import {
  AssetInput
} from '../api';

interface Props {
  assetInput: AssetInput;
  innerRef: any;
  onSubmit: (assetInput: AssetInput) => void;
}

export class AssetForm extends React.PureComponent<Props> {
  render() {
    const { assetInput, innerRef, onSubmit } = this.props;
    return (
      <Formik
        innerRef={innerRef}
        initialValues={assetInput}
        validate={ (values: AssetInput) => {
          const errors: FormikErrors<AssetInput> = {};
          if (!values.name)
            errors.name = 'Required';
          return errors;
        }}
        onSubmit={onSubmit}
      >
      { (props: FormikProps<AssetInput>)  => {
        const { values, errors, touched, handleChange } = props;

        return (
          <Form autoComplete="off" noValidate>
            <TextField
              required
              margin="normal"
              name="name"
              label="Name"
              value={values.name}
              onChange={handleChange}
              autoFocus
              fullWidth
              placeholder=""
              error={touched.name && !!errors.name}
              helperText={errors.name || 'Choose a unique name'}
              InputLabelProps={{shrink: true}}
              inputProps={{ maxLength: 150 }}
            />
            <TextField
              margin="normal"
              name="description"
              label="Description"
              value={values.description}
              onChange={handleChange}
              fullWidth
              placeholder=""
              error={touched.description && !!errors.description}
              helperText={errors.description}
              InputLabelProps={{shrink: true}}
              inputProps={{ maxLength: 250 }}
            />
          </Form>
        );
      } }
      </Formik>
    );
  }
}