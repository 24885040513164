import React from 'react';

import {
  Box,
  Typography,
  Grid,
  CircularProgress
} from '@material-ui/core';

interface Props {
  message?: string;
}

export class LoadingBox extends React.Component<Props> {

  render() {
    const { message } = this.props;

    return (
      <Box flex={1} display="flex">
        <Grid
          spacing={1}
          container
          justify="center"
          direction="column"
          alignItems="center"
        >
          <Grid item>
            <CircularProgress disableShrink color="secondary" />
          </Grid>
          <Grid item>
            <Typography color="textSecondary" variant="caption">{ message }</Typography>
          </Grid>
        </Grid>
      </Box>
    )
  }
}