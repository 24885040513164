import React from 'react';

import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core/styles';

type Props = {
  title: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
  onDrop: (files: FileList) => void;
}

type State = {
  dragging: boolean;
}

const styles = (theme: Theme) => createStyles({
  root: {
    position: 'relative'
  },
  frame: {
    border: `dashed ${theme.palette.primary.main} 2px`,
    backgroundColor: 'rgba(255,255,255,.8)',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  title: {
    position: 'absolute',
    top: '50%',
    right: 0,
    left: 0,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontSize: 25
  }
});

export const ImageDragAndDrop = withStyles(styles)(class extends React.Component<Props & WithStyles<typeof styles>, State> {

  static defaultProps = {
    title: 'Drag images here',
  };

  readonly state = { dragging: false };

  readonly dropRef = React.createRef<HTMLDivElement>();

  private dragCounter = 0;

  cancelEvent = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }

  handleDragEnter = (e: DragEvent) => {
    this.cancelEvent(e);
    this.dragCounter++;
    if (e.dataTransfer && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
    }
  }

  handleDragLeave = (e: DragEvent) => {
    this.cancelEvent(e);
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ dragging: false });
      if (e.dataTransfer) {
        e.dataTransfer.clearData();
      }
    }
  }

  handleDragOver = (e: DragEvent) => {
    this.cancelEvent(e);
  }

  handleDrop = (e: DragEvent) => {
    this.cancelEvent(e);
    this.setState({ dragging: false });
    if (e.dataTransfer) {
      const files = Array.from(e.dataTransfer.files).filter(s => s.type.includes("image"));
      if (files.length > 0) {
        this.props.onDrop(e.dataTransfer.files);
      }
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  }

  componentDidMount() {
    let div = this.dropRef.current;
    if (div) {
      div.addEventListener('dragenter', this.handleDragEnter);
      div.addEventListener('dragleave', this.handleDragLeave);
      div.addEventListener('dragover', this.handleDragOver);
      div.addEventListener('drop', this.handleDrop);
    }
  }

  componentWillUnmount() {
    let div = this.dropRef.current;
    if (div) {
      div.removeEventListener('dragenter', this.handleDragEnter);
      div.removeEventListener('dragleave', this.handleDragLeave);
      div.removeEventListener('dragover', this.handleDragOver);
      div.removeEventListener('drop', this.handleDrop);
    }
  }

  render() {
    const { title, classes, children, style } = this.props;
    const { dragging } = this.state;

    return (
      <div
        className={classes.root}
        style={{...style}}
        ref={this.dropRef}
      >
        { children }
        { dragging &&
          <div className={classes.frame}>
            <div className={classes.title}>{title}</div>
          </div>
        }
      </div>
    );
  }
});