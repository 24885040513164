import React from 'react';

import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography
} from '@material-ui/core';

import {
  Skeleton
} from '@material-ui/lab';

import {
  titleCase
} from '../utils';

export class TemplateInstanceItemGroupSkeleton extends React.Component {
  render() {
    return (
      <ListItem>
        <ListItemText
          primary={
            <Skeleton height={15} width="50%" />
          }
        />
      </ListItem>
    );
  }
}

interface Props {
  name: string;
  countTemplateInstances: number;
}

export class TemplateInstanceItemGroup extends React.Component<Props> {
  render() {
    const { name, countTemplateInstances } = this.props;

    return (
      <ListItem>
        <ListItemText
          primary={
            <Typography
              style={{lineHeight: 'initial'}}
              noWrap
              variant="overline"
              color="textSecondary">
              { titleCase(name) }
            </Typography>
          }
        />
        <ListItemSecondaryAction>
          <Typography
            style={{lineHeight: 'initial'}}
            variant="overline"
            color="textSecondary">
            { countTemplateInstances }
          </Typography>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}