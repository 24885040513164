import React from 'react';

import {
  Formik,
  FormikProps,
  FormikErrors,
  Form
} from 'formik';

import {
  MenuItem,
  TextField/*,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  Typography,
  Box*/
} from '@material-ui/core';

import {
  TemplateInput,
  DataType,
  LabelType
} from '../api';

interface Props {
  newTemplate?: boolean;
  uploadRequired?: boolean;
  templateInput: TemplateInput;
  dataType?: DataType;
  innerRef: any;
  onSubmit: (templateInput: TemplateInput) => void;
}

export class TemplateForm extends React.PureComponent<Props> {

  render() {
    const { newTemplate, uploadRequired, templateInput, innerRef, onSubmit } = this.props;
    return (
      <Formik
        innerRef={innerRef}
        initialValues={{...templateInput}}
        validateOnMount={false}
        validateOnChange={false}
        validateOnBlur={true}
        validate={ (values: TemplateInput) => {
          const errors: FormikErrors<TemplateInput> = {};
          if (!values.name)
            errors.name = 'Required';
          if (!values.templateType)
            errors.templateType = 'Required';
          if (!values.version)
            errors.version = 'Required';
          else if (!values.version.match(/^(\d+\.)?(\d+\.)?(\d+)$/))
            errors.version = 'Must be a valid version format (e.g. 1, 1.2, 1.2.3) and be consistent across versions';
          if (uploadRequired && !values.upload)
            errors.upload = 'Required';
          return errors;
        }}
        onSubmit={onSubmit}
      >
      { (props: FormikProps<TemplateInput>)  => {
        const { values, touched, errors, handleChange, setFieldValue } = props;

        return (
          <Form autoComplete="off" noValidate style={{display: 'flex', flexDirection: 'column', flex: 1}}>
            { <React.Fragment>
                { newTemplate &&
                  <TextField
                    autoFocus
                    required
                    margin="normal"
                    name="templateType"
                    label="Type"
                    value={values.templateType}
                    onChange={handleChange}
                    fullWidth
                    placeholder=""
                    error={touched.templateType && !!errors.templateType}
                    helperText={touched.templateType && errors.templateType}
                    InputLabelProps={{shrink: true}}
                    inputProps={{ maxLength: 150 }}
                  />
                }
                { newTemplate &&
                  <TextField
                    required
                    select
                    margin="normal"
                    name="labelType"
                    label="Label Type"
                    defaultValue={values.settings.labelSettings.labelConfiguration.labelType}
                    onChange={evt => {
                      setFieldValue('settings.labelSettings.labelConfiguration.labelType', evt.target.value, true);
                    }}
                  >
                    <MenuItem value={LabelType.Classification}>Classification</MenuItem>
                    <MenuItem value={LabelType.BoundingBoxes}>Bounding Boxes</MenuItem>
                    <MenuItem value={LabelType.Segmentation}>Segmentation</MenuItem>
                  </TextField>
                }
                <TextField
                  required
                  margin="normal"
                  name="name"
                  label="Name"
                  value={values.name}
                  onChange={handleChange}
                  fullWidth
                  placeholder=""
                  error={touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  InputLabelProps={{shrink: true}}
                  inputProps={{ maxLength: 150 }}
                />
                <TextField
                  margin="normal"
                  name="description"
                  label="Description"
                  value={values.description}
                  onChange={handleChange}
                  multiline
                  rows={2}
                  fullWidth
                  placeholder=""
                  error={touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                  InputLabelProps={{shrink: true}}
                  inputProps={{ maxLength: 250 }}
                />
              </React.Fragment>
            }
            <TextField
              required
              margin="normal"
              name="version"
              label="Version"
              value={values.version}
              onChange={handleChange}
              fullWidth
              placeholder=""
              error={touched.version && !!errors.version}
              helperText={touched.version && errors.version}
              InputLabelProps={{shrink: true}}
              inputProps={{ maxLength: 32 }}
            />
            <TextField
              required={uploadRequired}
              type="file"
              margin="normal"
              name="upload"
              label="Artifact"
              defaultValue={values.upload}
              onChange={(evt: any) => {
                const { files } = evt.target;
                if (files && files.length > 0) {
                  setFieldValue('upload', files[0], true)
                }
              }}
              fullWidth
              placeholder=""
              error={!!errors.upload}
              helperText={errors.upload}
              InputLabelProps={{shrink: true}}
            />
          </Form>
        );
      } }
      </Formik>
    );
  }
}