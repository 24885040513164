import { Dataset, DatasetInput, DataType, DataCollectionObject, DatasetSplitConfig, DatasetSplitResults } from '../models';

import HTTPClient from '../http-client';

import { pick } from 'lodash';

export class Datasets extends HTTPClient {

  listDatasets (query: { dataCollectionId?: string, dataType?: DataType }): Promise<Array<Dataset>> {
    return this.request({
      method: 'get',
      path: `/datasets`,
      query
    })
  }

  createDataset (datasetInput: DatasetInput): Promise<Dataset> {
    return this.request({
      method: 'post',
      path: `/datasets`,
      body: pick(datasetInput, 'dataType', 'name', 'description', 'dataCollectionId')
    })
  }

  getDatasetVersion (datasetVersionId: string): Promise<Dataset> {
    return this.request({
      method: 'get',
      path: `/datasets/versions/${datasetVersionId}`,
    })
  }

  updateDatasetVersion (datasetVersionId: string, datasetInput: DatasetInput): Promise<Dataset> {
    return this.request({
      method: 'put',
      path: `/datasets/versions/${datasetVersionId}`,
      body: pick(datasetInput, 'name', 'description')
    })
  }

  deleteDatasetVersion (datasetVersionId: string): Promise<void> {
    return this.request({
      method: 'delete',
      path: `/datasets/versions/${datasetVersionId}`
    })
  }

  getDatasetVersionObjects (datasetVersionId: string, query: { skip?: number, take?: number, ordering?: 'asc' | 'desc' }): Promise<Array<DataCollectionObject>> {
    return this.request({
      method: 'get',
      path: `/datasets/versions/${datasetVersionId}/objects`,
      query
    })
    .then(data => data.objects);
  }

  patchDatasetVersionObjects (datasetVersionId: string, include: Array<String> | 'all', exclude: Array<string> | 'all'): Promise<Dataset> {
    return this.request({
      method: 'patch',
      path: `/datasets/versions/${datasetVersionId}/objects`,
      body: { include, exclude }
    })
  }

  searchDatasetVersionObjects (datasetVersionId: string, objectIds: Array<String>): Promise<Array<DataCollectionObject>> {
    return this.request({
      method: 'post',
      path: `/datasets/versions/${datasetVersionId}/objects/searches`,
      body: { objectIds }
    })
  }

  splitDatasetVersion (datasetVersionId: string, input: DatasetSplitConfig): Promise<DatasetSplitResults> {
    return this.request({
      method: 'post',
      path: `/datasets/versions/${datasetVersionId}/splits`,
      body: input
    })
  }
}