import { Template, TemplateInput, DataType, Artifact/*, TemplateVersion*/  } from '../models';

import HTTPClient from '../http-client';

import { pick } from 'lodash';

export class Templates extends HTTPClient {

  /*** PSQL migration ***/

  listTemplates (query: { dataType?: DataType }): Promise<Array<Template>> {
    return this.request({
      method: 'get',
      path: `/templates`,
      query
    })
  }

  createTemplate(input: TemplateInput): Promise<Template> {
    return this.request({
      method: 'post',
      path: `/templates`,
      body: pick(input, 'name', 'description', 'version', 'settings', 'templateType', 'dataType', 'trainingRequired')
    })
  }

  getTemplate(templateId: string): Promise<Template> {
    return this.request({
      method: 'get',
      path: `/templates/${templateId}`
    })
  }

  updateTemplate(templateId: string, input: TemplateInput): Promise<Template> {
    return this.request({
      method: 'put',
      path: `/templates/${templateId}`,
      body: pick(input, 'name', 'description', 'version', 'settings', 'labelType')
    })
  }

  deleteTemplate(id: string): Promise<{ success: boolean }> {
    return this.request({
      method: 'delete',
      path: `/templates/${id}`
    })
  }

  getTemplateVersions(templateId: string): Promise<Template[]> {
    return this.request({
      method: 'get',
      path: `/templates/${templateId}/versions`
    })
  }

  createTemplateVersion(templateId: string, input: TemplateInput): Promise<Template> {
    return this.request({
      method: 'post',
      path: `/templates/${templateId}`,
      body: pick(input, 'name', 'description', 'version', 'settings')
    })
  }

  getTemplateVersion(templateVersionId: string): Promise<Template> {
    return this.request({
      method: 'get',
      path: `/templates/versions/${templateVersionId}`
    })
  }

  updateTemplateVersion(templateId: string, templateVersionId: string, input: TemplateInput): Promise<Template> {
    return this.request({
      method: 'put',
      path: `/templates/${templateId}/versions/${templateVersionId}`,
      body: pick(input, 'name', 'description', 'version', 'settings')
    })
  }

  deleteTemplateVersion(templateId: string, templateVersionId: string): Promise<void> {
    return this.request({
      method: 'delete',
      path: `/templates/${templateId}/versions/${templateVersionId}`
    })
  }

  createOrUpdateTemplateVersionArtifact(templateId: string, templateVersionId: string): Promise<Artifact> {
    return this.request({
      method: 'put',
      path: `/templates/${templateId}/versions/${templateVersionId}/code-artifact`,
    })
  }

  uploadTemplateVersionArtifact(templateVersionArtificatUploadUrl: string, file: File): Promise<void> {
    return this.request({
      method: 'put',
      path: templateVersionArtificatUploadUrl,
      body: file
    })
  }
}