import React from 'react';

import {
  SelectableGroup
} from 'react-selectable-fast';

import {
  Divider,
  Paper,
  Toolbar,
  Typography,
  Box,
  Button,
  IconButton,
  Checkbox,
  FormControlLabel,
  Tooltip
} from '@material-ui/core';

import {
  DeleteForever as DeleteIcon,
  Settings as SettingsIcon,
  AddPhotoAlternate as UploadIcon,
  Close as CloseIcon
} from '@material-ui/icons';

import {
  Skeleton
} from '@material-ui/lab';

import API, {
  DataCollection,
  DataCollectionObject,
  DataCollectionInput,
  DataType
} from '../api';

import {
  AutoSaveBadge,
  DataCollectionIcon,
  DataCollectionForm,
  DataCollectionSkeleton,
  ErrorAlert,
  ErrorBox,
  ImageContainer,
  ImageDragAndDrop,
  ImageTile,
  ImageZoomControl,
  PaginationToolbar,
  ResourceEditDialog
} from '../components';

import {
  loadDataCollectionObjectsPage,
} from '../utils'

interface Props {
  dataCollectionId?: string;
  dataSourceReferenceId: string;
  onCreate?: (dataCollection: DataCollection) => void;
  onUpdate?: (dataCollection: DataCollection) => void;
  onClose?: () => void;
}

class State {
  loading: boolean = false;
  paginating: boolean = false;
  error?: Error;
  dataCollection?: DataCollection;
  objects?: Array<DataCollectionObject>;
  pageIndex: number = 0;
  saving: boolean = false;
  selectedImages: DataCollectionObject[] = [];
  columns: number = 0;
}

export class DataCollectionEditor extends React.Component<Props, State> {

  readonly state = new State();

  private selectableRef = React.createRef<SelectableGroup>();

  private containerRef = React.createRef<HTMLDivElement>();

  reload = () => {
    this.setState(new State());
    this.componentDidMount();
  }

  onPageIndexChange = (dataCollectionId: string, pageIndex: number) => {
    this.setState({ paginating: true, error: undefined, pageIndex, selectedImages: [] });
    loadDataCollectionObjectsPage(dataCollectionId, pageIndex)
    .then(objects => this.setState({ paginating: false, objects}))
    .catch(error => this.setState({ paginating: false, error }))
  }

  componentDidMount () {
    const { dataCollectionId, dataSourceReferenceId, onCreate } = this.props;
    const newDataCollection: DataCollectionInput = {
      dataSourceReferenceId,
      dataType: DataType.Images,
      name: 'New Data Collection',
      description: ''
    };

    this.setState({ loading: true, error: undefined });
    (dataCollectionId ? API.dataCollections.getDataCollection(dataCollectionId) : API.dataCollections.createDataCollection(newDataCollection))
    .then(dataCollection => {
      if (!dataCollectionId)
        onCreate?.(dataCollection);

      return loadDataCollectionObjectsPage(dataCollection.id)
      .then(objects => {
        this.setState({ loading: false, dataCollection, objects })
      })
    })
    .catch(error => this.setState({ loading: false, error }))
  }

  handleFilesDrop = (dataCollectionId: string, pageIndex: number, files: FileList | null) => {
    if (files === null || files.length === 0)
      return;

    this.setState({ saving: true, paginating: true, error: undefined });
    Promise.all(
      Array.from(files).map(file =>
        API.dataCollections.createDataCollectionObject(dataCollectionId, file.name)
        .then(image => API.dataCollections.uploadDataCollectionObject(image.s3PresignedUploadUrl, file))
      )
    )
    .then(() => {
      return API.dataCollections.getDataCollection(dataCollectionId)
    })
    .then(dataCollection => {
      this.setState({ saving: false, paginating: false, dataCollection });
      this.onPageIndexChange(dataCollectionId, pageIndex);
      this.props.onUpdate?.(dataCollection);
    })
    .catch(error => {
      this.setState({ saving: false, paginating: false, error });
    })
  }

  handleImagesDeletion(dataCollectionId: string, pageIndex: number, images: Array<DataCollectionObject>): void {
    this.setState({ saving: true, paginating: true, error: undefined });
    Promise.all(images.map(image =>
      API.dataCollections.deleteDataCollectionObject(dataCollectionId, image.id)
    ))
    .then(() => {
      return API.dataCollections.getDataCollection(dataCollectionId)
    })
    .then(dataCollection => {
      this.setState({ saving: false, paginating: false, dataCollection });
      this.onPageIndexChange(dataCollectionId, pageIndex);
      this.props.onUpdate?.(dataCollection);
    })
    .catch(error => {
      this.setState({ saving: false, paginating: false, error })
    })
  }

  render() {
    const { onClose, onUpdate } = this.props;
    const { dataCollection, objects, pageIndex, saving, loading, paginating, error, columns, selectedImages } = this.state;

    if (loading) {
      return <DataCollectionSkeleton aspectRatio={1} columns={8} imagesCount={20} />;
    }

    if (!dataCollection || !objects) {
      return <ErrorBox message={error?.message} onReload={() => this.reload()} />
    }

    const { numberOfObjects } = dataCollection;
    const { aspectRatio } = objects[0]?.attributes || {};
    // const hasMultiplePages = objects.length < numberOfObjects;

    return (
      <Box display="flex" flexDirection="column" flex={1} component={Paper}>
        <Toolbar>
          <DataCollectionIcon />
          <Box mx={1} />
          <Typography variant="h6" noWrap>{dataCollection.name}</Typography>
          <Box mx={1} />
          <ResourceEditDialog<DataCollectionInput, DataCollection>
            title="Basic Settings"
            resource={{...dataCollection}}
            trigger={<IconButton><SettingsIcon /></IconButton>}
            action={dataCollectionInput => API.dataCollections.updateDataCollection(dataCollection.id, dataCollectionInput) }
            onSuccess={dataCollection => {
              this.setState({ dataCollection });
              onUpdate?.(dataCollection);
            }}
            renderForm={(dataCollectionInput, ref, handleSubmit) => (
              <DataCollectionForm
                dataCollectionInput={dataCollectionInput}
                innerRef={ref}
                onSubmit={handleSubmit} />
            )}
          />
          <input accept="image/*" style={{ display: 'none' }} id="upload-input" multiple type="file"
            onChange={evt => this.handleFilesDrop(dataCollection.id, pageIndex, evt.target.files) }
          />
          <label htmlFor="upload-input">
            <Tooltip title="Upload new images">
              <IconButton component="span">
                <UploadIcon />
              </IconButton>
            </Tooltip>
          </label>
          <Box mx="auto" />
          <AutoSaveBadge lastEditAt={dataCollection.updatedAt} saving={saving} />
          <IconButton onClick={() => {
            onClose?.();
          }}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
        { error && <ErrorAlert error={error} /> }
        <Divider />
        { paginating &&
          <Toolbar variant="dense">
            <Skeleton width={150} height={20} />
            <Box mx="auto" />
            <Skeleton width={150} height={20} />
          </Toolbar>
        }
        { !paginating &&
          <Toolbar variant="dense">
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: selectedImages.length > 0 ? 'red' : 'initial'}}
                  checked={selectedImages.length === objects.length}
                  indeterminate={selectedImages.length > 0 && selectedImages.length < objects.length}
                  onChange={evt => {
                    const group = this.selectableRef.current!;
                    if (selectedImages.length === 0) group.selectAll();
                    else group.clearSelection();
                  }}
                />
              }
              label={
                <Typography variant="subtitle1" noWrap>{selectedImages.length} / {objects.length} selected</Typography>
              }
            />
            <Button
              style={{color: selectedImages.length ? 'red' : undefined}}
              disabled={!selectedImages.length}
              color="primary"
              onClick={evt => this.handleImagesDeletion(dataCollection.id, pageIndex, selectedImages)}
              startIcon={<DeleteIcon />}
             >
              Delete
            </Button>
            <Box mx="auto" />
            <PaginationToolbar
              pageIndex={pageIndex}
              totalItems={numberOfObjects}
              onPageIndexChange={pageIndex => this.onPageIndexChange(dataCollection.id, pageIndex)}
            />
          </Toolbar>
        }
        <Box display="flex" flexDirection="column" style={{flex: '1 1 1px', overflowY: 'hidden'}} padding={0.5}>
          { paginating &&
            <Box style={{flex: '1 1 1px', overflowY: 'scroll'}}>
              <ImageContainer
                columns={columns}
                aspectRatio={aspectRatio}
              >
                {Array(100).fill(0).map((val, index) => (
                  <ImageTile key={index} />
                ))}
              </ImageContainer>
            </Box>
          }
          { !paginating &&
            <Box display="flex" style={{flex: '1 1 1px', overflowY: 'hidden'}} { ...{ref: this.containerRef}}>
              <ImageDragAndDrop
                style={{ flex: '1 1 1px', overflowY: 'scroll' }}
                onDrop={files => this.handleFilesDrop(dataCollection.id, pageIndex, files)}
              >
                { numberOfObjects == 0 &&
                  <Box display="flex" p={1} justifyContent="center"><Typography variant="body2" color="textSecondary">Drag and Drop Images here or use the upload button.</Typography></Box>
                }
                <ImageContainer key={dataCollection.id}
                  columns={columns}
                  aspectRatio={aspectRatio}
                  selectable
                  selectableRef={this.selectableRef}
                  onSelection={tiles => {
                    this.setState({ selectedImages: tiles.map(t => t.image) as DataCollectionObject[] });
                  }}>
                  {objects.map((image, index) => (
                    <ImageTile key={index}
                      image={image}
                      isSelected={selectedImages.indexOf(image) !== -1}
                    />
                  ))}
                </ImageContainer>
              </ImageDragAndDrop>
            </Box>
          }
        </Box>
        <Toolbar variant="dense">
          <PaginationToolbar
            pageIndex={pageIndex}
            totalItems={numberOfObjects}
          />
          <Box mx="auto" />
          <ImageZoomControl
            containerRef={this.containerRef}
            columns={columns}
            onChange={columns => {
              this.setState({ columns });
            }}
          />
        </Toolbar>
      </Box>
    )
  }
}