export enum ArtifactUploadStatus {
  Uploaded = 'uploaded',
  Pending = 'pending',
  InvalidChecksum = 'invalidChecksum'
}

export type Artifact = {
  readonly id: string;
  readonly s3PresignedUrl: string;
  readonly s3PresignedUploadUrl: string;
  readonly path: string;
  readonly fileUploadStatus: ArtifactUploadStatus;
  readonly createdAt: string;
  readonly updatedAt: string;
}