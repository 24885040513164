function buildURL (base: string, path: string, params: Record<string, string>): URL {
  let url = `${base}${path}`;
  if (params) {
    const query = new URLSearchParams(params).toString();
    url += `?${query}`;
  }
  return new URL(url);
}

function fullURLPath(url: URL): URL {
  return new URL(url.pathname, url.origin);
}

function parseURLQuery (url: URL): {[k: string]: string} {
  return Object.fromEntries(url.searchParams);
}

export {
  buildURL,
  fullURLPath,
  parseURLQuery
}