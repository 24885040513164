import React from 'react';

import {
  AxiosResponse
} from 'axios';

import {
  times
} from 'lodash';

import {
  HTTPClient
} from '../api';

import {
  Box,
  Chip,
  Toolbar,
  LinearProgress,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';

import {
  AppTopBar,
  DebugDialog
} from '../components';

import {
  computeStats
} from '../api';

import {
  titleCase
} from '../utils';

interface Props {
  path: string;
  repeat: number;
}

class State {
  production?: {
    responses: Array<PromiseSettledResult<AxiosResponse>>,
    stats: Record<string, number | undefined>
  }
  staging?: {
    responses: Array<PromiseSettledResult<AxiosResponse>>,
    stats: Record<string, number | undefined>
  }
}

const configs = {
  production: {
    baseURL: 'https://lm.dev.digilab.siemens.cloud',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'X-Api-Key': 'wYVk4Ll8XP6rmzWgW2e5C9HWWBkQ7cJf1rlisq1r'
    }
  },
  staging: {
    baseURL: 'https://lm.experimental.digilab.siemens.cloud',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'X-Api-Key': 'v8pAGZnn496BGrIrOM1yMaXibDKQ5Z5h5gGj2txp'
    }
  }
}

export class DevPerfPage extends React.Component<Props, State> {

  readonly state = new State();

  componentDidMount() {
    const { path, repeat } = this.props;

    this.setState({
      production: undefined,
      staging: undefined
    })

    // PRODUCTION
    const production = new HTTPClient(configs.production)
    const productionStartTime = Date.now();
    Promise.allSettled(times(repeat, () =>
      production.fullRequest({ method: 'GET', path })
    ))
    .then(responses => {
      this.setState({
        production: {
          responses,
          stats: {
            ...computeStats(responses),
            timeElapsed: Date.now() - productionStartTime
          }
        }
      })
    })

    // STAGING
    const staging = new HTTPClient(configs.staging)
    const stagingStartTime = Date.now();
    Promise.allSettled(times(repeat, () =>
      staging.fullRequest({ method: 'GET', path })
    ))
    .then(responses => {
      this.setState({
        staging: {
          responses,
          stats: {
            ...computeStats(responses) ,
            timeElapsed: Date.now() - stagingStartTime
          }
        }
      })
    })
  }

  render() {
    const { path, repeat } = this.props;
    const { production, staging } = this.state;

    return (
      <React.Fragment>
        <AppTopBar>
          <Typography variant="h6">
            API Performance Test - GET {path} x{repeat}
          </Typography>
          <Box mx={1} />
        </AppTopBar>
        <Box display="flex" flex={1} flexDirection="row">
          <Box flex={1}>
            <Toolbar variant="dense">
              <Typography variant="h6">
                lm.dev.digilab.siemens.cloud
              </Typography>
              <Box mx={1} />
              <Chip label="production" size="small" />
              <Box mx={1} />
              <Chip label="dynamodb" size="small" />
              <Box mx="auto" />
              { production &&
                <DebugDialog object={production} />
              }
            </Toolbar>
            <Box p={1}>
              { !production && <LinearProgress /> }
              { production &&
                <List>
                  { Object.entries(production.stats).map(([label, value]) => (
                    <ListItem key={label}>
                      <ListItemText primary={titleCase(label)} />
                      <ListItemSecondaryAction>{value !== undefined ? value : '-'}</ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              }
            </Box>
          </Box>
          <Divider orientation="vertical" />
          <Box flex={1}>
            <Toolbar variant="dense">
              <Typography variant="h6">
                lm.experimental.digilab.siemens.cloud
              </Typography>
              <Box mx={1} />
              <Chip label="staging" size="small" />
              <Box mx={1} />
              <Chip label="postgres" size="small" />
              <Box mx="auto" />
              { staging &&
                <DebugDialog object={staging} />
              }
            </Toolbar>
            <Box p={1}>
              { !staging && <LinearProgress /> }
              { staging &&
                <List>
                  { Object.entries(staging.stats).map(([label, value]) => (
                    <ListItem key={label}>
                      <ListItemText primary={titleCase(label)} />
                      <ListItemSecondaryAction>{value !== undefined ? value : '-'}</ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              }
            </Box>
          </Box>
        </Box>
      </React.Fragment>
    )
  }
}