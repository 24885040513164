import React from 'react';

import {
  withRouter,
  RouteComponentProps
} from 'react-router-dom';

import {
  Typography,
  Toolbar,
  Box,
  Divider,
  Button,
  IconButton,
  Tab,
  Tabs,
  Tooltip
} from '@material-ui/core';

import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Fingerprint as VersionIcon
} from '@material-ui/icons';

import {
  Skeleton
} from '@material-ui/lab';

import {
  isEmpty,
  omit,
} from 'lodash';

import API, {
  DataType,
  Template,
  TemplateInput
} from '../api';

import {
  LabelTypeBadge,
  TemplateIcon,
  DebugDialog,
  TemplateForm,
  ErrorPage,
  PropertyList,
  PropertyListItem,
  PropertyListSkeleton,
  LabelDefinitionChip,
  ResourceEditDialog,
  ParameterList,
  ResourceDeleteDialog
} from '../components';

import {
  formatLongDate,
  titleCase,
  uploadTemplateVersionArtifact
} from '../utils';

import moment from 'moment'

interface Params {
  templateId: string;
}

interface Props extends RouteComponentProps<Params> {
  embedded?: boolean;
  dataType?: DataType;
  onCreate: (template: Template) => void;
  onUpdate: (template: Template) => void;
  onDelete: (template: Template, permanently: boolean) => void;
}

class State {
  loading: boolean = true;
  template?: Template;
  versions?: Template[];
  versionIndex: number = 0;
  error?: Error;
}

export const TemplatePage = withRouter(class extends React.Component<Props, State> {

  readonly state = new State();

  reload = () => {
    this.setState(new State());
    this.componentDidMount();
  }

  componentDidMount() {
    const { params } = this.props.match;
    Promise.all([
      API.templates.getTemplate(params.templateId),
      API.templates.getTemplateVersions(params.templateId)
    ])
    .then(([template, versions]) => {
      const sortedVersions = versions.sort((a, b) => moment(b.createdAt).diff(a.createdAt));

      this.setState({
        loading: false,
        template,
        versionIndex: sortedVersions.findIndex(v => v.templateVersionId === template.templateVersionId),
        versions: sortedVersions
      })
    })
    .catch(error => this.setState({ loading: false, error }) )
  }

  render() {
    const { dataType, onCreate, onUpdate, onDelete } = this.props;
    const { loading, error, template, versions, versionIndex } = this.state;

    if (loading) {
      return (
        <React.Fragment>
          <Toolbar disableGutters>
            <Skeleton width={300} height={20} />
          </Toolbar>
          <Box paddingLeft={2} paddingBottom={2}>
            <Skeleton width={400} height={15} />
          </Box>
          <Box paddingLeft={2} paddingBottom={2} display="flex" flexDirection="row">
            <Skeleton width="10%" height={15} /><Box mx={1} />
            <Skeleton width="10%" height={15} /><Box mx={1} />
            <Skeleton width="20%" height={15} /><Box mx={1} />
            <Skeleton width="20%" height={15} /><Box mx={1} />
            <Skeleton width="20%" height={15} />
          </Box>
          <Divider />
          <Box padding={2}>
            <Skeleton width={100} height={20} />
            <Box my={2} />
            <PropertyListSkeleton countItems={4} secondary />
          </Box>
          <Box padding={2}>
            <Skeleton width={100} height={20} />
            <Box my={2} />
            <PropertyListSkeleton countItems={4} secondary />
          </Box>
        </React.Fragment>
      );
    } else if (error || !template || !versions) {
      return <ErrorPage message={error?.message} onReload={() => this.reload()} />
    } else {

      const version = versions[versionIndex];

      return (
        <React.Fragment>
          <Box paddingTop={1} display="flex" alignItems="center">
            <TemplateIcon />
            <Box mx={1} />
            <Typography variant="h5" noWrap>
              { version.name }
            </Typography>
            <Box mx={1} />
            <DebugDialog object={this.state} />
            <Box mx="auto" />
            <ResourceEditDialog<TemplateInput, Template>
              title="New Template Version"
              resource={{...versions[0] }}
              trigger={
                <Tooltip title="Upload new Version">
                  <Button
                    startIcon={<AddIcon />}
                    color="primary"
                  >
                    VERSION
                  </Button>
                </Tooltip>
              }
              action={resource => {
                return API.templates.createTemplateVersion(template.id, resource)
                .then(template => uploadTemplateVersionArtifact(template, resource.upload))
              }}
              onSuccess={template => {
                onCreate(template);
                this.reload();
              }}
              renderForm={(resource, ref, handleSubmit) => (
                <TemplateForm
                  uploadRequired
                  dataType={dataType}
                  templateInput={resource}
                  innerRef={ref}
                  onSubmit={handleSubmit}
                />
              )}
            />
          </Box>
          <Box paddingLeft={2}>
            <Typography color="textSecondary" variant="body1">
              { version.description }
            </Typography>
          </Box>
          {/*<Divider />*/}
          <Toolbar variant="dense">
            {<Tooltip title="Versions"><VersionIcon/></Tooltip>}
            {/*{<Typography variant="subtitle1" color="textSecondary">Versions</Typography>}*/}
            <Box mx={1} />
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              value={versionIndex}
              onChange={(evt, versionIndex) => this.setState({ versionIndex })}
            >
              { versions.map(version => (
                <Tab
                  key={version.templateVersionId}
                  label={version.version}
                  style={{minWidth: 80}}
                />
              ))}
            </Tabs>
            <Box mx="auto" />
            <ResourceEditDialog<TemplateInput, Template>
              title={`Edit Latest Template Version ${version.version}`}
              resource={omit(template, 'artifact')}
              trigger={<IconButton disabled={versionIndex > 0}><EditIcon /></IconButton>}
              action={resource => {
                return API.templates.updateTemplate(template.id, resource)
                .then(template => uploadTemplateVersionArtifact(template, resource.upload))
              }}
              onSuccess={template => {
                onUpdate(template);
                this.reload();
              }}
              renderForm={(resource, ref, handleSubmit) => (
                <TemplateForm
                  dataType={dataType}
                  templateInput={resource}
                  innerRef={ref}
                  onSubmit={handleSubmit}
                />
              )}
            />
            { versions.length > 1 &&
              <ResourceDeleteDialog<Template>
                title={`Delete Latest Template Version ${version.version}`}
                resource={template}
                trigger={<IconButton disabled={versionIndex > 0}><DeleteIcon /></IconButton>}
                action={resource => API.templates.deleteTemplate(resource.id) }
                onSuccess={template => {
                  onDelete(template, versions.length === 1);
                  this.reload();
                }}
              >
                Only this specific template version will be deleted.
              </ResourceDeleteDialog>
            }
            { versions.length === 1 &&
              <ResourceDeleteDialog<Template>
                title={`Delete Template`}
                resource={template}
                trigger={<IconButton><DeleteIcon /></IconButton>}
                action={resource => API.templates.deleteTemplate(resource.id) }
                onSuccess={template => {
                  onDelete(template, versions.length === 1);
                }}
              >
              </ResourceDeleteDialog>
            }
          </Toolbar>
          {<Divider />}
          <Box display="flex" flexDirection="column" flex={1} style={{overflowY: 'scroll'}} padding={2}>
            <Box marginBottom={2}>
              <Typography variant="h6" paragraph>General Information</Typography>
              <PropertyList>
                {/*<PropertyListItem name="Visibility" description="Who else can use this template">
                  {template.user ? 'Only visible to me' : 'Public' }
                </PropertyListItem>*/}
                <PropertyListItem name="Version" description="The unique release version ID">
                  {version.version}
                </PropertyListItem>
                <PropertyListItem name="Data Type" description="Template algorithm data type">
                  {titleCase(version.dataType)}
                </PropertyListItem>
                <PropertyListItem name="Type" description="Template algorithm category">
                  {version.templateType}
                </PropertyListItem>
                <PropertyListItem name="Training Required" description="Is a training required ?">
                  {version.trainingRequired ? 'Yes' : 'No'}
                </PropertyListItem>
                <PropertyListItem name="Added on" description="Date template was added">
                  {formatLongDate(version.createdAt)}
                </PropertyListItem>
                <PropertyListItem name="Last updated on" description="Date template was last updated">
                  {formatLongDate(version.updatedAt)}
                </PropertyListItem>
              </PropertyList>
            </Box>
            { version.settings.labelSettings &&
              <Box marginBottom={2}>
                <Typography variant="h6" paragraph>Label Settings</Typography>
                <PropertyList>
                  <PropertyListItem name="Label Annotation Type" description="Bounding Boxes, Classification or Segmentation">
                    <LabelTypeBadge labelType={version.settings.labelSettings.labelConfiguration.labelType} showAvatar />
                  </PropertyListItem>
                  <PropertyListItem name="Label Definitions" description="Predefined label definitions">
                    { version.settings.labelSettings.labelConfiguration.labelDefinitions.map((label, index, definitions) => (
                      <LabelDefinitionChip key={index}
                        labelId={label.id}
                        labelDefinitions={definitions}
                        labelType={version.settings.labelSettings.labelConfiguration.labelType}
                        style={{marginLeft: 8}}
                      />
                    )) }
                  </PropertyListItem>
                </PropertyList>
              </Box>
            }
            <Box marginBottom={2}>
              <Typography variant="h6" paragraph>Artifact</Typography>
              { version.codeArtifact &&
                <PropertyList>
                  <PropertyListItem name="Upload Date" description="The latest artifact upload date">
                    {formatLongDate(version.codeArtifact.createdAt)}
                  </PropertyListItem>
                  <PropertyListItem name="Upload Status" description="The latest artifact upload status">
                    {version.codeArtifact.fileUploadStatus}
                  </PropertyListItem>
                  { version.codeArtifact.s3PresignedUrl &&
                    <PropertyListItem name="URL" description="The latest artifact S3 URL">
                      <Tooltip title={version.codeArtifact.s3PresignedUrl} style={{maxWidth: 'none'}}>
                        <Typography noWrap style={{maxWidth: 250}} variant="body2">{version.codeArtifact.s3PresignedUrl}</Typography>
                      </Tooltip>
                    </PropertyListItem>
                  }
                </PropertyList>
              }
              { !version.codeArtifact &&
                <Box px={2}>
                  <Typography paragraph variant="body2">Not yet uploaded.</Typography>
                </Box>
              }
            </Box>
            { !isEmpty(version.settings?.commonsSchema) &&
              <Box marginBottom={2}>
                <Typography variant="h6" paragraph>Common Parameters</Typography>
                <ParameterList
                  schemas={version.settings.commonsSchema}
                  values={version.settings.commons}
                />
              </Box>
            }
            { !isEmpty(version.settings?.trainingSchema) &&
              <Box marginBottom={2}>
                <Typography variant="h6" paragraph>Training Parameters</Typography>
                <ParameterList
                  schemas={version.settings.trainingSchema}
                  values={version.settings.training}
                />
              </Box>
            }
            { !isEmpty(version.settings?.predictionSchema) &&
              <Box marginBottom={2}>
                <Typography variant="h6" paragraph>Prediction Parameters</Typography>
                <ParameterList
                  schemas={version.settings.predictionSchema}
                  values={version.settings.prediction}
                />
              </Box>
            }
          </Box>
        </React.Fragment>
      );
    }
  }
});