import moment from 'moment';

export const formatLongDate = (date: string): string => {
  return moment.utc(date).format('MMM D YYYY, HH:mm');
}

export const formatShortDate = (date: string): string => {
  const m = moment.utc(date);
  const daysAgo = moment.utc().diff(m, 'days');
  if (daysAgo < 1) return m.format('HH:mm');
  else if (daysAgo < 365) return m.format('MMM DD');
  else return m.format('YYYY');
}

export const formatTimeAgo = (date: string): string => {
  return moment.utc(date).fromNow();
}