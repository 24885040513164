import React from 'react';

import {
  Formik,
  FormikProps,
  FormikErrors,
  Form
} from 'formik';

import {
  TextField
} from '@material-ui/core';

import {
  AspectInput
} from '../api';

interface Props {
  aspectInput: AspectInput;
  innerRef: any;
  onSubmit: (aspectInput: AspectInput) => void;
}

export class AspectForm extends React.PureComponent<Props> {
  render() {
    const { aspectInput, innerRef, onSubmit } = this.props;
    return (
      <Formik
        innerRef={innerRef}
        initialValues={aspectInput}
        validate={ (values: AspectInput) => {
          const errors: FormikErrors<AspectInput> = {};
          if (!values.name)
            errors.name = 'Required';

          return errors;
        }}
        onSubmit={onSubmit}
      >
      { (props: FormikProps<AspectInput>)  => {
        const { values, errors, touched, handleChange } = props;

        return (
          <Form autoComplete="off" noValidate>
            <TextField
              required
              margin="normal"
              name="name"
              label="Name"
              value={values.name}
              onChange={handleChange}
              fullWidth
              placeholder=""
              error={touched.name && !!errors.name}
              helperText={errors.name || 'Choose a unique name'}
              InputLabelProps={{shrink: true}}
              inputProps={{ maxLength: 150 }}
            />
            <TextField
              margin="normal"
              name="description"
              label="Description"
              value={values.description}
              onChange={handleChange}
              fullWidth
              placeholder=""
              error={touched.description && !!errors.description}
              helperText={errors.description}
              InputLabelProps={{shrink: true}}
              inputProps={{ maxLength: 250 }}
            />
          </Form>
        );
      } }
      </Formik>
    );
  }
}