import API, {
  DataCollectionObject
} from '../api';

export const loadDataCollectionObjectsPage = (dataCollectionId: string, pageIndex: number = 0) => {
  const take = 100, skip = take * pageIndex;
  return API.dataCollections.getDataCollectionObjects(dataCollectionId, { take, skip, ordering: 'asc' });
}

export const loadDataCollectionPageObjectsAndDatasetIds = (
    dataCollectionId: string,
    datasetVersionId: string,
    pageIndex: number = 0
  ): Promise<[Array<DataCollectionObject>, Array<string>]> => {

  return loadDataCollectionObjectsPage(dataCollectionId, pageIndex)
  .then(dataCollectionObjects => {
    const dataCollectionObjectsIds = dataCollectionObjects.map(o => o.id);
    return API.datasets.searchDatasetVersionObjects(datasetVersionId, dataCollectionObjectsIds)
    .then(datasetObjects => {
      return [ dataCollectionObjects, datasetObjects.map(o => o.id) ];
    })
  });
}