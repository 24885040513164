import React from 'react';

import {
  Box,
  List,
  Toolbar,
  Typography
} from '@material-ui/core';

import API, {
  Template,
  DataType
} from '../api';

import {
  TemplateIcon,
  TemplateList,
  TemplateListSkeleton,
  ErrorBox,
  SearchField
} from '../components';

import {
  filterTemplates
} from '../utils';

interface Props {
  selection?: Template;
  onSelection?: (template?: Template) => void;
  style?: React.CSSProperties;
}

class State {
  loading: boolean = true;
  error?: Error;
  templates?: Template[];
  selection?: Template;
  searchText: string = '';

  constructor(props: Props) {
    this.selection = props.selection;
  }
}

export class TemplatePicker extends React.Component<Props, State> {

  readonly state = new State(this.props);

  componentDidMount () {
    this.setState({ loading: true, error: undefined, templates: undefined });
    API.templates.listTemplates({ dataType: DataType.Images })
    .then(templates => this.setState({ templates, loading: false }))
    .catch(error => this.setState({ error, loading: false }))
  }

  toggleSelection = (selection: Template) => {
    this.setState({ selection });
    this.props.onSelection?.(selection);
  }

  render() {
    const { style } = this.props;
    const { templates, selection, loading, error, searchText } = this.state;

    const filteredTemplates = templates && filterTemplates(templates, searchText, selection);

    return (
      <Box display="flex" flexDirection="column" style={style}>
        <Toolbar variant="dense">
          <TemplateIcon />
          <Box mx={1} />
          <Typography variant="subtitle1">Pick a Template</Typography>
          <Box mx={3} />
          { templates && templates.length > 0 &&
            <SearchField
              autoFocus
              value={searchText}
              onChange={searchText => this.setState({ searchText })}
            />
          }
        </Toolbar>
        <Box flex={1} display="flex" flexDirection="column">
          <Box style={{flex: '1 1 1px', overflowY: 'scroll'}}>
            <List dense disablePadding>
              { loading && <TemplateListSkeleton countItems={8} /> }
              { error && <ErrorBox message={error?.message} onReload={() => this.componentDidMount()} /> }
              { filteredTemplates &&
                <TemplateList
                  showVersions
                  items={filteredTemplates}
                  selection={selection}
                  onSelection={version => this.toggleSelection(version)}
                />
              }
            </List>
          </Box>
          { templates && !templates.length &&
            <Box p={2}>
              <Typography color="textSecondary" variant="body1">No Templates available.</Typography>
            </Box>
          }
          {/*<Divider />
          { templates && filteredTemplates && filteredTemplates.length > 0 &&
            <Typography variant="caption" color="textSecondary">Showing { filteredTemplates.length } out of { templates.length } templates.</Typography>
          }*/}
        </Box>
      </Box>
    );
  }
}