import React from 'react';

import {
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';

import {
  Add as AddIcon
} from '@material-ui/icons';

import {
  AssetForm,
  AssetItem,
  ResourceEditDialog
} from '../components';

import API, {
  Asset,
  AssetInput
} from '../api';

interface Props {
  assets: Asset[];
  onCreate: (asset: Asset) => void;
}

export class AssetList extends React.Component<Props> {

  render() {
    const { assets } = this.props;

    return (
      <List dense
        subheader={<ListSubheader disableSticky>PRODUCTION LINES</ListSubheader>}
      >
        { assets.sort((a, b) => a.name.localeCompare(b.name)).map((asset) => (
          <AssetItem key={asset.id}
            asset={asset}
            href={`/assets/${asset.id}`}
          />
        )) }
        <ResourceEditDialog<AssetInput, Asset>
          title="Add Production Line"
          resource={{ name: '', description: '' }}
          trigger={
            <ListItem key="add" button>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Add new..." color="primary" />
            </ListItem>
          }
          action={asset => API.assets.createAsset(asset) }
          onSuccess={this.props.onCreate}
          renderForm={(assetInput, ref, handleSubmit) => (
            <AssetForm
              assetInput={assetInput}
              innerRef={ref}
              onSubmit={handleSubmit} />
          )}
        />
      </List>
    );
  }
}