import { Aspect } from '../api';

import { searchCase } from '../utils';

export const filterAspectsByText = (aspects: Aspect[], text: string = ''): Aspect[] => {
  const searchText = searchCase(text);

  return aspects
    .filter(a => {
      return !searchText.length
        || searchCase(`${a.name} ${a.description}`).indexOf(searchText) !== -1;
    });
}