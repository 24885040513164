import { Asset, AssetInput } from '../models';

import { pick } from 'lodash';

import HTTPClient from '../http-client';

export class Assets extends HTTPClient {

  listAssets (): Promise<Array<Asset>> {
    return this.request({
      method: 'get',
      path: `/assets`
    })
  }

  createAsset (assetInput: AssetInput): Promise<Asset> {
    return this.request({
      method: 'post',
      path: `/assets`,
      body: pick(assetInput, 'name', 'description')
    })
  }

  getAsset(assetId: string): Promise<Asset> {
    return this.request({
      method: 'get',
      path: `/assets/${assetId}`,
    })
  }

  updateAsset(assetId: string, assetInput: AssetInput): Promise<Asset> {
    return this.request({
      method: 'put',
      path: `/assets/${assetId}`,
      body: pick(assetInput, 'name', 'description')
    })
  }

  deleteAsset(assetId: string): Promise<void> {
    return this.request({
      method: 'delete',
      path: `/assets/${assetId}`
    })
  }
}