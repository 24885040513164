import React from 'react';

import {
  withRouter,
  Route,
  RouteComponentProps,
  Switch
} from 'react-router-dom';

import {
  DataCollectionManager,
} from '../components';

interface Props extends RouteComponentProps {
  path: string;
  dataSourceReferenceId?: string;
}

export const DataCollectionRouter = withRouter(class extends React.Component<Props> {

  render() {
    const { path, dataSourceReferenceId } = this.props;

    return (
      <Switch>
        <Route exact path={`${path}`}>
          <DataCollectionManager
            dataSourceReferenceId={dataSourceReferenceId}
          />
        </Route>
      </Switch>
    );
  }
});