import React from 'react';

import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from 'react-router-dom';

import {
  AspectRouter,
  CanvasTestPage,
  DevQueryPage,
  DevPerfPage,
  DataCollectionRouter,
  DatasetRouter,
  LabelsetRouter,
  TemplatesRouter,
  TemplateInstanceRouter
} from '../components';

import {
  DataType
} from '../api';

interface Props extends RouteComponentProps {}

export const DevRouter = withRouter(class extends React.Component<Props> {

  render() {
    return (
      <Switch>
        <Route exact path="/dev/query" render={(props) => (
          <DevQueryPage />
        )} />
        <Route exact path="/dev/canvas" render={(props) => (
          <CanvasTestPage />
        )} />
        <Route exact path="/dev/perf" render={(props) => (
          <DevPerfPage
            path={new URLSearchParams(props.location.search).get('path') || 'assets'}
            repeat={parseInt(new URLSearchParams(props.location.search).get('repeat') || '10')}
          />
        )} />
        <Route path="/dev/templates" render={(props) => (
          <TemplatesRouter
            path="/dev/templates"
            dataType={new URLSearchParams(props.location.search).get('dataType') as DataType || undefined}
          />
        )} />
        <Route path="/dev/aspects/:aspectId" render={(props) => (
          <AspectRouter
            path="/dev/aspects"
            aspectId={props.match.params.aspectId}
          />
        )} />
        <Route path="/dev/data-collections/:dataCollectionId?" render={(props) => (
          <DataCollectionRouter
            path="/dev/data-collections"
            dataSourceReferenceId={new URLSearchParams(props.location.search).get('dataSourceReferenceId') || undefined}
          />
        )} />
        <Route path="/dev/datasets/:datasetId?" render={(props) => (
          <DatasetRouter
            path="/dev/datasets"
            dataCollectionId={new URLSearchParams(props.location.search).get('dataCollectionId') || undefined}
          />
        )} />
        <Route path="/dev/labelsets/:labelsetId?" render={(props) => (
          <LabelsetRouter
            path="/dev/labelsets"
            datasetVersionId={new URLSearchParams(props.location.search).get('datasetVersionId') || undefined}
            templateVersionId={new URLSearchParams(props.location.search).get('templateVersionId') || undefined}
          />
        )} />
        <Route path="/dev/template-instances/:templateInstanceId?" render={(props) => (
          <TemplateInstanceRouter
            path="/dev/template-instances"
            dataSourceReferenceId={new URLSearchParams(props.location.search).get('dataSourceReferenceId') || undefined}
          />
        )} />
      </Switch>
    );
  }
});