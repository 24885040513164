import React from 'react';

import {
  Formik,
  FormikProps,
  FormikErrors,
  Form
} from 'formik';

import {
  pick
} from 'lodash';

import {
  Box,
  Grid,
  Toolbar,
  Typography,
  FormControl,
  FormLabel
} from '@material-ui/core';

import {
  TemplateInstanceInput,
  Aspect,
  DeploymentInput
} from '../api';

import {
  DebugDialog,
  DevicesList
} from '../components';

interface Props {
  templateInstanceInput: TemplateInstanceInput;
  aspect: Aspect;
  innerRef: any;
  onSubmit: (templateInstanceInput: TemplateInstanceInput) => void;
}

export class TemplateInstancePredictionForm extends React.PureComponent<Props> {

  render() {
    const { aspect, templateInstanceInput, innerRef, onSubmit } = this.props;

    return (
      <Formik
        innerRef={innerRef}
        initialValues={{...templateInstanceInput}}
        validate={ (values: TemplateInstanceInput) => {
          const errors: FormikErrors<TemplateInstanceInput> = {};
          Object.keys(values.settings.predictionSchema || {}).forEach(name=> {
            if (`${values.settings.prediction[name]}` === '') {
              errors.settings = errors.settings || {};
              errors.settings.prediction = errors.settings.prediction || {};
              errors.settings.prediction[name] = 'Required';
            }
          });
          if (!values.latestDeployment || !values.latestDeployment.inputs || values.latestDeployment.inputs.length === 0) {
            errors.latestDeployment = 'Select at least one Device/Source';
          }
          return errors;
        }}
        onSubmit={onSubmit}
      >
      { (props: FormikProps<TemplateInstanceInput>)  => {
        const { values, errors, touched, setFieldValue } = props;

        return (
          <Form autoComplete="off" noValidate>
            <Grid
              spacing={3}
              container
              direction="row"
            >
              <Grid item xs>
                <Toolbar variant="dense" disableGutters>
                  <Typography variant="subtitle2">DEVICE / SOURCES</Typography>
                  <Box mx="auto" />
                  <DebugDialog object={values.latestDeployment} />
                </Toolbar>
                <FormControl
                  margin="normal"
                  fullWidth
                  size="small"
                  error={touched.latestDeployment && !!errors.latestDeployment}>
                  <FormLabel>
                    <Typography variant="caption">
                      Select 1 to multiple sources inside 1 Device.
                    </Typography>
                  </FormLabel>
                  <DevicesList
                    devices={aspect.attributes.devices}
                    deploymentInput={pick(values.latestDeployment, 'stage', 'inputs', 'runtimeId') as DeploymentInput}
                    onChange={options => {
                      setFieldValue('latestDeployment', options);
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Form>
        );
      } }
      </Formik>
    );
  }
}