import React from 'react';

import preval from 'preval.macro';

import {
  AppBar,
  Container,
  Toolbar,
  IconButton
} from '@material-ui/core';

import {
  Menu as MenuIcon
} from '@material-ui/icons';

import {
  AppDrawer
} from '../components';

interface Props {
  children: React.ReactNode | undefined;
}

export class AppTopBar extends React.Component<Props> {

  render() {
    const { children } = this.props;
    const production = preval`process.env.APP_ENV` === 'production';

    return (
      <React.Fragment>
        <AppBar color={production ? 'secondary' : 'primary'}>
          { children && (
          <Container>
            <Toolbar disableGutters>
              <AppDrawer
                trigger={
                  <IconButton edge="start" color="inherit">
                    <MenuIcon />
                  </IconButton>
                }
              />
              { children }
            </Toolbar>
          </Container>
          )}
        </AppBar>
        <Toolbar />
      </React.Fragment>
    )
  }
}