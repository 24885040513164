// @ts-nocheck
import React from 'react';

import {
  fabric
} from 'fabric';

import {
  DataCollectionObject,
  LabelsetEntry,
  Label,
  LabelDefinition,
  LabelType,
  PolygonLabel,
  TRBLLabel,
  deepEqual
} from '../api';

import {
  lookupLabelDefinitionAndColor
} from '../utils';

interface Dimensions {
  left: number;
  top: number;
  width: number;
  height: number;
}

interface Props {
  labelsetEntry: LabelsetEntry;
  labelDefinitions: LabelDefinition[];
  labelType: LabelType;
  aspectRatio: number;
  image: DataCollectionObject;
  cropBox: TRBLLabel | undefined;
  cropMode?: boolean;
  canDelete?: boolean;
  style?: React.CSSProperties;
  selection?: Label[];
  onCropChange?: (cropBox?: TRBLLabel) => void
  onAddition?: (annotation: LabelsetEntry, additions: Label[]) => void;
  onDeletion?: (annotation: LabelsetEntry, deletions: Label[]) => void;
  onChange?: (annotation: LabelsetEntry) => void;
  onSelection?: (labels: Label[]) => void;
}

class State { }

export class ImageCanvas extends React.Component<Props, State> {

  readonly state = new State();

  private canvasRef = React.createRef<HTMLDivElement>();

  private canvas: fabric.Canvas = new fabric.Canvas(null);

  private img?: fabric.Image;

  private drawingDisabled: boolean = false;
  private drawingShape?: fabric.Rect | fabric.Polygon;
  private drawingPoints: fabric.Circle[] = [];

  componentDidMount() {
    const { image, labelType } = this.props;

    const container = this.canvasRef.current;

    if (container) {
      this.canvas = new fabric.Canvas(container.querySelector('canvas'), {
        backgroundColor: '#EFEFEF',
        selection: false,
        defaultCursor: 'crosshair'
      });

      // prevent objects to go outside of canvas
      this.canvas.on('before:render', this.dragLimitSelection.bind(this));

      // on mouse down start/continue drawing region shape
      if (this.props.cropMode || labelType === LabelType.BoundingBoxes) {
        this.canvas.on('mouse:up', this.drawRectangle.bind(this));
        this.canvas.on('mouse:move', this.redrawRectangle.bind(this));
        this.canvas.on('selection:created', this.controlRectangle.bind(this))
      } else if (labelType === LabelType.Segmentation) {
        this.canvas.on('mouse:up', this.drawPolygon.bind(this));
        this.canvas.on('mouse:move', this.redrawPolygon.bind(this));
        this.canvas.on('selection:created', this.controlPolygon.bind(this))
      }

      // update coordinates after any object modification
      (this.canvas as any).on('object:modified', this.updateCoords.bind(this));

      this.canvas.on('selection:created', this.highlightSelection.bind(this));
      // this.canvas.on('selection:updated', this.highlightSelection.bind(this));
      this.canvas.on('selection:cleared', this.clearSelection.bind(this));

      window.addEventListener('resize', this.resizeCanvas.bind(this));
      window.addEventListener('keydown', this.handleKeyDown.bind(this));

      this.resizeCanvas();
      this.redraw();

      this.loadImage(image.attributes.previewUrl);
    }
  }

  componentDidUpdate(prevProps: Props) {
    console.debug('componentDidUpdate');

    if (!this.canvas)
      return;

    const { selection, labelsetEntry } = this.props;

    if (!deepEqual(prevProps.labelsetEntry, labelsetEntry)) {
      this.redraw();
    }
    else if (selection !== undefined && !deepEqual(prevProps.selection, selection)) {
      this.applySelection(selection);
    }
  }

  componentWillUnmount() {
    if (this.canvas) {
      window.removeEventListener('resize', this.resizeCanvas.bind(this));
    }
  }

  render() {
    const { style } = this.props;

    return (
      <div ref={this.canvasRef} style={style}>
        <canvas />
      </div>
    );
  }

  computeCrop () {
    const fullsize = { left: 0, top: 0, right: 1, bottom: 1 };
    if (this.props.cropMode)
      return fullsize;
    else
      return this.props.cropBox || fullsize;
  }

  loadImage (url: string) {
    console.debug('loadImage');

    fabric.Image.fromURL(url, (img) => {
      this.img = img;

      this.resizeCanvas();
    });
  }

  updateCoords () {
    console.debug('updateCoords');

    const { labelType } = this.props;

    const activeRegions = this.canvas.getActiveObjects();
    if (labelType === LabelType.BoundingBoxes) {
      activeRegions.forEach(region => this.updateBoundingBoxesCoords(region as fabric.Rect));
    } else if (labelType === LabelType.Segmentation) {
      activeRegions.forEach(region => this.updateSegmentationCoords(region as fabric.Polygon));
    }

    this.notifyChanges([], []);
    this.applySelection(activeRegions.map(a => a.data));
  }

  /** GENERIC **/

  handleKeyDown (evt: KeyboardEvent) {
    if (evt.key === 'Escape') {
      this.cancelDrawingShape();
      this.cancelSelection();
    }
  }

  cancelDrawingShape() {
    console.debug('cancelDrawingShape');

    if (this.drawingPoints.length) {
      this.drawingPoints.forEach(pt => this.canvas.remove(pt));
      this.drawingPoints = [];
    }
    if (this.drawingShape) {
      this.canvas.remove(this.drawingShape);
      this.drawingShape = undefined;
    }
  }

  cancelSelection() {
    this.canvas.discardActiveObject();
    this.canvas.requestRenderAll();
  }

  // list all existing regions
  getRegions() {
    const objects = this.canvas.getObjects();
    const regions = objects.filter(o => o.selectable);
    return regions;
  }

  notifyChanges(additions: Array<fabric.Rect | fabric.Polygon>, deletions: Array<fabric.Rect | fabric.Polygon>) {
    console.debug('notifyChanges', additions, deletions);

    const { onChange, onAddition, onDeletion } = this.props;
    console.debug('notifyChanges');
    if (onChange) {
      const regions = this.getRegions();
      const labels = regions.map(obj => {
        const data = obj.data as Label;
        return data;
      })
      const data = {
        ...this.props.labelsetEntry,
        labels
      }
      if (additions.length > 0 && onAddition)
        onAddition(data, additions.map(a => a.data as Label));
      else if (deletions.length > 0 && onDeletion)
        onDeletion(data, deletions.map(a => a.data as Label));
      else
        onChange(data);
    } else if (this.props.cropMode && this.props.onCropChange) {
      const cropRegion = this.getRegions()[0];
      if (!cropRegion) {
        this.props.onCropChange(undefined);
      } else {
        const dimensions = cropRegion.getBoundingRect(true, true);
        const cropBox = this.rectangleDimensionsToBoundingBoxCoords(dimensions);
        this.props.onCropChange(cropBox);
      }
    }
  }

  redraw () {
    console.debug('redraw');

    const { labelsetEntry, cropBox, selection, labelType } = this.props;

    console.debug('redraw');

    // clear canvas
    const regions = this.getRegions();
    this.canvas.remove(...regions);
    this.canvas.discardActiveObject();

    // adding regions
    if (labelType === LabelType.BoundingBoxes) {
      labelsetEntry.labels.forEach(label => {
        const bounds = label.labelAnnotation as TRBLLabel;
        const dimensions = this.boundingBoxCoordsToRectangleDimensions(bounds);
        const region = this.createBoundingBox(dimensions, label);
        if (this.props.cropMode) {
          region.selectable = false;
          region.evented = false;
        }
        this.canvas.add(region);
      })
    } else if (labelType === LabelType.Segmentation) {
      labelsetEntry.labels.forEach(label => {
        const region = this.createSegmentation(label);
        if (this.props.cropMode) {
          region.selectable = false;
          region.evented = false;
        }
        this.canvas.add(region);
      })
    }

    // adding crop box in crop mode
    if (this.props.cropMode && cropBox) {
      const dimensions = this.boundingBoxCoordsToRectangleDimensions(cropBox);
      const cropRegion = this.createBoundingBox(dimensions)
      this.canvas.add(cropRegion);
      this.canvas.setActiveObject(cropRegion);
    }

    if (this.props.cropMode) {
      const cropRegion = this.getRegions()[0];
      this.canvas.defaultCursor = cropRegion ? 'not-allowed' : 'crosshair';
    }

    if (selection) {
      this.applySelection(selection);
    }

    this.canvas.requestRenderAll();
  }

  applySelection (selection: Label[]) {
    console.debug('applySelection', selection);

    const regions = this.getRegions();
    const activeRegions = regions.reduce((r, region) => {
      // skip region if already found active to prevent
      // selecting multiple regions with identical coordinates
      if (r.find(ar => deepEqual(ar.data, region.data)))
        return r;

      // add region if matches selection
      if (selection.find(sr => deepEqual(sr, region.data)))
        r.push(region);

      return r;
    }, new Array<fabric.Object>());

    this.canvas.discardActiveObject(undefined);
    if (activeRegions.length == 1 ) {
      this.canvas.setActiveObject(activeRegions[0]);
    } else if (activeRegions.length > 1) {
      const activeGroup = new fabric.ActiveSelection(activeRegions, {
        canvas: this.canvas,
        originX: 'left',
        originY: 'top',
      });
      activeGroup.setControlsVisibility({ mtr: false });
      this.canvas.setActiveObject(activeGroup);
    }
    this.canvas.requestRenderAll();
  }

  notifySelection () {
    console.debug('notifySelection');

    const { onSelection } = this.props;
    if (!onSelection)
      return;

    console.debug('notifySelection');

    const activeRegions = this.canvas.getActiveObjects();
    const selection = activeRegions.map(region => region.data as Label);
    onSelection(selection);
  }

  static getObjectSizeWithStroke(object) {
    var stroke = new fabric.Point(
      object.strokeUniform ? 1 / object.scaleX : 1,
      object.strokeUniform ? 1 / object.scaleY : 1
    ).multiply(object.strokeWidth);
    return new fabric.Point(object.width + stroke.x, object.height + stroke.y);
  }

  highlightSelection (o: fabric.IEvent) {
    console.debug('highlightSelection', o);

    this.notifySelection();

    // prevent default event so that delete control is not triggered automatically
    if (o.e)
      o.e.preventDefault();

    return true;
  }

  clearSelection (o: fabric.IEvent) {
    console.debug('clearSelection');

    this.notifySelection();

    // FIXME: re-enable if necessary
    // this.drawingDisabled = true;
  }

  dragLimitSelection () {
    console.debug('dragLimitSelection');
    const obj = this.canvas.getActiveObject();
    if (obj) {
      const b = obj.getBoundingRect();
      const c = this.canvas;

      // compute left and top based on canvas dimensions
      const adjustedLeft = Math.max(0,  Math.min(b.left, c.width! - b.width));
      const adjustedTop = Math.max(0, Math.min(b.top, c.height! - b.height));

      obj.set({
        left: obj.left! - b.left + adjustedLeft,
        top: obj.top! - b.top + adjustedTop
      })
      obj.setCoords();
    }
  }

  /* CONTROLS */

  addDeleteControl(object: fabric.Object) {
    console.debug('addDeleteControl');

    var icon = document.createElement('img');
    icon.src = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:black;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";

    object.controls.deleteControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: -12,
      offsetX: 7,
      cursorStyle: 'pointer',
      mouseDownHandler: (eventData, transform) => {
        if (eventData.defaultPrevented)
          return true;

        var activeObjects = this.canvas.getActiveObjects();
        if (activeObjects) {
          this.canvas.discardActiveObject();
          this.canvas.remove(...activeObjects);
          this.notifyChanges([], activeObjects);
        }
        return true;
      },
      render: (ctx, left, top, styleOverride, fabricObject) => {
        var size = 18;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle!));
        ctx.drawImage(icon, -size/2, -size/2, size, size);
        ctx.restore();
      },
    });
  }

  /* RESIZING */

  resizeObject (obj: fabric.Object, ratioX: number, ratioY: number) {
    console.debug('resizeObject');

    obj.set({
      scaleX: obj.scaleX! * ratioX,
      scaleY: obj.scaleY! * ratioY,
      left: obj.left! * ratioX,
      top: obj.top! * ratioY
    })
    obj.setCoords();
  }

  resizeCanvas () {
    console.debug('resizeCanvas');

    if (!this.canvasRef.current)
      return;

    const crop = this.computeCrop();

    // retrieve parent container available width/height and compute aspect ratio
    const { clientWidth, clientHeight } = this.canvasRef.current!;
    const clientRatio = clientWidth / clientHeight;

    // compute image and new aspect ratio based
    const originalRatio = this.props.aspectRatio; //img.width! / img.height!;
    const aspectRatio = originalRatio * (crop.right - crop.left) / (crop.bottom - crop.top);

    // compute new canvas width and height
    const canvasWidth = clientRatio < aspectRatio ? clientWidth : clientHeight * aspectRatio;
    const canvasHeight = clientRatio < aspectRatio ? clientWidth / aspectRatio : clientHeight;

    // compute canvas dimensions change against previous values
    const canvasWidthChange = canvasWidth / this.canvas.width!;
    const canvasHeightChange = canvasHeight / this.canvas.height!;

    // apply new canvas dimensions
    this.canvas.setDimensions({ width: canvasWidth, height: canvasHeight });

    const img = this.img;
    if (img) {
      // compute new image scales
      const imgScaleX = 1 / (crop.right - crop.left) * (canvasWidth / img.width!);
      const imgScaleY = 1 / (crop.bottom - crop.top) * (canvasHeight / img.height!);

      // apply background image
      this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas), {
        originX: 'left',
        originY: 'top',
        scaleX: imgScaleX,
        scaleY: imgScaleY,
        left: -crop.left * imgScaleX * img.width!,
        top: -crop.top * imgScaleY * img.height!,
        // opacity: 0.5
      });
    }

    // resize existing objects based on canvas scale change
    this.canvas.forEachObject((obj) => {
      this.resizeObject(obj, canvasWidthChange, canvasHeightChange)
    });

    // redraw
    this.canvas.requestRenderAll();
  }

  /** BOUNDING BOXES **/

  controlRectangle (o: fabric.IEvent) {
    const rectangle = o.target as fabric.Rect;
    this.addDeleteControl(rectangle);
  }

  drawRectangle (o: fabric.IEvent) {
    console.debug('drawRectangle', o);

    // discard when in crop mode and one region has already been drawn
    if (this.props.cropMode && this.getRegions().length !== 0)
      return;

    if (this.drawingDisabled) {
      this.drawingDisabled = false;
    }
    else if (this.drawingShape) {
      this.drawBoundingBox(o);
      this.cancelDrawingShape();
    }
    else if (!o.target) {
      const pointer = this.canvas.getPointer(o.e);
      this.drawingShape = new fabric.Rect({
        originX: 'left',
        originY: 'top',
        objectCaching: false,
        stroke: '#FF0000',
        strokeWidth: 3,
        opacity: 0.75,
        fill: 'transparent',
        hasBorders: false,
        hasControls: false,
        transparentCorners: true,
        selectable: false,
        evented: false,
        left: pointer.x,
        top: pointer.y,
        width: 0,
        height: 0,
        perPixelTargetFind: false
      });
      this.canvas.add(this.drawingShape);
    }
  }

  redrawRectangle(o: fabric.IEvent) {
    if (!this.drawingShape)
      return;

    console.debug('redrawRectangle', o);

    const rect = this.drawingShape as fabric.Rect;

    const pointer = this.canvas.getPointer(o.e);

    const r = rect.getBoundingRect();

    if (pointer.x < r.left){
      rect.set({
        width: r.left - Math.max(0, pointer.x),
        left: Math.max(0, pointer.x)
      });
    } else if (pointer.x > r.left) {
      rect.set({
        width: Math.min(this.canvas.width!, pointer.x) - r.left
      });
    }
    if(pointer.y < r.top) {
      rect.set({
        height: r.top - Math.max(0, pointer.y),
        top: Math.max(0, pointer.y),
      });
    } else if (pointer.y > r.top) {
      rect.set({
        height: Math.min(this.canvas.height!, pointer.y) - r.top
      })
    }
    this.canvas.requestRenderAll();
  }

  drawBoundingBox (o: fabric.IEvent) {
    if (!this.drawingShape)
      return;

    console.debug('drawBoundingBox', o);

    // compute bounding rect
    const r = this.drawingShape.getBoundingRect(true, true);

    // discard small drawings
    if (r.width < 10 || r.height < 10)
      return;

    const { labelDefinitions } = this.props;
    const defaultLabelDefinition = labelDefinitions[0];

    /****/
    const label: Label = {
      labelId: defaultLabelDefinition.id,
      type: 'TRBLLabel',
      labelAnnotation: this.rectangleDimensionsToBoundingBoxCoords(r)
    };

    const newRegion = this.createBoundingBox(r, this.props.cropMode ? undefined : label);

    this.canvas.add(newRegion);
    this.canvas.setActiveObject(newRegion);

    this.notifyChanges([newRegion], []);
    this.applySelection([newRegion.data]);
  }

  createBoundingBox (options: Dimensions, label?: Label) {
    console.debug('createBoundingBox', options, label);

    const region = new fabric.Rect({
      originX: 'left',
      originY: 'top',
      objectCaching: false,
      strokeWidth: 3,
      strokeUniform: true,
      opacity: 0.5,
      borderScaleFactor: 2,
      hasBorders: true,
      hasControls: true,
      borderColor: 'black',
      cornerSize: 8,
      cornerStyle: 'rect',
      cornerColor: 'white',
      cornerStrokeColor: 'black',
      transparentCorners: false,
      perPixelTargetFind: false,
      hoverCursor: 'move',
      ...options
    });

    // store label data
    region.data = label || {};

    // retrieve label definition and apply color
    if (label) {
      const [ , color ] = lookupLabelDefinitionAndColor(this.props.labelDefinitions, label.labelId);
      region.set({
        fill: color,
        stroke: color
      })
      region.data.labelId = label.labelId;
    } else if (this.props.cropMode) {
      region.set({
        fill: 'transparent',
        borderColor: '#FFF',
        cornerColor: '#FFF',
        stroke: '#FFF'
      })
    }

    // disable rotation control
    region.setControlsVisibility({ mtr: false });

    // update coordinates on any move
    const setCoords = region.setCoords.bind(region);
    (region as any).on({
      moving: setCoords,
      scaling: setCoords,
      skewing: setCoords,
    });

    return region;
  }

  boundingBoxCoordsToRectangleDimensions (coords: TRBLLabel) {
    const canvas = this.canvas;
    const crop = this.computeCrop();

    const scaleX = 1 / (crop.right - crop.left);
    const scaleY = 1 / (crop.bottom - crop.top);

    const left = (coords.left - crop.left) * scaleX * canvas.width!;
    const top = (coords.top - crop.top) * scaleY * canvas.height!;
    const width = (coords.right - coords.left) * scaleX * canvas.width!;
    const height = (coords.bottom - coords.top) * scaleY * canvas.height!;
    return {
      left,
      top,
      width,
      height
    }
  }

  rectangleDimensionsToBoundingBoxCoords(obj: Dimensions) {
    const canvas = this.canvas;
    const crop = this.computeCrop();
    const canvasWidth = canvas.width!;
    const canvasHeight = canvas.height!;

    const scaleX = 1 / (crop.right - crop.left);
    const scaleY = 1 / (crop.bottom - crop.top);

    const b = obj;

    const left = (b.left / canvasWidth / scaleX) + crop.left;
    const top = (b.top / canvasHeight / scaleY) + crop.top;
    const right = ((b.left + b.width) / canvasWidth / scaleX) + crop.left;
    const bottom = ((b.top + b.height) / canvasHeight / scaleY) + crop.top;

    return {
      left,
      top,
      right,
      bottom
    }
  }

  updateBoundingBoxesCoords(region: fabric.Rect) {
    console.debug('updateBoundingBoxesCoords', region);

    const dimensions = {
      left: region.left!,
      top: region.top!,
      width: region.width! * (region.scaleX || 1),
      height: region.height! * (region.scaleY || 1)
    }
    const labelAnnotation = this.rectangleDimensionsToBoundingBoxCoords(dimensions)
    region.data = {
      ...region.data,
      labelAnnotation: labelAnnotation
    }
  }

  /** SEGMENTATION **/

  addPolygonPoint (x: number, y: number, first?: boolean) {
    const newPoint = new fabric.Circle({
      left: x,
      top: y,
      radius: 4,
      fill: first ? 'red' : 'white',
      stroke: '#333333',
      strokeWidth: 0.5,
      strokeUniform: true,
      selectable: false,
      hasBorders: false,
      hasControls: false,
      originX: 'center',
      originY: 'center',
      objectCaching:false
    });
    this.canvas.add(newPoint);
    this.drawingPoints.push(newPoint);
  }

  controlPolygon (o: fabric.IEvent) {
    const canvas = this.canvas;
    const polygon = o.target as fabric.Polygon;
    polygon.controls = polygon.points.reduce(function(acc, point, index) {
      acc['p' + index] = new fabric.Control({
        positionHandler: function (dim, finalMatrix) {
          const x = (polygon.points[index].x - polygon.pathOffset.x),
              y = (polygon.points[index].y - polygon.pathOffset.y);
          return fabric.util.transformPoint(
            { x, y },
            fabric.util.multiplyTransformMatrices(
              canvas.viewportTransform,
              polygon.calcTransformMatrix()
            )
          );
        },
        actionHandler: function(eventData, transform, x, y) {
          const lastControl = polygon.points.length - 1,
            anchorIndex = index > 0 ? index - 1 : lastControl,
            absolutePoint = fabric.util.transformPoint({
              x: (polygon.points[anchorIndex].x - polygon.pathOffset.x),
              y: (polygon.points[anchorIndex].y - polygon.pathOffset.y),
            }, polygon.calcTransformMatrix()),
            actionPerformed = function (eventData, transform, x, y) {
              const currentControl = polygon.controls[polygon.__corner],
                mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
                polygonBaseSize = ImageCanvas.getObjectSizeWithStroke(polygon),
                size = polygon._getTransformedDimensions(0, 0),
                finalPointPosition = {
                  x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
                  y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
                };

              polygon.points[index] = finalPointPosition;

              return true;
            }(eventData, transform, x, y),
            newDim = polygon._setPositionDimensions({}),
            polygonBaseSize = ImageCanvas.getObjectSizeWithStroke(polygon),
            newX = (polygon.points[anchorIndex].x - polygon.pathOffset.x) / polygonBaseSize.x,
            newY = (polygon.points[anchorIndex].y - polygon.pathOffset.y) / polygonBaseSize.y;

          polygon.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);

          return actionPerformed;
        },
        actionName: 'modifyPolygon'
      });
      return acc;
    }, { });

    this.addDeleteControl(polygon);
    this.canvas.requestRenderAll();
  }

  drawPolygon (o: fabric.IEvent) {
    console.debug('drawPolygon', o);

    const pointer = this.canvas.getPointer(o.e);

    if (this.drawingDisabled) {
      this.drawingDisabled = false;
    }
    else if (this.drawingShape) {
      if (o.target === this.drawingPoints[0] && this.drawingPoints.length > 2) {
        // complete drawing
        this.drawSegmentation(o);
        this.cancelDrawingShape();
      } else if (!o.target) {
        // add new drawing point
        const polygon = this.drawingShape as fabric.Polygon;
        const points = [...polygon.points!, new fabric.Point(pointer.x, pointer.y)];
        polygon.set('points', points)
        this.addPolygonPoint(pointer.x, pointer.y);
        this.canvas.requestRenderAll();
      }
    }
    else if (!o.target) {
      // start drawing first point
      const coord = { x: pointer.x, y: pointer.y };
      this.drawingShape = new fabric.Polygon([coord, coord], {
        originX: 'left',
        originY: 'top',
        objectCaching: false,
        stroke: '#FF0000',
        strokeWidth: 3,
        fill: 'transparent',
        opacity: 0.75,
        hasBorders: false,
        hasControls: false,
        transparentCorners: true,
        selectable: false,
        evented: false,
        left: pointer.x,
        top: pointer.y,
        width: 0,
        height: 0,
        perPixelTargetFind: false
      });
      this.canvas.add(this.drawingShape);
      this.addPolygonPoint(pointer.x, pointer.y, true);
    }
  }

  redrawPolygon(o: fabric.IEvent) {
    if (!this.drawingShape)
      return;

    console.debug('redrawPolygon', o);

    const pointer = this.canvas.getPointer(o.e);
    const polygon = this.drawingShape as fabric.Polygon;
    const points = polygon.points!.map((point, index, points) => {
      if (index !== points.length - 1)
        return point;
      else
        return new fabric.Point(pointer.x, pointer.y);
    })
    polygon.points = points;

    this.canvas.requestRenderAll();
  }

  drawSegmentation (o: fabric.IEvent) {
    if (!this.drawingShape)
      return;

    console.debug('drawSegmentation', o);

    const polygon = this.drawingShape as fabric.Polygon;
    const { width, height } = polygon._calcDimensions();

    // discard small drawings
    if (width < 10 || height < 10)
      return;

    // remove last duplicated point
    polygon.set({
      points: polygon.points!.slice(0, -1)
    });

    const label: Label = {
      labelId: this.props.labelDefinitions[0].id,
      type: 'PolygonLabel',
      labelAnnotation: this.polygonToSegmentationLabelAnnotation(polygon)
    };

    const newRegion = this.createSegmentation(label);

    this.canvas.add(newRegion);
    this.canvas.setActiveObject(newRegion);

    this.notifyChanges([newRegion], []);
    this.applySelection([newRegion.data]);
  }

  createSegmentation (label: Label): fabric.Polygon {
    console.debug('createSegmentation', label);

    const points = this.segmentationCoordsToPolygonPoints(label.labelAnnotation as PolygonLabel);

    const region = new fabric.Polygon(points, {
      originX: 'left',
      originY: 'top',
      objectCaching: false,
      strokeWidth: 3,
      strokeUniform: true,
      opacity: 0.5,
      borderScaleFactor: 2,
      selectable: true,
      hasBorders: true,
      hasControls: true,
      borderColor: 'black',
      cornerSize: 8,
      cornerStyle: 'circle',
      cornerColor: 'white',
      cornerStrokeColor: 'black',
      transparentCorners: false,
      perPixelTargetFind: false,
      hoverCursor: 'move'
    });

    // store label data
    region.data = label;

    // retrieve label definition and apply color
    const [ , color ] = lookupLabelDefinitionAndColor(this.props.labelDefinitions, label.labelId);
    region.set({
      fill: color,
      stroke: color
    })

    // disable rotation control
    region.setControlsVisibility({ mtr: false });

    // update coordinates on any move
    const setCoords = region.setCoords.bind(region);
    (region as any).on({
      moving: setCoords,
      scaling: setCoords,
      skewing: setCoords,
    });

    return region;
  }

  segmentationCoordsToPolygonPoints (coords: PolygonLabel): fabric.Point[] {
    const canvas = this.canvas;
    const crop = this.computeCrop();

    const scaleX = 1 / (crop.right - crop.left);
    const scaleY = 1 / (crop.bottom - crop.top);

    return coords.points.map(p => {
      const x = (p.x - crop.left) * scaleX * canvas.width!;
      const y = (p.y - crop.top) * scaleY * canvas.height!;
      return new fabric.Point(x, y);
    });
  }

  polygonToSegmentationLabelAnnotation (polygon: fabric.Polygon): PolygonLabel {
    const canvas = this.canvas;

    const matrix = polygon.calcTransformMatrix();
    const points = polygon.get('points') || [];
    const crop = this.computeCrop();

    const scaleX = 1 / (crop.right - crop.left);
    const scaleY = 1 / (crop.bottom - crop.top);

    return {
      points: points.map(p => {
        const o = new fabric.Point(p.x - polygon.pathOffset.x, p.y - polygon.pathOffset.y);
        const t = fabric.util.transformPoint(o, matrix);
        const x = (t.x / canvas.width! / scaleX) + crop.left;
        const y = (t.y / canvas.height! / scaleY) + crop.top;
        return { x, y };
      })
    }
  }

  updateSegmentationCoords(polygon: fabric.Polygon) {
    console.debug('updateSegmentationCoords', polygon);

    polygon.data = {
      ...polygon.data,
      labelAnnotation: this.polygonToSegmentationLabelAnnotation(polygon)
    }
  }
}