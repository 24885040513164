import React from 'react';

import {
  Divider,
  Toolbar,
  Typography,
  Box,
  List,
  ListItem
} from '@material-ui/core';

import {
  Skeleton
} from '@material-ui/lab';

import {
  Labelset
} from '../api';

import {
  LabelsetIcon,
  ErrorBox,
  SearchItem
} from '../components';

import {
  searchCase
} from '../utils';

interface Props {
  labelsets: Labelset[] | (() => Promise<Labelset[]>);
  selection?: string;
  renderSelection: (labelsets: Labelset[], id: string, manager: LabelsetManager) => React.ReactNode;
  renderActions?: (labelsets: Labelset[], manager: LabelsetManager) => React.ReactNode;
  onSelection?: (id: string | undefined) => void;
}

class State {
  loading: boolean = true;
  selection?: string;
  error?: Error;
  labelsets?: Labelset[];
  searchText: string = '';
}

export class LabelsetManager extends React.Component<Props, State> {

  readonly state = new State();

  reload = (selection: string | undefined, notify: boolean = true) => {
    const { labelsets, onSelection } = this.props;

    this.setState({ loading: true, labelsets: undefined, error: undefined, selection });
    if (notify && onSelection)
      onSelection(selection);

    Promise.resolve(labelsets instanceof Function ? labelsets() : labelsets)
    .then(labelsets => {
      this.setState({ loading: false, labelsets })
    })
    .catch(error => this.setState({ loading: false, error }))
  }

  componentDidMount() {
    this.reload(this.props.selection, false);
  }

  handleSelection = (id: string) => {
    const selection = this.state.selection !== id ? id : undefined;
    this.setState({ selection });
    this.props.onSelection?.(selection);
  }

  render() {
    const { renderActions, renderSelection } = this.props;
    const { selection, loading, error, labelsets, searchText } = this.state;

    return (
      <Box display="flex" flex={1} flexDirection="column">
        <Toolbar>
          <LabelsetIcon />
          <Box mx={1} />
          <Typography variant="h6" noWrap>Labelsets</Typography>
          <Box mx={2} />
          { labelsets && renderActions && renderActions(labelsets, this) }
        </Toolbar>
        <Divider />
        <Box display="flex" flex="1" flexDirection="row">
          <Box display="flex" flex={1} style={{maxWidth: 250}}>
            { loading && (
              <List style={{flex: 1}}>
                { Array(5).fill(0).map((count, itemIndex) => (
                 <ListItem key={itemIndex}>
                    <Skeleton height={15} width="80%" />
                 </ListItem>
                )) }
              </List>
            )}
            { error && <ErrorBox message={error?.message} onReload={() => this.reload(this.props.selection)} /> }
            { labelsets &&
            <Box style={{ flex: '1 1 1px', overflowY: 'scroll'}}>
              <List dense disablePadding>
                <SearchItem hideIcon onUpdate={(searchText) => this.setState({ searchText })} />
              </List>
              <List disablePadding>
                { labelsets
                  .filter(d => !searchText || searchCase(d.name).indexOf(searchCase(searchText)) !== -1)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(labelset => (
                  <ListItem key={labelset.id}
                    button
                    selected={labelset.id === selection}
                    onClick={() => this.handleSelection(labelset.id)}
                  >
                    { labelset.name }
                  </ListItem>
                )) }
              </List>
            </Box>
            }
          </Box>
          <Divider orientation="vertical" />
          <Box flex={1} display="flex">
            { !selection && <Box padding={2}><Typography variant="body2" color="textSecondary">Select a labelset.</Typography></Box> }
            { labelsets && selection && renderSelection && renderSelection(labelsets, selection, this) }
          </Box>
        </Box>
      </Box>
    )
  }
}