import React from 'react';

import {
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';

import {
  Skeleton
} from '@material-ui/lab';

const avatarStyle = {
  height: 45,
  width: 45,
  backgroundColor: '#E3E3E3'
};

export class DatasetItemSkeleton extends React.Component {
  render() {
    return (
      <ListItem style={{paddingTop: 8, paddingBottom: 8}}>
        <ListItemAvatar>
          <Skeleton style={avatarStyle} variant="rect"  />
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton height={20} width={150} />}
          secondary={<Skeleton height={15} width={200} />}
        />
      </ListItem>
    );
  }
}