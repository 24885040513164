import React from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography
} from '@material-ui/core';

import {
  KeyboardArrowRight as NextIcon,
  PlayArrow as StartIcon,
  SaveAlt as SaveIcon,
  Close as CloseIcon,
  Settings as SettingsIcon
} from '@material-ui/icons';

import {
  Skeleton
} from '@material-ui/lab';

import API, {
  Dataset,
  DatasetSplitResults,
  Labelset,
  DeploymentStaticDataInput,
  DeploymentStaticDataInputType,
  Template,
  DataCollection,
  DeploymentStage,
  TemplateInstance,
  TemplateInstanceInput,
  deepClone
} from '../api';

import {
  DebugDialog,
  ErrorAlert,
  TemplatePicker,
  DataCollectionPicker,
  DatasetPicker,
  LabelsetPicker,
  TemplateInstanceBasicForm,
  TemplateIcon,
  DataCollectionIcon,
  DatasetIcon,
  LabelsetIcon,
  TemplateInstanceAdvancedForm
} from '../components';

import {
  initTemplateInstance,
  setTemplateInstanceTemplate
} from '../utils';

interface Props {
  aspectId: string;
  templateInstance?: TemplateInstance;
  onClose?: () => void;
  onSuccess?: (templateInstance: TemplateInstance) => void;
  footer?: React.ReactNode;
}

class State {
  templateInstanceInput?: TemplateInstanceInput;
  template?: Template;
  dataCollection?: DataCollection;
  dataset?: Dataset;
  labelset?: Labelset;
  activeStep: number = 0;
  isLoading: boolean = false;
  isStarting: boolean = false;
  isSaving: boolean = false;
  error?: Error;

  constructor (props: Props) {
    this.templateInstanceInput = props.templateInstance ? deepClone(props.templateInstance) : initTemplateInstance({ dataSourceReferenceId: props.aspectId });
  }
}

export class TemplateInstanceCreator extends React.Component<Props, State> {

  readonly state = new State(this.props);

  componentDidMount() {
    const { templateInstance } = this.props;
    if (!templateInstance)
      return;

    const trainingSet = templateInstance.dataInputs
      .find(i => i.dataInputType === DeploymentStaticDataInputType.TrainingSet);

    const { datasetVersionId, labelsetId } = trainingSet || {};

    const { templateVersionId } = templateInstance;

    this.setState({ isLoading: true, activeStep: -1, template: undefined, dataset: undefined, labelset: undefined, dataCollection: undefined });
    Promise.all([
      templateVersionId ? API.templates.getTemplateVersion(templateVersionId) : undefined,
      datasetVersionId ? API.datasets.getDatasetVersion(datasetVersionId) : undefined,
      labelsetId ? API.labelsets.getLabelset(labelsetId) : undefined
    ])
    .then(([template, dataset, labelset]) => {
      (dataset ? API.dataCollections.getDataCollection(dataset.dataCollectionId) : Promise.resolve(undefined))
      .then(dataCollection => {
        this.setState({
          isLoading: false,
          template, dataCollection, dataset, labelset,
          activeStep: labelset ? 4 : dataset ? 3 : dataCollection ? 2 : template ? 1 : 0
        });
      })
    })
    .catch(error => this.setState({ isLoading: false, error }));
  }

  startTemplateInstanceTrainingWithTestSplit = (id: string | undefined, instance: TemplateInstanceInput, results: DatasetSplitResults): Promise<TemplateInstance> => {
    const dataInputs: Array<DeploymentStaticDataInput> = Object.entries(results.splits).map(([dataInputType, result]) => {
      return {
        type: 'DeploymentStaticDataInput',
        dataInputType: dataInputType as DeploymentStaticDataInputType,
        datasetVersionId: result.datasetVersionId,
        labelsetId: result.labelsetId
      }
    })
    return ( id
      ? API.templateInstances.updateTemplateInstance(id, {...instance, dataInputs})
      : API.templateInstances.createTemplateInstance({...instance, dataInputs})
    )
    .then(templateInstance => {
      return API.templateInstances.startTemplateInstanceDeployment(templateInstance.id, {
        stage: DeploymentStage.Training,
        inputs: dataInputs
      })
      .then(deployment => {
        return API.templateInstances.getTemplateInstance(templateInstance.id);
      })
    })
  }

  startTemplateInstanceTraining = (id: string | undefined, instance: TemplateInstanceInput): Promise<TemplateInstance> => {
    const inputs = instance.dataInputs, input = inputs[0];
    const { testSplit, validationSplit } = instance.settings.training;

    const testSplitRatio = testSplit as number || 0;
    const validationSplitRatio = (100 - testSplitRatio) * (validationSplit as number || 0) / 100;
    const trainingSplitRatio = 100 - testSplitRatio - validationSplitRatio;

    if (inputs.length === 1 && input
      && trainingSplitRatio < 100
      && input.datasetVersionId
      && input.labelsetId) {
      return Promise.all([
        API.datasets.getDatasetVersion(input.datasetVersionId),
        API.labelsets.getLabelset(input.labelsetId)
      ])
      .then(([dataset, labelset]) => {
        return API.datasets.splitDatasetVersion(dataset.datasetVersionId, {
          deleteOrigin: false,
          includedLabelset: labelset.id,
          split: {
            [DeploymentStaticDataInputType.TrainingSet]: trainingSplitRatio,
            [DeploymentStaticDataInputType.TestSet]: testSplitRatio || undefined,
            [DeploymentStaticDataInputType.ValidationSet]: validationSplitRatio || undefined,
          }
        })
      })
      .then(results => {
        return this.startTemplateInstanceTrainingWithTestSplit(id, instance, results);
      })
    } else {
      return ( id
        ? API.templateInstances.updateTemplateInstance(id, instance)
        : API.templateInstances.createTemplateInstance(instance)
      )
      .then(templateInstance => {
        return API.templateInstances.startTemplateInstanceDeployment(templateInstance.id, {
          stage: DeploymentStage.Training,
          inputs
        })
        .then(deployment => {
          return API.templateInstances.getTemplateInstance(templateInstance.id);
        })
      })
    }
  }

  selectTemplate = (template?: Template) => {
    const { templateInstanceInput } = this.state;
    if (!template) {
      this.setState({
        template: undefined,
        dataCollection: undefined,
        dataset: undefined,
        labelset: undefined,
      });
    } else if (templateInstanceInput) {
      setTemplateInstanceTemplate(templateInstanceInput, template);
      this.setState({
        template,
        dataCollection: undefined,
        dataset: undefined,
        labelset: undefined,
        templateInstanceInput,
        activeStep: 1
      })
    }
  }

  selectDataCollection = (dataCollection?: DataCollection) => {
    const { templateInstanceInput  } = this.state;
    if (!dataCollection) {
      this.setState({
        dataCollection: undefined,
        dataset: undefined,
        labelset: undefined,
        templateInstanceInput
     })
    } else {
      this.setState({
        dataCollection,
        dataset: undefined,
        labelset: undefined,
        activeStep: 2,
        templateInstanceInput
      })
    }
  }

  selectDataset = (dataset?: Dataset) => {
    const { templateInstanceInput  } = this.state;
    if (!dataset) {
      this.setState({
        dataset: undefined,
        labelset: undefined,
        templateInstanceInput
      });
    } else if (templateInstanceInput) {
      this.setState({
        dataset,
        labelset: undefined,
        activeStep: 3,
        templateInstanceInput: {
          ...templateInstanceInput,
          dataInputs: [
            {
              type: 'DeploymentStaticDataInput',
              dataInputType: DeploymentStaticDataInputType.TrainingSet,
              datasetVersionId: dataset.datasetVersionId,
              labelsetId: undefined
            }
          ]
        }
      })
    }
  }

  selectLabelset = (labelset?: Labelset) => {
    const { templateInstanceInput, dataCollection, template  } = this.state;
    if (!labelset) {
      this.setState({
        labelset: undefined,
        templateInstanceInput
      })
    } else if (template && dataCollection && templateInstanceInput) {
      this.setState({
        labelset,
        activeStep: 4,
        templateInstanceInput: {
          ...templateInstanceInput,
          name: [ dataCollection.name, labelset.name, template.name ].join('-'),
          additionalAttributes: {
            tags: [ dataCollection.name, labelset.name, template.name ]
          },
          dataInputs: [
            {
              ...templateInstanceInput.dataInputs[0],
              labelsetId: labelset.id
            }
          ]
        }
      });
    }
  }

  handleSave = (templateInstanceInput: TemplateInstanceInput) => {
    const { templateInstance } = this.props;

    this.setState({ error: undefined, isSaving: true });
    ( templateInstance
      ? API.templateInstances.updateTemplateInstance(templateInstance.id, templateInstanceInput)
      : API.templateInstances.createTemplateInstance(templateInstanceInput)
    )
    .then(templateInstance => {
      this.props.onSuccess?.(templateInstance);
    })
    .catch(error => {
      this.setState({ error, isSaving: false });
    })
  }

  handleStart = (templateInstanceInput: TemplateInstanceInput) => {
    const { templateInstance } = this.props;

    this.setState({ error: undefined, isStarting: true });
    this.startTemplateInstanceTraining(templateInstance?.id, templateInstanceInput)
    .then(templateInstance => {
      this.props.onSuccess?.(templateInstance);
    })
    .catch(error => {
      this.setState({ error, isStarting: false });
    })
  }

  render() {
    const { aspectId, onClose } = this.props;
    const { error, activeStep, template, dataCollection, dataset, labelset, templateInstanceInput, isLoading, isStarting, isSaving } = this.state;

    const label = (text: string) => <Typography variant="subtitle2">{text}</Typography>
    const optionalLabel = (text?: string) => text && <Typography variant="body2" color="textSecondary" noWrap>{text}</Typography>

    return (
      <Box display="flex" flexDirection="column" flex={1} style={{overflowY: 'hidden'}}>
        <Toolbar>
          <Typography variant="h6">Quick Training</Typography>
          <Box mx={2} />
          <DebugDialog object={templateInstanceInput} />
          <Box mx="auto" />
          { onClose &&
            <IconButton onClick={onClose}><CloseIcon /></IconButton>
          }
        </Toolbar>
        <Divider />
        <Box display="flex" flexDirection="row" flex={1} style={{overflowY: 'hidden'}}>
          <Box style={{width: 300}} paddingRight={2}>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              style={{background: 'inherit'}}>
              <Step>
                <StepLabel
                  disabled={isLoading}
                    icon={<TemplateIcon color={activeStep === 0 ? 'primary' : !isLoading ? 'secondary' : 'disabled'} />}
                  optional={optionalLabel(template?.name)}
                  onClick={() => {
                    this.setState({ activeStep: 0, error: undefined })
                  }}
                >
                  {label('Template')}
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  icon={<DataCollectionIcon color={activeStep === 1 ? 'primary' : template ? 'secondary' : 'disabled'} />}
                  disabled={!template}
                  optional={optionalLabel(dataCollection?.name)}
                  onClick={() => {
                    if (!template) return;
                    this.setState({ activeStep: 1, error: undefined })
                  }}
                >
                  {label('Data Collection')}
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  icon={<DatasetIcon color={activeStep === 2 ? 'primary' : dataCollection ? 'secondary' : 'disabled'} />}
                  disabled={!dataCollection}
                  optional={optionalLabel(dataset?.name)}
                  onClick={() => {
                    if (!dataCollection) return;
                    this.setState({ activeStep: 2, error: undefined  })
                  }}
                >
                  {label('Dataset')}
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  icon={<LabelsetIcon color={activeStep === 3 ? 'primary' : dataset ? 'secondary' : 'disabled'} />}
                  disabled={!dataset}
                  optional={optionalLabel(labelset?.name)}
                  onClick={() => {
                    if (!dataset) return;
                    this.setState({ activeStep: 3, error: undefined })
                  }}
                >
                  {label('Labelset')}
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  icon={<SettingsIcon color={activeStep === 4 ? 'primary' : labelset ? 'secondary' : 'disabled'} />}
                  disabled={!labelset}
                  optional={optionalLabel('Optional')}
                  onClick={() => {
                    if (!labelset) return;
                    this.setState({ activeStep: 4, error: undefined })
                  }}
                >
                  {label('Advanced Parameters')}
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  icon={<StartIcon color={activeStep === 5 ? 'primary' : labelset ? 'secondary' : 'disabled'} />}
                  disabled={!labelset}
                  onClick={() => {
                    if (!labelset) return;
                    this.setState({ activeStep: 5, error: undefined })
                  }}
                >
                  {label('Save & Start')}
                </StepLabel>
              </Step>
            </Stepper>
          </Box>
          <Divider orientation="vertical" />
          <Box display="flex" flex={1} flexDirection="column">
            { isLoading &&
              <Toolbar>
                <Skeleton width={200} height={20} />
              </Toolbar>
            }
            { error && <ErrorAlert error={error} /> }
            { activeStep === 0 &&
              <TemplatePicker
                style={{flex: 1}}
                selection={template}
                onSelection={this.selectTemplate.bind(this)}
              />
            }
            {
              activeStep === 1 &&
              <DataCollectionPicker
                style={{flex: 1}}
                aspectId={aspectId}
                selection={dataCollection}
                onSelection={this.selectDataCollection.bind(this)}
              />
            }
            {
              activeStep === 2 && dataCollection &&
              <DatasetPicker
                dataCollectionId={dataCollection.id}
                style={{flex: 1}}
                selection={dataset}
                onSelection={this.selectDataset.bind(this)}
              />
            }
            {
              activeStep === 3 && dataset && template &&
              <LabelsetPicker
                style={{flex: 1}}
                labelConfiguration={template.settings.labelSettings.labelConfiguration}
                datasetVersionId={dataset.datasetVersionId}
                selection={labelset}
                onSelection={this.selectLabelset.bind(this)}
              />
            }
            {
              activeStep === 4 && templateInstanceInput &&
              <Box display="flex" flexDirection="column" flex={1}>
                <Toolbar variant="dense">
                  <SettingsIcon fontSize="small" />
                  <Box mx={1} />
                  <Typography variant="subtitle1">Advanced Parameters</Typography>
                  <Box mx={2} />
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<NextIcon />}
                    onClick={() => this.setState({ activeStep: 5 }) }
                    children="Next" />
                </Toolbar>
                <Box px={2} display="flex" style={{flex: '1 1 1px', overflowY: 'scroll'}}>
                  <TemplateInstanceAdvancedForm
                    style={{width: '100%'}}
                    templateInstanceInput={templateInstanceInput}
                    onSubmit={templateInstanceInput => this.setState({ templateInstanceInput }) }
                  />
                </Box>
              </Box>
            }
            {
              activeStep === 5 && templateInstanceInput &&
              <Box px={3} flex={1}>
                <TemplateInstanceBasicForm
                  templateInstanceInput={templateInstanceInput}
                  onChange={(templateInstanceInput) => this.setState({ templateInstanceInput })}
                  onSubmit={(templateInstanceInput) => this.handleStart(templateInstanceInput)}
                />
                <Box my={2} />
                <Box display="flex" flexDirection="row">
                  <Button variant="contained" color="primary"
                    startIcon={isStarting ? <CircularProgress color="inherit" size={20} /> : <StartIcon />}
                    disabled={isStarting}
                    onClick={() => this.handleStart(templateInstanceInput)}
                  >
                    Save and Start Training
                  </Button>
                  <Box mx={2} />
                  <Button
                    variant="outlined"
                    startIcon={isSaving ? <CircularProgress color="inherit" size={20} /> : <SaveIcon />}
                    disabled={isSaving}
                    onClick={() => this.handleSave(templateInstanceInput)}
                  >
                    Save and Start later
                  </Button>
                </Box>
              </Box>
            }
          </Box>
        </Box>
        <Divider />
      </Box>
    );
  }
}