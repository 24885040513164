import React from 'react';

import {
  withRouter,
  RouteComponentProps
} from 'react-router-dom';

import {
  Typography,
  Box,
  Container
} from '@material-ui/core'

import {
  ErrorBox
} from '../components';

import auth from '../auth';

interface Props extends RouteComponentProps {}

class State {
  token?: string;
  error?: Error;
}

export const LoginPage = withRouter(class extends React.Component<Props, State> {

  readonly state = new State();

  componentDidMount() {
    auth.confirm(new URL(window.location.href))
    .then(token => {
      setTimeout(() => {
        if (token) this.props.history.push('/')
        else auth.signin();
      }, 1000)
    })
    .catch(error => this.setState({ error }))
  }

  render() {
    const { error } = this.state;

    return (
      <Container>
        <Box mx="auto" my="auto">
          {
            error
            ? <ErrorBox message={error.message} onReload={() => auth.signin()} />
            : <Container><Typography variant="subtitle1">Completing your sign-in...</Typography></Container>
          }
        </Box>
      </Container>
    );
  }
});