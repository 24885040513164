import React from 'react';

import {
  UnregisterCallback
} from 'history';

import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from 'react-router-dom';

import {
  Typography,
  Container,
  Box
} from '@material-ui/core';

import {
  AppTopBar,
  AssetPage,
  TemplateInstancesPage,
  DevRouter,
  ErrorPage,
  TemplatesPage,
  LoginPage
} from '../components';

import {
  DataType
} from '../api';

import auth from '../auth';

interface Props extends RouteComponentProps {}

class State {
  redirectTo: string = /*localStorage.getItem('redirectTo') ||*/ '/assets';
}

export const AppRouter= withRouter(class extends React.Component<Props, State> {

  readonly state = new State();

  removeHistoryListener: UnregisterCallback = () => {};

  componentDidMount() {
    this.removeHistoryListener = this.props.history.listen((location, action) => {
      const { pathname } = location;
      if (!['/login', '/'].includes(pathname)) {
        localStorage.setItem('redirectTo', pathname);
      }
    });
  }

  componentWillUnmount() {
    this.removeHistoryListener();
  }

  render() {
    const { location } = this.props;
    const { redirectTo } = this.state;

    if (location.pathname !== '/login' && !auth.currentUser()) {
      auth.signin();
      return null;
    }

    return (
      <Switch>
        <Route exact path="/">
          <Redirect to={redirectTo} />
        </Route>
        <Route exact path="/login" component={LoginPage} />
        <Route path="/templates">
          <TemplatesPage baseURL="/templates" dataType={DataType.Images} />
        </Route>
        <Route exact path="/assets">
          <React.Fragment>
            <AppTopBar>
              <Typography variant="h6">
                Production Lines
              </Typography>
            </AppTopBar>
            <Container>
              <Box py={1}>
                <Typography variant="subtitle1">Select or add a production line in the left side menu.</Typography>
              </Box>
            </Container>
          </React.Fragment>
        </Route>
        <Route exact path="/assets/:assetId" render={(props) => (
          <AssetPage key={props.match.params.assetId} {...props} />)
        } />
        <Route path="/assets/:assetId/aspects/:aspectId" render={(props) => (
          <TemplateInstancesPage
            key={props.match.params.aspectId}
            assetId={props.match.params.assetId}
            aspectId={props.match.params.aspectId}
            {...props}
          />
        ) } />
        <Route path="/dev">
          <DevRouter />
        </Route>
        <Route path="*">
          <ErrorPage title="Not Found" message="The page you are looking for does not exist..." />
        </Route>
      </Switch>
    );
  }
});