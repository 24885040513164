import React from 'react';

import {
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';

import {
  Skeleton
} from '@material-ui/lab';

import {
  titleCase
} from '../utils';

interface SkeletonProps {
  countProperties: number;
  width?: number;
}

export class PropertyTableSkeleton extends React.Component<SkeletonProps> {
  render() {
    const { countProperties, width } = this.props;
    return (
      <TableContainer style={{width}}>
        <Table size="small">
          <TableBody>
            {Array(countProperties).fill(0).map((value, index) => (
              <TableRow key={index}>
                <TableCell style={{borderBottom: 'none'}} component="th" size="small">
                  <Skeleton height={15} width="80%" />
                </TableCell>
                <TableCell style={{borderBottom: 'none'}} align="right" size="small">
                  <Skeleton height={15} width={50} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

interface Props {
  properties: Object;
  width?: number;
}

export class PropertyTable extends React.Component<Props> {

  render() {
    const { properties, width } = this.props;

    const entries = Object.entries(properties).filter(([key, value]) => value !== undefined);

    return (
      <TableContainer style={{width}}>
        <Table size="small">
          <TableBody>
            {entries.map(([key, value]) => (
              <TableRow key={key}>
                <TableCell style={{borderBottom: 'none'}} component="th" size="small">
                  <Typography variant="body2" color="textSecondary">
                  {titleCase(key)}
                  </Typography>
                </TableCell>
                <TableCell style={{borderBottom: 'none'}} align="right" size="small">
                  <Typography variant="body2">{`${value}`}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}